import { KatTabs } from '../tabs';
import { KatTabOverflow } from '../tabs-overflow';
import { KatTab } from '../tabs-tab';
import { Keys } from '../../../shared/base';
import { TabStrings } from '../strings';
import { OVERFLOW_CHILD_TAB_NOT_SELECTED } from '../tab-constants';

export class OverflowController {
  primaryChildTabs: (KatTab | KatTabOverflow)[];
  ref: KatTabs;
  overflowTab: KatTabOverflow;
  overflowChildTabs: KatTab[];
  hiddenTab: KatTab;
  tabBeforeOverflow: KatTab;
  isInitialOverflowChildBeingClicked: boolean;
  initialOverflowTabSelected: number;
  lastSelectedOverflowTab: null | KatTab;

  constructor(ref: KatTabs) {
    this.ref = ref;
    // initialization needs to happen prior to setting additional properties
    this.initializeOverflowTab();

    this.primaryChildTabs = Array.from(ref.children) as (
      | KatTab
      | KatTabOverflow
    )[];
    this.overflowTab = this.primaryChildTabs[
      this.primaryChildTabs.length - 2
    ] as KatTabOverflow;
    this.overflowChildTabs = Array.from(this.overflowTab.children) as KatTab[];
    this.hiddenTab = this.primaryChildTabs[
      this.primaryChildTabs.length - 1
    ] as KatTab;
    this.tabBeforeOverflow = this.primaryChildTabs[
      this.primaryChildTabs.length - 3
    ] as KatTab;
    this.isInitialOverflowChildBeingClicked = false;
    this.initialOverflowTabSelected = OVERFLOW_CHILD_TAB_NOT_SELECTED;
  }

  initializeOverflowTab() {
    const primaryChildTabs = Array.from(this.ref.children);

    // prevent duplication of overflowTab if component is disconnected and re-connected
    if (
      primaryChildTabs[primaryChildTabs.length - 2].tagName ===
      TabStrings.KatTabOverflowElement
    ) {
      return;
    }

    const replacementTabs = document.createDocumentFragment();
    const overflowTab = document.createElement(
      TabStrings.KatTabOverflowElement
    );
    overflowTab.setAttribute('tab-id', TabStrings.KatTabOverflowId);

    primaryChildTabs.forEach((child, i) => {
      if (this.ref.tabsBeforeOverflow <= i) {
        child.classList.add('overflow-child-tab');

        overflowTab.appendChild(child);
      } else {
        replacementTabs.appendChild(child);
      }
    });

    replacementTabs.appendChild(overflowTab);
    this.ref.appendChild(replacementTabs);

    // Create the hidden replacement tab
    const hiddenTab = document.createElement('kat-tab');
    hiddenTab.setAttribute('label', 'katal-hidden');
    hiddenTab.setAttribute('tab-id', TabStrings.KatHiddenTab);
    hiddenTab.setAttribute('tabIndex', '-1');
    hiddenTab.classList.add('hidden');

    this.ref.appendChild(hiddenTab);
  }

  _replaceHiddenTabContent(selectedOverflowTab: KatTab) {
    // Return the contents of the hidden tab back to the correct overflow child tab
    if (this.lastSelectedOverflowTab) {
      // The first element is a wrapper div that is added when moving elements to the hidden tab
      Array.from(this.hiddenTab.children[0].children).forEach(child =>
        this.lastSelectedOverflowTab.appendChild(child)
      );
    }

    this.lastSelectedOverflowTab = selectedOverflowTab;

    const newNode = document.createElement('div');
    const selectedTabChildNodes = selectedOverflowTab.children;

    // Copying HTMLCollection to new node
    Array.from(selectedTabChildNodes).forEach(node => {
      // ignore slots so they function as normal and placement in component doesn't matter
      // Must not use unformatted text
      if (!node.hasAttribute('slot')) {
        newNode.appendChild(node);
      }
    });

    // @ts-expect-error  not an issue: https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/985
    this.hiddenTab.replaceChildren(newNode);
  }

  setSelectedTab() {
    // Must set ref properly during arrowing or resizing wont work
    if (this.ref._selectedTab !== this.hiddenTab) {
      this.ref._selectedTab = this.hiddenTab;
    }
  }

  setNextOverflowSelected(e) {
    e.keyCode === Keys.ArrowRight
      ? (this.overflowTab.overflowSelected += 1)
      : (this.overflowTab.overflowSelected -= 1);
  }

  arrowIntoOverflow() {
    this.overflowTab.overflowSelected = 0;

    this.ref._setSelected(this.hiddenTab.tabId);
    this.overflowTab.isOverflowVisible = true;
  }

  // Arrow navigation when the overflow has focus but is closed
  overflowNavigationWhenClosed(e: KeyboardEvent) {
    this.setNextOverflowSelected(e);

    if (this.overflowTab.overflowSelected === OVERFLOW_CHILD_TAB_NOT_SELECTED) {
      this.overflowTab.isOverflowVisible = false;
      this.ref._setSelected(this.tabBeforeOverflow.tabId);
      return;
    }

    if (
      this.overflowTab.overflowSelected === this.overflowTab.children.length
    ) {
      this.overflowTab.overflowSelected = OVERFLOW_CHILD_TAB_NOT_SELECTED;
      this.ref._setSelected(this.primaryChildTabs[0].tabId);
      return;
    }

    this.ref._setSelected(this.hiddenTab.tabId);
    this.overflowTab.isOverflowVisible = true;
  }

  overflowVisibleArrow(e: KeyboardEvent, nextTab) {
    const overflowTab = this.overflowTab;
    const childTabs = this.primaryChildTabs;

    this.setNextOverflowSelected(e);

    // Handle clicking on overflow tab then arrowing to the left
    if (e.keyCode === Keys.ArrowLeft && overflowTab.overflowSelected === -2) {
      // reset overflowSelected to the proper count and hide
      overflowTab.overflowSelected = OVERFLOW_CHILD_TAB_NOT_SELECTED;
      overflowTab.isOverflowVisible = false;
      this.ref._setSelected(childTabs[childTabs.length - 3].tabId);
      return;
    }

    // Handle clicking on overflow tab then arrowing to the right
    if (e.keyCode === Keys.ArrowRight && overflowTab.overflowSelected === 0) {
      nextTab.overflowSelected = 0;

      this.ref._setSelected(this.hiddenTab.tabId);
      overflowTab.isOverflowVisible = true;

      return;
    }

    // Arrow back to the tab before overflow
    if (overflowTab.overflowSelected === OVERFLOW_CHILD_TAB_NOT_SELECTED) {
      overflowTab.isOverflowVisible = false;
      this.ref._setSelected(this.tabBeforeOverflow.tabId);
      return;
    }

    // Arrow forward to the beginning when right arrow from last overflow tab
    if (overflowTab.overflowSelected === overflowTab.children.length) {
      overflowTab.overflowSelected = OVERFLOW_CHILD_TAB_NOT_SELECTED;
      this.ref._setSelected(childTabs[0].tabId);
      return;
    }

    this.ref._selectTab();
  }

  // returns selected element
  _getSelectedOverflowTab() {
    return this.overflowChildTabs[this.overflowTab?.overflowSelected];
  }

  _setSelected(id: string) {
    let newOverflowTabSelected;
    let newOverflowTabSelectedIndex;

    // clean up and set focus if overflow tab selected
    this.overflowChildTabs.forEach((tab, index) => {
      if (tab.tabId === id) {
        tab.focus();
        tab.overflowTabSelected = true;
        newOverflowTabSelected = tab;
        newOverflowTabSelectedIndex = index;
      } else {
        tab.overflowTabSelected = false;
      }
    });

    // handles when an overflow tab child was selected and then a different one is clicked on
    if (
      this.ref.selected === TabStrings.KatHiddenTab &&
      newOverflowTabSelected
    ) {
      this.overflowTab.overflowSelected = newOverflowTabSelectedIndex;
      this.overflowTab.isOverflowVisible = false;

      this.ref._selectTab();
      return;
    }

    // Normal tab is selected and overflow is visible, handles initial click on overflow child tab
    if (!!newOverflowTabSelected && !this.hiddenTab.selected) {
      this.isInitialOverflowChildBeingClicked = true;
      this.initialOverflowTabSelected = newOverflowTabSelectedIndex;
      this.overflowTab.overflowSelected = newOverflowTabSelectedIndex;
      this.overflowTab.isOverflowVisible = false;

      this.ref.selected = this.hiddenTab.tabId;
      return;
    }

    // User has arrowed from the last overflow child tab to the right (beginning of tabs)
    if (this.overflowTab.isOverflowVisible) {
      this.overflowTab.isOverflowVisible = false;
    }

    // hidden tab is selected, an overflow child tab is displayed, the overflowTab isn't visible and an arrow keydown has happened
    if (
      id === TabStrings.KatHiddenTab &&
      this.ref.selected === TabStrings.KatHiddenTab
    ) {
      const selectedOverflowTab = this._getSelectedOverflowTab();

      this._replaceHiddenTabContent(selectedOverflowTab);
      this._setFocusAndSelectionForOverflowChildTabs(selectedOverflowTab);
    }

    this.ref.selected = id;
  }

  _updateChildTabs() {
    let selectedTab;

    this.primaryChildTabs.forEach((tab, i) => {
      const selected =
        // auto-select the first tab if no selected id is set
        (i === 0 && !this.ref.selected) ||
        // prevent multiple tabs from being selected at once
        (!selectedTab && tab.tabId === this.ref.selected);
      tab.selected = selected;
      if (selected) {
        selectedTab = tab;
      }
    });
  }

  _setFocusAndSelectionForOverflowChildTabs(selectedOverflowTab) {
    this.overflowChildTabs.forEach(tab => {
      if (tab === selectedOverflowTab) {
        tab.focus();
        tab.overflowTabSelected = true;
      } else {
        tab.overflowTabSelected = false;
      }
    });
  }

  _handleOverflowBlur(e) {
    if (!e?.detail?.relatedTarget) {
      this.overflowTab.isOverflowVisible = false;
      return;
    }

    const tabId = e.detail.relatedTarget.tabId;
    // Any direct child tab which includes the overflow tab and overflow child tabs
    let clickedOnPrimaryTab = false;
    // Primary tabs that are viewable and the overflow (no overflow child tabs or grandchild tabs)
    let clickedOnDirectChild;

    // Check main tabs for click
    this.primaryChildTabs.some(child => {
      if (tabId === child.tabId) {
        clickedOnPrimaryTab = true;
        clickedOnDirectChild = true;
        return true;
      }
    });

    // Check overflow child tabs for click
    this.overflowChildTabs.forEach(overflowChild => {
      if (e.detail.relatedTarget.tabId === overflowChild.tabId) {
        clickedOnPrimaryTab = true;
      }
    });

    if (!clickedOnPrimaryTab) {
      this.overflowTab.isOverflowVisible = false;
      return;
    }

    // Needed for reverse tabbing OUT of the Overflow tab
    if (
      clickedOnPrimaryTab &&
      e.detail.relatedTarget.tagName !== TabStrings.KatTabOverflowElement &&
      this.overflowTab.isOverflowVisible === true &&
      clickedOnDirectChild === true
    ) {
      this.overflowTab.isOverflowVisible = false;
    }
  }

  _clickOverflowChildTabForTheFirstTime() {
    this._replaceHiddenTabContent(this._getSelectedOverflowTab());
    this._updateChildTabs();

    this.ref._selectedTab = this.hiddenTab;
    this.isInitialOverflowChildBeingClicked = false;
    this.initialOverflowTabSelected = OVERFLOW_CHILD_TAB_NOT_SELECTED;
  }

  // Handle overflow child tab selection for the second or more time, until a non overflow child tab is selected
  _selectTab() {
    // Ensure ref._selectedTab is set correctly
    this.setSelectedTab();
    const selectedOverflowTab = this._getSelectedOverflowTab();

    this._replaceHiddenTabContent(selectedOverflowTab);
    this._updateChildTabs();
    this._setFocusAndSelectionForOverflowChildTabs(selectedOverflowTab);
  }
}
