import { KatButton, KatSpinner } from '@amzn/katal-react';
import React, { ReactNode, useEffect } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { GraphDiagram } from 'src/components/GraphDiagram/GraphDiagram';
import { useGetSageMakerPipelineGraphStatus } from 'src/hooks/useGetSageMakerPipelineGraphStatus/useGetSageMakerPipelineGraphStatus';
import { Placeholder } from '../Placeholder/Placeholder';
import cx from 'classnames';

export interface SageMakerPipelineGraphData {
  steps: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    DependsOn?: string[];
  }[];
  jobId: string;
}

export const SageMakerPipelineGraph = ({
  loading,
  data,
  error,
}: {
  loading: boolean;
  data: SageMakerPipelineGraphData | null;
  error: Error | null;
}) => {
  const nodes: {
    id: string;
    nodeHeight: number;
    data: {
      label: ReactNode;
    };
  }[] = [];

  const edges: {
    id: string;
    source: string;
    target: string;
    type: string;
    arrowHeadType: string;
  }[] = [];

  const [statusLoading, statusError, statusData, getStatus] =
    useGetSageMakerPipelineGraphStatus();

  useEffect(() => {
    if (!data) return;
    getStatus({ jobId: data.jobId });
  }, [data]);

  if (!loading && !data)
    return <p>No SageMaker Pipeline data available for this operator.</p>;

  data?.steps?.forEach((node) => {
    const status = statusData?.find(
      (nodeStatus) => nodeStatus.stepName === node.Name,
    );

    const stepStatus = status?.stepStatus.toLowerCase();

    nodes.push({
      id: node.Name,
      nodeHeight: 188,
      data: {
        label: (
          <>
            <div
              className={`sagemaker-pipeline-graph-node sagemaker-pipeline-graph-node--${stepStatus}`}
            >
              {stepStatus === 'starting' ||
              stepStatus === 'executing' ||
              stepStatus === 'stopping' ? (
                <KatSpinner size="small" />
              ) : (
                <div
                  className={`sagemaker-pipeline-graph-node__status sagemaker-pipeline-graph-node__status--${stepStatus}`}
                />
              )}
              {node.Name}
            </div>
            {stepStatus === 'failed' && (
              <div className="error-text">{status?.failureReason}</div>
            )}
          </>
        ),
      },
    });

    node.DependsOn?.forEach((dep) => {
      edges.push({
        id: `${dep}-${node.Name}`,
        source: dep,
        target: node.Name,
        type: 'straight',
        arrowHeadType: 'arrowclosed',
      });
    });
  });

  return (
    <ReactFlowProvider>
      <div
        className={cx('sagemaker-pipeline-graph', {
          'sagemaker-pipeline-graph--error': statusError || error,
        })}
      >
        {data && (
          <div className="sagemaker-pipeline-graph__refresh-button">
            <KatButton
              loading={statusLoading}
              variant="secondary"
              onClick={() => {
                getStatus({ jobId: data.jobId });
              }}
            >
              Refresh Status
            </KatButton>
          </div>
        )}
        {statusError && <p className="error-text">{statusError.message}</p>}

        {error ? (
          <p className="error-text">{error.message}</p>
        ) : (
          <Placeholder
            ready={!loading}
            shapes={<rect width="100%" height="41rem" />}
          >
            <GraphDiagram elements={[...nodes, ...edges]} />
          </Placeholder>
        )}
      </div>
    </ReactFlowProvider>
  );
};
