import { ChartData, Dataset } from 'src/context/apiState';
import React from 'react';

const katalColors = ['#008296', '#B6D0D3']; // 2.79:1 contrast ratio

// slice from the beginning of each dataset by amount in range, default 0
export const getDataByRecency = (datasets: Dataset[], range = 0): Dataset[] =>
  datasets.map((dataset) => ({
    ...dataset,
    data: dataset.data.slice(range * -1),
    ids: dataset.ids.slice(range * -1),
    pointRadius: dataset.pointRadius?.slice(range * -1),
  }));

// for each dataset, filter out any that don't match the selectedLabel or the compareMetric,
// then update the pointRadius of the id in the correct dataset to be bigger, also assign the color to the dataset
export const filterData = (
  datasets: Dataset[],
  selectedMetric: string,
  compareMetric: string,
  selectedJob: { id: string; datasetIndex: number } | null,
): Dataset[] => {
  const visibleDatasets = datasets.filter(
    (dataset) =>
      selectedMetric === dataset.label || compareMetric === dataset.label,
  );

  return visibleDatasets.map((dataset, index: number) => {
    let updatedDataset: Dataset;
    if (selectedJob && index === selectedJob.datasetIndex) {
      const updatedPointRadius = dataset.pointRadius.slice();
      updatedPointRadius.splice(dataset.ids.indexOf(selectedJob.id), 1, 6);

      updatedDataset = {
        ...dataset,
        pointRadius: updatedPointRadius,
      };
    } else {
      updatedDataset = dataset;
    }

    return {
      ...updatedDataset,
      borderColor: katalColors[index],
      backgroundColor: katalColors[index],
    };
  });
};

// loop through each job, then see if it has a value for each named metric. If it does, add the value
// to the chart data, otherwise add an 'undefined' in the data, to show that a run had no value for a metric
export const buildCompleteChartData = (
  data: MetricsData[],
  metricNames: Set<string>,
): ChartData | null => {
  const reversedData = data.reverse(); // the api returns the jobs newest to oldest

  const labels: string[] = reversedData.reduce(
    (prev: string[], curr: MetricsData) => {
      // add the first 5 of each job id as a label
      prev.push(curr.id.slice(0, 5));
      return prev;
    },
    [],
  );

  const datasets = reversedData.reduce(
    (prev: Record<string, Dataset>, curr: MetricsData) => {
      metricNames.forEach((name: string) => {
        const value = curr.metrics?.find(
          (metric: Metric) => metric.MetricName === name,
        )?.Value;
        if (prev[name]) {
          prev[name].data.push(value);
          prev[name].ids.push(curr.id);
          prev[name].pointRadius.push(4);
        } else {
          prev[name] = {
            label: name,
            ids: [curr.id],
            data: [value],
            borderColor: katalColors[0],
            pointHoverRadius: 6,
            pointRadius: [4],
            backgroundColor: katalColors[0],
          };
        }
      });

      return prev;
    },
    {},
  );

  return { labels, datasets: Object.values(datasets) };
};

// chartjs supplies an event and an element to the given handler (onHover)
// if there is an element, meaning I'm hovering over a point, then I set the canvas cursor property to
// be a pointer, otherwise, I'm not hovering over anything in the chart, and it should be default.
export const setCursorToPointerOverAnElement: any = (
  event: React.MouseEvent,
  element: { index: number; datasetIndex: number }[],
) => {
  const canvasElement: any = document.querySelectorAll(
    '#training-metrics-canvas',
  )[0];

  if (canvasElement) {
    canvasElement.style.cursor = element[0] ? 'pointer' : 'default';
  }
};

// returns a function that has access to chartData and set functions
export const setSelectedId: any =
  (
    chartData: ChartData,
    setSelectedJobId: (id: string) => void,
    setSelectedJob: ({
      id,
      datasetIndex,
    }: {
      id: string;
      datasetIndex: number;
    }) => void,
  ) =>
  // the click handler gets the event and an element (the chart element that was clicked).
  // the returned function gets an id from the element (from chartjs) and uses it with the set functions
  (
    event: React.MouseEvent,
    element: { index: number; datasetIndex: number }[],
  ) => {
    if (chartData?.datasets.length === 0 || element.length === 0) return;

    const id =
      chartData.datasets[element[0].datasetIndex].ids[element[0].index];

    setSelectedJobId(id);

    setSelectedJob({
      id,
      datasetIndex: element[0].datasetIndex,
    });
  };
