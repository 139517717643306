import React from 'react';

import { Link } from 'react-router-dom';
import { KatLink } from '@amzn/katal-react';

export const MonitoringScheduleIdColumn = (
  accountNo: string,
  applicationId: string,
  modelVersionId: string,
  modelArtifactId: string,
) => {
  const MonitoringScheduleIdColumnCell = (cellInfo: any) => (
    <Link
      to={
        `/accounts/${accountNo}/applications/${applicationId}/modelVersions` +
        `/${modelVersionId}/modelArtifacts/${modelArtifactId}/monitoringSchedules/${cellInfo.value}/dataQualityMetrics`
      }
    >
      <KatLink>{cellInfo.value}</KatLink>
    </Link>
  );
  return {
    Header: 'Id',
    accessor: 'id',
    sortable: true,
    width: 300,
    Cell: MonitoringScheduleIdColumnCell,
  };
};
