import React, { useState } from 'react';
import { KatDateRangePicker, KatButton } from '@amzn/katal-react';
import { getDateRangeFromToday } from 'src/utils/timeUtils/timeUtils';

export interface DateRangePickerProps {
  label?: string;
  initDateRange?: { startDate: Date; endDate: Date };
  changeDateRange: (arg: DateRange) => void;
  setSearchParams?: (arg: Record<string, string>) => void;
  disableShortcuts?: boolean;
}

export function DateRangePicker({
  label,
  initDateRange = getDateRangeFromToday(7),
  changeDateRange,
  setSearchParams,
  disableShortcuts,
}: DateRangePickerProps) {
  const [startDate, setStartDate] = useState<Date>(initDateRange.startDate);
  const [endDate, setEndDate] = useState<Date>(initDateRange.endDate);

  const isDateRangeValid = (startDate: Date, endDate: Date) =>
    startDate <= endDate;

  const submit = (dateRange: DateRange) => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
    if (isDateRangeValid(dateRange.startDate, dateRange.endDate)) {
      setSearchParams?.({
        startDate: dateRange.startDate.toJSON(),
        endDate: dateRange.endDate.toJSON(),
      });
      changeDateRange({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    }
  };

  return (
    <div className="date-range-picker">
      <div className="date-range-picker-container">
        <div className="date-range-picker__wrapper">
          <KatDateRangePicker
            startLabel={`${label || ''} Start Date`}
            endLabel={`${label || ''} End Date`}
            startValue={startDate}
            endValue={endDate}
            onChange={(time) => {
              setStartDate(new Date(time.detail.startDate));
              setEndDate(new Date(time.detail.endDate));
            }}
          />
          <KatButton
            className="date-range-picker-button"
            label="Search"
            variant="primary"
            disabled={!isDateRangeValid(startDate, endDate)}
            onClick={() => submit({ startDate, endDate })}
          />
        </div>
        {!disableShortcuts && (
          <div className="date-range-picker__range-shortcuts">
            <KatButton
              variant="tertiary"
              onClick={() => {
                const dateRange = getDateRangeFromToday(0);
                submit({
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                });
              }}
            >
              Today
            </KatButton>
            <KatButton
              variant="tertiary"
              onClick={() => {
                const dateRange = getDateRangeFromToday(7);
                submit({
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                });
              }}
            >
              One Week
            </KatButton>
            <KatButton
              variant="tertiary"
              onClick={() => {
                const dateRange = getDateRangeFromToday(30);
                submit({
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                });
              }}
            >
              One Month
            </KatButton>
          </div>
        )}
      </div>
      {!isDateRangeValid(startDate, endDate) && (
        <span className="error-text">Date range is invalid.</span>
      )}
    </div>
  );
}
