import React, { ReactNode } from 'react';
import ContentLoader from 'react-content-loader';

export interface PlaceholderProps {
  shapes: ReactNode;
  children: ReactNode;
  ready: boolean;
  options?: {
    speed?: number;
    width?: string;
    height?: string;
    viewBox?: string;
    backgroundColor?: string;
    foregroundColor?: string;
  };
}

export const Placeholder = ({
  shapes,
  children,
  ready,
  options = {
    speed: 2,
    width: '100%',
    height: '100vh',
    backgroundColor: '#f3f3f3',
    foregroundColor: '#ecebeb',
  },
}: PlaceholderProps) => {
  if (ready) return children;
  return <ContentLoader {...options}>{shapes}</ContentLoader>;
};
