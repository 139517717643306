import React, { useContext, useState } from 'react';
import { ADD_TOAST } from 'src/context/reducer';
import { ApiContext } from 'src/context/apiContext';
import { apiPostRequest } from 'src/utils/mlPigeonAPIRequest/mlPigeonAPIRequest';

export const useTriggerWorkflow = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);
  const { dispatch } = useContext(ApiContext);

  const makeCall = ({
    startingOperatorType,
    startingOperatorName,
    variables,
    region,
    modelVersionId,
  }: {
    startingOperatorType: string;
    startingOperatorName: string;
    variables: { key: string; value: string }[];
    region: string;
    modelVersionId: string;
  }) => {
    setLoading(true);
    const formattedVariables = variables.reduce(
      (prev: Record<string, string>, curr) => {
        prev[curr.key] = curr.value;
        return prev;
      },
      {},
    );
    const workflowTriggerDetails = {
      modelVersionId,
      startingOperatorType,
      startingOperatorName,
      variables: formattedVariables,
      region,
    };
    return apiPostRequest({
      endpoint: '/trigger/workflow',
      data: workflowTriggerDetails,
    })
      .then((res) => {
        setResponse(res);
        dispatch({
          type: ADD_TOAST,
          payload: {
            header: 'Request Submitted Successfully',
            variant: 'success',
            description: (
              <div>
                <p>Request Submitted Successfully</p>
              </div>
            ),
          },
        });
      })
      .catch((err) => {
        setError(err);
        dispatch({
          type: ADD_TOAST,
          payload: {
            header: 'Error Sending Request',
            variant: 'danger',
            description: (
              <div>
                <p>There was an issue submitting your request. {err.message}</p>
              </div>
            ),
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return [loading, error, response, makeCall];
};
