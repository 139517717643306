import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { KatButton } from '@amzn/katal-react';
import { ApplicationLinkCell } from 'src/components/table-cells/ApplicationLinkCell/ApplicationLinkCell';
import { DataTable } from 'src/components/DataTable/DataTable';
import { DateColumn } from 'src/components/DateColumn/DateColumn';
import { useApplications } from 'src/hooks/useApplications';
import { ApplicationModal } from 'src/components/modals/Application/ApplicationModal';

export const ApplicationList = () => {
  const { accountNo } = useParams();
  const { data, isLoading } = useApplications({
    account: accountNo,
  });
  const [showRelativeDate, setShowRelativeDate] = useState<boolean>(true);
  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const [applicationModalMode, setApplicationModalMode] = useState<
    'New' | 'Edit' | 'Delete'
  >('New');
  const [applicationModalValues, setApplicationModalValues] =
    useState<any>(null);

  const showApplicationModal = (
    mode: 'New' | 'Edit' | 'Delete',
    values: any,
  ) => {
    setApplicationModalValues(values);
    setApplicationModalMode(mode);
    setApplicationModalVisible(true);
  };
  const columns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
      sortable: true,
      Cell: ApplicationLinkCell,
    },
    {
      Header: 'Description',
      id: 'description',
      accessor: 'description',
    },
    {
      Header: 'Id',
      id: 'id',
      accessor: 'id',
    },
    DateColumn({
      header: 'Updated Date',
      id: 'updated-date',
      accessor: 'updatedDate',
      showRelativeDate,
      setShowRelativeDate,
    }),
    {
      Header: 'Updated By',
      id: 'updated-by',
      accessor: 'updatedBy',
      sortable: true,
    },
    {
      id: 'buttons',
      width: 220,
      disableResizing: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: (props: any) => (
        <div className="button-cell">
          <KatButton
            onClick={() => showApplicationModal('Edit', props.row.original)}
            label="Edit"
            variant="secondary"
          />
          <KatButton
            label="Delete"
            variant="danger"
            onClick={() => showApplicationModal('Delete', props.row.original)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="application-list">
      <DataTable
        heading="Applications"
        controls={
          <KatButton
            onClick={() => {
              showApplicationModal('New', null);
            }}
            label="Create a New Application"
            variant="primary"
          />
        }
        loading={isLoading}
        columns={columns}
        data={data || []}
        options={{
          defaultSort: {
            id: 'updated-date',
            desc: true,
          },
          noDataText: 'No applications found.',
        }}
      />
      {accountNo && (
        <ApplicationModal
          visible={applicationModalVisible}
          modalMode={applicationModalMode}
          values={applicationModalValues}
          onClose={() => {
            setApplicationModalVisible(false);
          }}
          account={accountNo}
        />
      )}
    </div>
  );
};
