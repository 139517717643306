import { html, internalProperty, state, property } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';
import { nothing } from 'lit-html';
import {
  Keys,
  register,
  nextUniqueId,
  event,
  EventEmitter,
  KatLitMobileElement,
} from '../../shared/base';
import { ifNotNull } from '../../utils/directives';
import { formInputMap } from '../../utils/form-input-map';
import {
  formatDateObject,
  isValidDateObject,
  getDateFormatFromLocale,
  createDateFromStringAndFormat,
} from '../../shared/base/date-utils';
import {
  hasMobileIncludeClass,
  isMobile,
  mobileMediaQuery,
} from '../../utils/mobile-helpers';
import getModalString from '../modal/strings';
import getString from '../date-picker/strings';
import { KatCalendar } from '../calendar';
import baseStyles from '../../shared/base/base.lit.scss';
import formItemStyles from '../../shared/base/form-item.base.lit.scss';
import styles from './date-picker.lit.scss';

/**
 * @component {kat-date-picker} KatalDatepicker The date picker allows users to enter a date. A date can be input directly into the input box or through the <a href="/components/calendar/">calendar component</a>, which is triggered by the calendar icon within the date picker.
 * @example UnitedStatesLocale {"locale": "en-US", "label": "US English", "value": ""}
 * @example Disabled {"locale": "en-US", "label": "Disabled", "value": "", "disabled": "true"}
 * @example GermanLocale {"locale": "de-DE", "label": "German", "value": "01.01.2018"}
 * @example ChineseLocale {"locale": "zh-CN", "label": "Mandarin", "value": "2019/01/01", "script": ""}
 * @example IsoFormat {"locale": "en-US", "label": "Set by ISO String", "value": "2019-09-21T12:00:00.000Z", "script": ""}
 * @example DisabledWeekends {"locale": "en-US", "label": "Disabled Weekends",
 * "script": "
 *   document.querySelector(\"kat-date-picker\").isDateDisabled = function (date) {
 *     return (date.getDay() === 0 || date.getDay() === 6);
 *   };
 * "}
 * @example ColorCodedDates {"locale": "en-US", "label": "Color coded dates",
 * "script": "
 *   var SCARY_DAY_OF_MONTH = 13;
 *   document.querySelector(\"kat-date-picker\").getDateDecorationConfig = function (date) {
 *     if (date.getDate() === SCARY_DAY_OF_MONTH) {
 *         return {style: 'color-code-01', ariaLabel: 'Do not book today.'};
 *     }
 *     return null;
 *   };
 * "}
 * @example WithTooltip {"locale": "en-US", "label": "Date picker with tooltip", "value": "",
 * "tooltip-text": "Hello world"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-date-picker')
export class KatDatePicker extends KatLitMobileElement {
  /**
   * Set the value for the date picker. This will only emit an 'invalid' date if it is not well-formed. No 'change' event is
   * emitted if successfully set
   * @type {string | Date}
   */
  @property()
  get value(): string {
    return this._value || '';
  }

  set value(value: string) {
    const oldValue = this.value;
    this.rawValue = value;
    if (!this.locale) return;

    if (
      value == null ||
      value === '' ||
      !(
        value instanceof Date ||
        typeof value === 'string' ||
        typeof value === 'number'
      )
    ) {
      this.updateValueAndDate(null, null);
      return;
    }

    if (typeof value === 'string') {
      // Cast to integer if is number
      if (value.match(/^[0-9]+$/)) {
        value = new Date(parseInt(value, 10));
      } else if (value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)) {
        value = new Date(value);
      }
    } else if (typeof value === 'number') {
      value = new Date(value);
    }

    let dateObject = value;
    let formattedValue = value;

    // Try converting to format specified by locale
    if (value instanceof Date) {
      formattedValue = formatDateObject(value, this.placeholder);
    } else {
      dateObject = createDateFromStringAndFormat(value, this.placeholder);
    }

    if (dateObject && this.isDateDisabled && this.isDateDisabled(dateObject)) {
      this.focus();
      return;
    }

    if (!isValidDateObject(dateObject)) {
      this._invalid.emit({ value });
      this.updateValueAndDate(null, null);
      return;
    }

    this.updateValueAndDate(formattedValue, dateObject);
    this.requestUpdate('value', oldValue);
  }

  /**
   *  The current value as a standard Date object. Setting this attribute/property
   * is exactly the same as setting "value". The "date-value" attribute will not
   * be kept up-to-date automatically.
   * @type {string | Date}
   */
  @property({ type: String, reflect: false })
  get dateValue(): string {
    return this._dateObject;
  }

  set dateValue(value: string) {
    this.value = value;
  }

  @internalProperty()
  _dateObject: Date = null;

  @internalProperty()
  showing = false;

  @internalProperty()
  focused = false;

  /** The locale the calendar should be using */
  @property()
  get locale(): string {
    return this._locale;
  }

  set locale(locale: string) {
    const oldLocale = this._locale;
    this._locale = locale;
    if (this.rawValue && !this.value) {
      this.value = this.rawValue;
    } else if (this.value?.length && oldLocale && locale) {
      const oldFormatString = getDateFormatFromLocale(oldLocale);
      const newFormatString = getDateFormatFromLocale(locale);
      const currentDate = createDateFromStringAndFormat(
        this.value,
        oldFormatString
      );
      this.value = formatDateObject(currentDate, newFormatString);
    }
    this.requestUpdate('locale', oldLocale);
  }

  /** Set the Label for the input */
  @property()
  label?: string;

  /** Set the name attribute for the input */
  @property()
  name?: string;

  /** If true, the datepicker will be disabled and unable to be interacted with. */
  @property()
  disabled?: boolean;

  /**
   * Sets the function used to determine which dates are disabled. Called for each date rendered in the
   * date picker to determine if the date should be disabled. If this function returns true, the given date will be disabled. If the logic of this function is
   * changed, the disabled state will only be displayed after the next time the user opens the date picker calendar. If instead you use this setter to set the
   * function again, the calendar will be re-rendered immediately with the new logic.
   */
  @property({ attribute: false })
  isDateDisabled?: (date: Date) => boolean;

  /** Optional pretext for the constraint label.  Used to provide additional context to the constraint for the date-picker */
  @property({ attribute: 'constraint-emphasis' })
  constraintEmphasis?: string;

  /** Provides users with more information about what they enter into the date-picker */
  @property({ attribute: 'constraint-label' })
  constraintLabel?: string;

  /**
   * The size of the date picker.
   * @enum {value} large Large date picker - Default
   * @enum {value} small Small date picker
   */
  @property()
  size?: 'large' | 'small' = 'large';

  /**
   * Setting the state of the date-picker changes its look to give the user more information about what they have entered.  This value must be set for State Labels to show up.
   * @enum {value} error Lets the user know there is a problem with the value they have entered in the date-picker.
   */
  @property()
  state?: string;

  /** Optional pretext for the state label. Used to provide additional context to the state for the date-picker */
  @property({ attribute: 'state-emphasis' })
  stateEmphasis?: string;

  /** Provides users with more information about why the date-picker is in the state it's in. */
  @property({ attribute: 'state-label' })
  stateLabel?: string;

  /**
   * Defines a function that is called each time a day is rendered.
   * Passes the date the day represents.
   * Returns a configuration object that contains the date rendering style and accessibility content
   * or null if no specific styling is applicable to the date.
   * style attribute must be one of "color-code-01" | "color-code-02" | "color-code-03" | "color-code-04" | "color-code-05" |
   * "color-code-06" | "color-code-07" | "color-code-08" | "color-code-09" | "color-code-10".
   *
   * Each style must be accompanied with a corresponding accessibility blurb that describes the specifics of the date.
   *
   * E.G. {style: 'color-code-01', ariaLabel: 'Bookings today require payment.'}
   */
  @property({ attribute: false })
  getDateDecorationConfig?: (
    date: Date
  ) => { style: string; ariaLabel: string } | null;

  /**
   * Specifies the autocomplete behavior that a browser should attempt:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  @property()
  autocomplete = 'off';

  /** The label used exclusively by screenreaders as a fallback if the regular label is not specified. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /**
   * The text to be shown inside the tooltip placed next to the label text. The tooltip will only appear if this is
   * set.
   */
  @property({ attribute: 'tooltip-text' })
  tooltipText?: string;

  /** The position of the tooltip. Defaults to "top". */
  @property({ attribute: 'tooltip-position' })
  tooltipPosition?: string;

  /** The icon that triggers the tooltip next to the label. Defaults to "help_outline". */
  @property({ attribute: 'tooltip-trigger-icon' })
  tooltipTriggerIcon?: string;

  /** Displays an 'x' icon to clear the input. Defaults to `false`. */
  @property({ attribute: 'show-clear' })
  showClear?: boolean;

  /**
   * Observes mobile viewport width as state so that DatePicker only reacts when
   * this changes values, instead of on every resize event.
   */
  @state()
  isMobileView = isMobile();

  @state()
  _uniqueLabelId: string;

  static get styles() {
    return [baseStyles, formItemStyles, styles];
  }

  /** Fires when either date is updated. */
  @event('change', true)
  private _change: EventEmitter<{
    value: string | Date; // TODO: this should not be one or the other
    isoValue: string;
    dateObject: Date;
  }>;

  /** Fires when the entered value is not a valid Date. The invalid value will be attached to the event as event.detail.value. */
  @event('invalid', true)
  private _invalid: EventEmitter<{ value: string }>;

  constructor() {
    super();

    this._uniqueLabelId = nextUniqueId();

    this.addEventListener('focusin', this._onFocusIn);
    this.addEventListener('click', this._onClick);
    this.addEventListener('focusout', this._onFocusOut);

    this.expandedPropertyName = 'showing';
  }

  connectedCallback() {
    super.connectedCallback();
    mobileMediaQuery.addEventListener('change', (e: MediaQueryListEvent) =>
      this._onMediaChange(e)
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    mobileMediaQuery.removeEventListener('change', (e: MediaQueryListEvent) =>
      this._onMediaChange(e)
    );
  }

  firstUpdated() {
    super.firstUpdated();
    if (!this.locale) {
      this.locale = 'en-US';
    }
  }

  @formInputMap([
    {
      tag: 'input',
      name: (component: KatDatePicker) => component.name,
      isNeeded: (component: KatDatePicker) => component.isFormInputNeeded(),
      setup: (component: KatDatePicker, input: HTMLInputElement) =>
        component.setupFormInput(input),
    },
  ])
  updated(changedProps: Map<string, any>) {
    super.updated(changedProps);

    if (changedProps.has('showing')) {
      if (this.showing && isMobile()) {
        this.calendarFocus();
      }
    }
  }

  isFormInputNeeded() {
    return !(this.disabled || !this.name);
  }

  setupFormInput(input: HTMLInputElement) {
    input.value = this.value;
  }

  updateValueAndDate(val, datObj) {
    // In the context of "set value", these three should be set together
    this._dateObject = datObj;
    this._value = val;
    this.lastValue = this._value;
  }

  get placeholder() {
    return getDateFormatFromLocale(this.locale);
  }

  show() {
    if (this.showing || this.disabled) {
      return;
    }
    this.showing = true;
  }

  hide() {
    if (this.static || !this.showing) {
      return;
    }
    this.showing = false;
  }

  // Called when a user interaction changes the selected date
  emitChangeEvent(value) {
    if (value === this.lastValue) {
      return;
    }

    // Setting value sets this.lastValue
    this.value = value;

    const isoValue = this._dateObject ? this._dateObject.toISOString() : '';
    this._change.emit({
      value,
      isoValue,
      dateObject: this._dateObject,
    });
  }

  private _onMediaChange(e: MediaQueryListEvent) {
    this.isMobileView = e.matches && hasMobileIncludeClass();
    this.requestUpdate();
  }

  // Stop the outer click handler from executing
  private handleMobileHeaderClick(e: Event) {
    e.stopPropagation();
    this.hide();
  }

  render() {
    const calendarClasses = { show: this.showing };
    const groupClasses = { input__container: true, focused: this.focused };
    const label = this.label
      ? html`<kat-label
          for="${this._uniqueLabelId}"
          class="date-label"
          part="date-picker-label"
          text=${this.label}
          .tooltipText=${ifNotNull(this.tooltipText)}
          .tooltipPosition=${ifNotNull(this.tooltipPosition)}
          .tooltipTriggerIcon=${ifNotNull(this.tooltipTriggerIcon)}
        ></kat-label>`
      : nothing;

    const mobileLabel = super.getMobileHeader(
      this.showing,
      this.handleMobileHeaderClick,
      getModalString('kat-modal-close', null, this.locale),
      this.label
    );

    const ariaLabel = this.katAriaLabel ? this.katAriaLabel : this.label;

    const constraintLabel = this.constraintLabel
      ? html`
          <kat-label
            class="constraint-label"
            part="date-picker-constraint-label"
            for=${this._uniqueLabelId}
            variant="constraint"
            emphasis=${ifNotNull(this.constraintEmphasis)}
            text=${ifNotNull(this.constraintLabel)}
          ></kat-label>
        `
      : nothing;

    const stateLabel =
      (this.stateLabel || this.stateEmphasis) && this.state
        ? html`
            <kat-label
              class="state-label"
              part="date-picker-state-label"
              variant="constraint"
              text=${ifNotNull(this.stateLabel)}
              emphasis=${ifNotNull(this.stateEmphasis)}
              state=${ifNotNull(this.state)}
            ></kat-label>
          `
        : nothing;

    return html`<div class="container">
        ${label}
        <div class=${classMap(groupClasses)}>
          <kat-icon
            name="calendar-alt"
            part="date-picker-icon"
            class="icon"
            role="button"
            tabindex="-1"
            aria-label="${this.getAriaLabel()}"
            aria-expanded=${this.showing}
            @click=${this.show}
          ></kat-icon>
          <kat-input
            type="text"
            part="date-picker-input"
            class="input"
            placeholder=${ifNotNull(this.placeholder)}
            name=${ifNotNull(this.name)}
            value=${ifNotNull(this.value)}
            autocomplete=${ifNotNull(this.autocomplete)}
            ?disabled=${this.disabled}
            kat-aria-label=${ariaLabel}
            unique-id=${this._uniqueLabelId}
            size=${this.size}
            @change=${this._onInputChange}
            @keydown=${this._onInputKey}
            @click=${this._onClick}
            @focusin=${this._onInputFocusIn}
            ?show-clear=${this.showClear !== undefined
              ? this.showClear
              : this.isMobileView}
          ></kat-input>
        </div>
        <div class="metadata">${constraintLabel} ${stateLabel}</div>
      </div>
      <div part="${this.getPartMask(this.showing)}">
        <div part="${this.getPartWrapper(this.showing)}">
          <div part="${this.getPartContainer(this.showing)}">
            <div part="${this.getPartContent(this.showing)}">
              ${mobileLabel}
              <kat-calendar
                tabindex="0"
                part="${super.getPartItem(
                  this.showing,
                  'date-picker-calendar'
                )}"
                class=${classMap(calendarClasses)}
                locale=${ifNotNull(this.locale)}
                .isDateDisabled=${this.isDateDisabled}
                .getDateDecorationConfig=${this.getDateDecorationConfig}
                .value=${this._dateObject}
                @hide=${this._onCalendarHide}
                @change=${this._onCalendarChange}
                @focusin=${this._onCalendarFocus}
                @fullblur=${this._onCalendarBlur}
              ></kat-calendar>
            </div>
          </div>
        </div>
      </div>`;
  }

  _onClick(e: MouseEvent) {
    // Yuck.
    const clearIcon = this.shadowRoot
      .querySelector('kat-input')
      .shadowRoot.querySelector('button.close-icon');
    // Prevent focusing when clearing the input
    if (e?.composedPath().includes(clearIcon)) {
      return;
    }

    this.show();
    if (isMobile()) {
      this.calendarFocus();
    } else {
      this.inputFocus();
    }
  }

  _onInputChange(e) {
    e.stopImmediatePropagation();
    this.emitChangeEvent(e.target.value);
  }

  _onFocusIn() {
    this.focused = true;
  }

  _onInputFocusIn(e) {
    if (isMobile()) {
      this._onClick(e);
    }
  }

  _onFocusOut(e) {
    e.stopImmediatePropagation();
    const relatedTarget = e.relatedTarget;

    const relatedTargetInsideComponent =
      relatedTarget && this.shadowRoot.contains(relatedTarget);

    if (!relatedTargetInsideComponent) {
      this.hide();
      this.focused = false;
    }
  }

  /** Triggered when a date is picked from KatCalendar */
  _onCalendarChange(e) {
    e.stopImmediatePropagation();

    // Receives the date event and renders it using the date format
    const formattedDate = formatDateObject(e.detail.value, this.placeholder);

    this.emitChangeEvent(formattedDate);
    this.hide();

    if (!isMobile()) {
      this.focus();
    }
  }

  /** Triggered when KatCalendar is closed via the Escape key */
  _onCalendarHide(e) {
    e.stopImmediatePropagation();
    this.hide();
    this.focus();
  }

  _onCalendarFocus(e) {
    e.stopImmediatePropagation();
  }

  _onInputKey(e) {
    if (e.keyCode === Keys.Enter) {
      this.show();
    }
    if (e.keyCode === Keys.Escape) {
      this.hide();
    }
  }

  focus() {
    if (this.showing) {
      this.calendarFocus();
    } else {
      this.inputFocus();
    }
  }

  calendarFocus() {
    const calendar = this.shadowRoot.querySelector<KatCalendar>('kat-calendar');
    this.show();
    calendar.focus();
  }

  inputFocus() {
    const input = this.shadowRoot.querySelector<HTMLElement>('kat-input');
    input.focus();
  }

  getAriaLabel() {
    return getString(
      'date-picker-calendar-button-aria-label',
      null,
      this.locale
    );
  }
}
