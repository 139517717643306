import React, { Children, ReactNode } from 'react';
import cx from 'classnames';

export const Tab = ({
  children,
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  label,
}: {
  children: ReactNode;
  id: string;
  label: string;
}) => (
  <div data-id={id} className="tab">
    {children}
  </div>
);

export const Tabs = ({
  children,
  selectedPanel,
  setSelectedPanel,
  focusable = true,
}: {
  children: ReactNode;
  selectedPanel: string;
  setSelectedPanel: (id: string) => void;
  focusable?: boolean;
}) => {
  const arrayChildren = Children.toArray(children);

  return (
    <div className="tabs">
      <div className="tabs__header">
        <ul className="tab-list">
          {Children.map(arrayChildren, (tab: any) => (
            <li
              className={cx('tab-list__item', {
                'tab-list__item--selected': tab.props.id === selectedPanel,
              })}
            >
              <button
                role="tab"
                tabIndex={!focusable ? -1 : undefined}
                onClick={() => {
                  focusable && setSelectedPanel(tab.props.id);
                }}
              >
                {tab.props.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {Children.map(arrayChildren, (tab: any) => (
        <div
          role="tabpanel"
          className={cx('tab-panel', {
            hidden: tab.props.id !== selectedPanel,
          })}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};
