import React from 'react';

import { parse } from '@aws-sdk/util-arn-parser';

export const RegionColumn = () => {
  const RegionColumnCell = (cellInfo: any) => {
    let monitoringScheduleArn;
    try {
      monitoringScheduleArn = parse(cellInfo.value);
    } catch {
      monitoringScheduleArn = null;
    }
    return monitoringScheduleArn ? (
      <div>{monitoringScheduleArn.region}</div>
    ) : (
      <div>Not available.</div>
    );
  };

  return {
    Header: 'Region',
    accessor: 'monitoringScheduleArn',
    sortable: true,
    width: 120,
    Cell: RegionColumnCell,
  };
};
