import React from 'react';
import { KatList } from '@amzn/katal-react';
import pythonLogo from '../../public/python.svg';
import dockerLogo from '../../public/docker.svg';
import { Link } from 'src/components/Link';

export const DevelopmentSection = () => (
  <section className="development-section">
    <div className="development-section__container">
      <div className="development-section__heading">
        <h2>Model Development Support</h2>
      </div>
      <div className="development-section__wrapper">
        <div className="development-section__content">
          <h3>Python Package Repository</h3>
          <p>
            In collaboration with Builder Tools, we&apos;ve created an AWS
            CodeArtifact repository, for use with external, third-party python
            packages.
          </p>
          <p>
            This allow MLPigeon users to make use of external packages, but with
            coverage for:
          </p>
          <div className="development-section__list">
            <KatList variant="bullet">
              <li>Security concerns</li>
              <li>License validation</li>
              <li>Risk detection</li>
              <li>Malicious Code issues</li>
            </KatList>
            <img src={pythonLogo} alt="python" />
          </div>

          <div className="button-wrapper">
            <Link
              to="https://w.amazon.com/bin/view/MLPigeon/CustomModelDockerImageBuilder/Dependencies"
              external
            >
              Search Packages
            </Link>
            <Link to="https://w.amazon.com/bin/view/MLPigeon" external>
              Learn More
            </Link>
          </div>
        </div>

        <div className="development-section__content">
          <h3>Custom Model Docker Image Builder</h3>
          <p>
            Provides a starting Brazil package and <strong>pigeon</strong> cli
            for building your custom training logic and / or custom inference
            logic into a Docker image that can be easily used in MLPigeon and
            SageMaker.
          </p>
          <p>
            Supports customer owned Python packages that can be shared between
            multiple models.
          </p>
          <div className="development-section__list">
            <img src={dockerLogo} alt="docker" />
          </div>
          <Link
            to="https://w.amazon.com/bin/view/MLPigeon/CustomModelDockerImageBuilder"
            external
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  </section>
);
