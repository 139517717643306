import { urlList } from 'src/context/url';
import { TrainingJob } from 'src/context/apiState';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/context/userContext';

export interface TrainingJobAPIResponse {
  id: string;
  modelArtifactId: string;
  trainingJobArn: string;
  trainingJobName: string;
  s3TrainingDatasetLocation: string;
  s3ArtifactsLocation: string;
  status: string;
  metrics?: string;
  operatorFlowJobId: string;
  operatorName: string;
  upstreamJobRelationships: { jobId: string; jobType: string }[];
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
}

export const useTrainingJobs = (
  modelArtifactId: string,
): [boolean, Error | null, TrainingJob[] | null] => {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<TrainingJob[] | null>(null);
  useEffect(() => {
    if (!modelArtifactId) return;
    setLoading(true);

    fetch(
      urlList.trainingJobs +
        '?' +
        new URLSearchParams({
          modelArtifactId,
        }),
      {
        headers: {
          Authorization: user.token,
          'Content-type': 'application/json',
        },
      },
    )
      .then(async (res) => {
        if (!res.ok) {
          const apiResponse = await res.json();
          if (apiResponse.message) {
            if (
              apiResponse.message.includes(
                'No objects of type TrainingJob found',
              )
            ) {
              throw new Error('No Training Jobs have been run on this model.');
            }
            throw new Error(apiResponse.message);
          }
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((jobData: TrainingJobAPIResponse[]) => {
        const jobList: TrainingJob[] = jobData.map((job) => {
          let parsedData;
          if (job.metrics && job.metrics !== 'null') {
            try {
              parsedData = JSON.parse(job.metrics);
            } catch {
              parsedData = [];
            }
          } else {
            parsedData = [];
          }

          return {
            ...job,
            metrics: parsedData,
          };
        });
        setData(jobList);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [modelArtifactId, user.token]);

  return [loading, error, data];
};
