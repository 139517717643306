import { parse } from '@aws-sdk/util-arn-parser';
import { SageMakerLink } from 'src/components/SageMakerLink/SageMakerLink';
import { Link } from 'react-router-dom';
import React from 'react';

export const TrainingJobCell = ({
  createdDate,
  trainingJobArn,
  trainingJobName,
  id,
  state,
}: {
  createdDate?: number;
  trainingJobArn: string;
  trainingJobName: string;
  id: string;
  state?: {
    accountNo: string;
    applicationId: string;
    modelVersionId: string;
    modelArtifactId: string;
  };
}) => {
  let arn;
  try {
    arn = parse(trainingJobArn);
  } catch {
    arn = null;
  }
  return (
    <div className="training-job-cell">
      <div>
        <p className="training-job-cell__id">{id}</p>
        <p>{trainingJobName}</p>
      </div>
      <div className="training-job-cell__link-group">
        {arn && <SageMakerLink arn={arn} />}
        {state && (
          <Link
            to={`/accounts/${state.accountNo}/applications/${
              state.applicationId
            }/modelVersions/${state.modelVersionId}/modelArtifacts/${
              state.modelArtifactId
            }/trainingJobs/${id}/dataQualityMetrics${
              createdDate
                ? `?startDate=${new Date(
                    createdDate * 1000,
                  ).toUTCString()}&endDate=${new Date(
                    createdDate * 1000,
                  ).toUTCString()}`
                : ''
            }`}
          >
            View Data Quality Metrics
          </Link>
        )}
      </div>
    </div>
  );
};
