import React, { useRef } from 'react';
import { KatInput, KatLabel } from '@amzn/katal-react';

export const KeyValuePairInput = ({
  disabled,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  state,
  stateLabel,
  type,
  value,
}: {
  placeholder?: string[];
  disabled?: boolean;
  label?: string;
  name: string;
  onBlur?: (arg: KatInput.BlurEvent) => void;
  onChange?: (arg: KatInput.ChangeEvent) => void;
  state?: KatInput.State;
  stateLabel?: string;
  type?: KatInput.Type[];
  value?: string[];
}) => {
  const keyInputRef = useRef<any>();
  const valueInputRef = useRef<any>();

  const handleBlur = () => {
    onBlur?.({
      target: {
        // @ts-expect-error
        name,
        value: {
          key: keyInputRef.current.value,
          value: valueInputRef.current.value,
        },
      },
    });
  };

  const handleChange = () => {
    onChange?.({
      target: {
        // @ts-expect-error
        name,
        value: {
          key: keyInputRef.current.value,
          value: valueInputRef.current.value,
        },
      },
    });
  };

  const fieldLabel = `key-value-pair-field-label-${name}`;

  return (
    <div className="key-value-pair-input">
      <KatLabel id={fieldLabel} className="key-value-pair-input__label">
        {label}
      </KatLabel>
      <div className="key-value-pair-input__fields">
        <KatInput
          katAriaLabel={fieldLabel}
          ref={keyInputRef}
          type={type?.[0]}
          disabled={disabled}
          name={name[0]}
          value={value?.[0]}
          onBlur={handleBlur}
          onChange={handleChange}
          state={state}
          placeholder={placeholder?.[0]}
        />
        <KatInput
          katAriaLabel={fieldLabel}
          ref={valueInputRef}
          disabled={disabled}
          name={name[0]}
          value={value?.[1]}
          onBlur={handleBlur}
          onChange={handleChange}
          state={state}
          placeholder={placeholder?.[1]}
        />
      </div>
      {state === 'error' && <p className="error-text">{stateLabel}</p>}
    </div>
  );
};
