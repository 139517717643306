import { nothing } from 'lit-html';
import { html, property } from 'lit-element';
import {
  Keys,
  register,
  event,
  EventEmitter,
  KatLitMobileElement,
} from '../../shared/base';
import { fitHandler } from '../dropdown/fit-handler';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './dropdown-button.lit.scss';
import getString from './strings';

/**
 * @component {kat-dropdown-button} KatalDropdownbutton A dropdown button provides a default primary action to users and offers a set of secondary actions behind a dropdown menu.
 * @example Basic {"variant": "primary", "size": "base", "disabled": false, "responsive": false, "options": [{"label":"Button text","action":"action1"},{"label":"Option 1","action":"action2"},{"label":"Option 2","action":"action3"}]}
 * @example Small {"variant": "primary", "size": "small", "disabled": false, "responsive": false, "options": [{"label":"Small","action":"action1"},{"label":"Option 1","action":"action2"},{"label":"Option 2","action":"action3"}]}
 * @example Disabled {"variant": "primary", "size": "small", "disabled": true, "responsive": false, "options": [{"label":"Disabled","action":"action1"},{"label":"Option 1","action":"action2"},{"label":"Option 2","action":"action3"}]}
 * @example Secondary {"variant": "secondary", "size": "base", "disabled": false, "responsive": false, "options": [{"label":"Secondary","action":"action1"},{"label":"Option 1","action":"action2"},{"label":"Option 2","action":"action3"}]}
 * @status Production
 * @a11y {keyboard}
 * @a11y {contrast}
 * @a11y {sr}
 */
@register('kat-dropdown-button')
export class KatDropdownButton extends KatLitMobileElement {
  static get styles() {
    return [baseStyles, styles];
  }

  /** Configuration for the buttons shown. The first option displayes as the main button. The rest will be within the dropdown. */
  @property()
  options: { label: string; action: string; disabled?: boolean }[] = [];

  /** Whether the component is currently showing its dropdown options. */
  @property()
  expanded?: boolean = false;

  /** Whether the first option is displayed in the dropdown. */
  @property({ attribute: 'single-target' })
  singleTarget?: boolean;

  /** Optional label to use when in single-target mode. */
  @property({ attribute: 'single-target-label' })
  singleTargetLabel?: string;

  /** Optional icon to use when in single-target mode. Default is more_horiz. */
  @property({ attribute: 'single-target-icon' })
  singleTargetIcon?: string;

  /**
   * Specifies the visual size of the button.  Defaults to "base"
   * @enum {value} base This is the default button size.
   * @enum {value} small This is a smaller button.
   */
  @property()
  size = 'base';

  /** If present, the button is not clickable. */
  @property()
  disabled?: boolean;

  /**
   * Specifies the type of button to be displayed.  Defaults to primary
   * @enum {value} primary This is the default button type.
   * @enum {value} secondary Some other kind button.
   */
  @property()
  variant = 'primary';

  /** If present, the button will adjust its appearance on smaller screens. */
  @property()
  responsive?: boolean;

  /** Fires when a button is clicked with `action` set to the `action` property of the button. */
  @event('action')
  _action: EventEmitter<{ action: string }>;

  /** Fires whenever the dropdown options expand open. */
  @event('expand')
  _expand: EventEmitter;

  /** Fires whenever the dropdown options are hidden. */
  @event('collapse')
  _collapse: EventEmitter;

  constructor() {
    super();
    this.addEventListener('focusout', e => {
      const newFocus = e.relatedTarget;

      if (!this.disabled && !this.contains(newFocus) && this.expanded) {
        this.toggleExpanded();
      }
    });
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('disabled')) {
      if (this.disabled) {
        this.expanded = false;
      }
    }

    if (changedProps.has('expanded')) {
      this.setOrUnsetOptionsContainerBottomOffset();
    }
  }

  render() {
    const firstButtonIndex = this.singleTarget ? 0 : 1;

    const options = Array.isArray(this.options)
      ? this.options.slice(firstButtonIndex).map((option, i) => {
          const action = option.action || '';
          const label = option.label || '';
          return html`
            <button
              part="dropdown-button-button-${i + 1}"
              data-action=${action}
              role="menuitem"
              class="option"
              ?aria-disabled=${option.disabled}
              @click=${this._listItemTrapDisabledClickHandler}
            >
              ${label}
            </button>
          `;
        })
      : nothing;

    const firstButton = this.singleTarget
      ? nothing
      : html`<button
          class="button"
          part="dropdown-button-button-0"
          type="button"
          data-action=${this.getFirstAction()}
          ?disabled=${this.disabled}
          @click=${this._firstOptionClickHandler}
        >
          ${this.getFirstLabel()}
        </button>`;

    return html`
      <div class="button-group-header">
        ${firstButton}
        <button
          part="dropdown-button-toggle-button"
          type="button"
          class="indicator"
          ?disabled=${this.disabled}
          @click=${this._toggleClickHandler}
          @keydown=${this._toggleKeydownHandler}
          aria-haspopup="true"
          aria-expanded=${this.expanded}
          aria-label=${this.expanded
            ? getString('kat-dropdown-button-close')
            : getString('kat-dropdown-button-open')}
        >
          ${this.getToggleLabel()}<kat-icon
            name="${this.getToggleIconName()}"
            size="small"
          ></kat-icon>
        </button>
      </div>
      <div
        part="${super.getPartMask(this.expanded)}"
        @click=${this.handleMaskClick}
      >
        <div
          class="options"
          part="${super.getPartWrapper(
            this.expanded,
            'dropdown-button-options'
          )}"
        >
          <div part="${super.getPartContainer(this.expanded)}">
            <div
              class="options-inner-container"
              part="${super.getPartContent(this.expanded)}"
              role="menu"
              @click=${this._listItemClickHandler}
              @keydown=${this._listKeydownHandler}
            >
              ${options}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  getFirstLabel() {
    return this.options && this.options.length > 0
      ? this.options[0].label || ''
      : '-- missing option';
  }

  getFirstAction() {
    return this.options && this.options.length > 0
      ? this.options[0].action || ''
      : '';
  }

  getToggleLabel() {
    if (this.singleTarget && this.singleTargetLabel) {
      return this.singleTargetLabel;
    }

    return nothing;
  }

  getToggleIconName() {
    if (this.singleTarget) {
      return this.singleTargetIcon || 'more_horiz';
    }

    return this.expanded && this.hasAttribute('drop-up')
      ? 'chevron-up'
      : 'chevron-down';
  }

  _toggleClickHandler() {
    if (!this.disabled) {
      this.toggleExpanded();
    }
  }

  _toggleKeydownHandler(e) {
    if (e.which === Keys.Escape) {
      if (this.expanded) {
        this.toggleExpanded();
      }
    } else if (e.which === Keys.ArrowDown) {
      if (!this.expanded) {
        this.toggleExpanded();
      }

      this._moveMenuOption(1, null);
    }
  }

  _firstOptionClickHandler(evt) {
    if (!this.disabled) {
      this.triggerAction(evt.target);
    }
  }

  _listKeydownHandler(e) {
    if (e.which === Keys.ArrowDown) {
      this._moveMenuOption(1, e.target);
    } else if (e.which === Keys.ArrowUp) {
      this._moveMenuOption(-1, e.target);
    } else if (e.which === Keys.Escape) {
      if (this.expanded) {
        this.toggleExpanded();
      }
    }
  }

  // Only purpose is to stop a disabled option from closing the dropdown
  _listItemTrapDisabledClickHandler(e) {
    if (e.target.hasAttribute('aria-disabled')) {
      e.stopPropagation();
    }
  }

  _listItemClickHandler(e) {
    if (!this.disabled) {
      this.triggerAction(e.target);
    }
  }

  _moveMenuOption(increment, optionsElement) {
    const items = this.shadowRoot.querySelectorAll('.options button');

    if (items.length === 0) {
      return;
    }

    let index = Array.from(items).indexOf(optionsElement);

    if (increment < 0 && index > 0) {
      // Up
      index -= 1;
    }

    if (increment > 0 && index < items.length - 1) {
      // Down
      index += 1;
    }

    items[index].focus();
  }

  setOrUnsetOptionsContainerBottomOffset() {
    const optionContainer = this._shadow('.options') as HTMLElement;

    if (!optionContainer) {
      return;
    }

    const optionInnerContainer = optionContainer.querySelector(
      '.options-inner-container'
    );

    // Temporarily reveal container so that height can be observed in fitHandler
    optionContainer.style.display = 'table';
    fitHandler(this, optionInnerContainer, true);
    optionContainer.style.removeProperty('display');

    if (this.hasAttribute('drop-up')) {
      const button = this._shadow('.button-group-header button') as HTMLElement;
      optionContainer.setAttribute('style', `bottom: ${button.offsetHeight}px`);
    } else {
      optionContainer.removeAttribute('style');
    }
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
    this.expanded ? this._expand.emit() : this._collapse.emit();
  }

  triggerAction(element) {
    const button = element.closest('button');
    const action = button.dataset.action;
    this._action.emit({ action });

    if (this.expanded) {
      this.toggleExpanded();
      // Refocus the toggle button
      this._shadow('.indicator').focus();
    }
  }

  // Collapses dropdown when mobile mask is clicked
  handleMaskClick(e: MouseEvent) {
    const optionsWrapper = this.shadowRoot.querySelector('.options');
    // prevents toggling twice when clicking on an option
    if (!e.composedPath().includes(optionsWrapper)) {
      this.toggleExpanded();
    }
  }
}
