import React from 'react';
import cx from 'classnames';
import { TrainingJobCell } from 'src/components/table-cells/TrainingJobCell';
import { KatLabel } from '@amzn/katal-react';
import { MetricNameCell } from 'src/components/table-cells/MetricNameCell/MetricNameCell';
import { MetricValueCell } from 'src/components/table-cells/MetricValueCell/MetricValueCell';
import { DataTable } from 'src/components/DataTable/DataTable';
import moment from 'moment/moment';

export const JobDetailPane = ({
  job,
  noSelectedJobText,
}: {
  job:
    | {
        id: string;
        trainingJobArn?: string;
        trainingJobName?: string;
        createdDate?: string;
        metrics: Metric[];
      }
    | undefined;
  noSelectedJobText: string;
}) => (
  <div
    className={cx('job-detail-pane', {
      'job-detail-pane--empty': !job,
    })}
  >
    {!job && <p>{noSelectedJobText}</p>}
    {job && (
      <div className="job-detail-pane__layout">
        <div className="job-detail-pane__info-cell">
          <KatLabel>Training Job</KatLabel>
          {job.trainingJobArn && job.trainingJobName ? (
            <div>
              <TrainingJobCell
                trainingJobArn={job.trainingJobArn}
                trainingJobName={job.trainingJobName}
                id={job.id}
              />
            </div>
          ) : (
            <p>{job.id}</p>
          )}
        </div>
        <div className="job-detail-pane__info-cell">
          {job.createdDate && (
            <>
              <KatLabel>Created Date</KatLabel>
              <p
                className="job-detail-pane__create-date"
                title={moment.unix(parseInt(job.createdDate)).toString()}
              >
                {moment.unix(parseInt(job.createdDate)).fromNow()}
              </p>
            </>
          )}
        </div>

        {job.metrics && (
          <div className="job-detail-pane__info-cell">
            <DataTable
              heading="Metrics"
              options={{
                className: 'job-detail-pane__table',
              }}
              columns={[
                {
                  Header: 'Name',
                  accessor: 'MetricName',
                  Cell: MetricNameCell,
                },
                {
                  Header: 'Value',
                  accessor: 'Value',
                  Cell: MetricValueCell,
                },
              ]}
              data={job.metrics}
            />
          </div>
        )}
      </div>
    )}
  </div>
);
