import { urlList } from 'src/context/url';

export interface DeploymentJobsAPIResponse {
  id: string;
  modelArtifactId: string;
  status: string;
  stage?: 'BETA' | 'PROD';
  sageMakerModelArn?: string;
  sageMakerEndpointArn?: string;
  primaryContainerImage?: string;
  sageMakerModelDataS3Path?: string;
  sageMakerModelDataS3ETag?: string;
  rolledBackOperatorFlowJobId?: string;
  operatorFlowJobId?: string;
  operatorName?: string;
  upstreamJobRelationships?: any;
  trainingJobId?: string;
  createdDate?: number;
  createdBy?: string;
  updatedData?: number;
  updatedBy?: string;
}

export const fetchDeploymentJob = (
  deploymentJobId: string,
  userToken: string,
): Promise<DeploymentJobsAPIResponse> =>
  new Promise((resolve, reject) => {
    fetch(`${urlList.deploymentJobs}/${deploymentJobId}`, {
      headers: {
        Authorization: userToken,
        'Content-type': 'application/json',
      },
    })
      .then(async (res) => {
        if (!res.ok) {
          await res.json().then((res) => {
            throw new Error(res?.message);
          });
        }
        return res;
      })
      .then((res) => res.json())
      .then(resolve)
      .catch(reject);
  });
