import React, { useState } from 'react';
import { ModelArtifactTable } from 'src/components/ModelArtifactTable/ModelArtifactTable';
import { ProfileDetails } from 'src/components/ProfileDetails/ProfileDetails';
import { KatDropdownButton, KatButton } from '@amzn/katal-react';
import { TriggerWorkflowModal } from 'src/components/modals/TriggerWorkflowModal/TriggerWorkflowModal';
import { useParams } from 'react-router-dom';
import { ModelVersionModal } from 'src/components/modals/ModelVersionModal';
import { getModelVersion } from 'src/hooks/useModelVersions';
import { Placeholder } from 'src/components/Placeholder/Placeholder';
import { ModelVersionEventsTable } from 'src/components/ModelVersionEventsTable';

export function ModelVersionDetails() {
  const [modelVersionModalVisible, setModelVersionModalVisible] =
    useState<boolean>(false);
  const [modelVersionModalMode, setModelVersionModalMode] = useState<
    'New' | 'Edit' | 'Delete'
  >('New');
  const [modelVersionModalValues, setModelVersionModalValues] =
    useState<any>(null);

  const showModelVersionModal = (
    mode: 'New' | 'Edit' | 'Delete',
    values: any,
  ) => {
    setModelVersionModalValues(values);
    setModelVersionModalMode(mode);
    setModelVersionModalVisible(true);
  };

  const { accountNo, applicationId, modelVersionId } = useParams();

  const { data, isLoading } = getModelVersion({
    modelVersionId: modelVersionId,
  });

  const [triggerWorkflowModalVisible, setTriggerWorkflowModalVisible] =
    useState<boolean>(false);

  const actionHandler = (event: KatDropdownButton.ActionEvent) => {
    switch (event.detail.action) {
      case 'edit_model_version':
        if (!data) return;
        showModelVersionModal('Edit', {
          id: modelVersionId,
          applicationId,
          name: data.name,
          description: data.description,
          version: data.version,
        });
        break;
      case 'delete_model_version':
        showModelVersionModal('Delete', { id: modelVersionId, applicationId });
        break;
    }
  };

  return (
    <div className="dashboard">
      <div className="model-version-details__header">
        <h2>Model Version Details</h2>
        <div className="model-version-details__button-group">
          <KatButton
            label="Trigger Workflow"
            variant="tertiary"
            onClick={() => {
              setTriggerWorkflowModalVisible(true);
            }}
          />
          <KatDropdownButton
            size="base"
            variant="secondary"
            options='[{"label": "Edit model version", "action": "edit_model_version"},{"label": "Delete model version", "action": "delete_model_version"}]'
            onAction={actionHandler}
          />
        </div>
      </div>
      <div className="details-display">
        <Placeholder
          shapes={
            <>
              <rect y="0" width="300" height="168" />
            </>
          }
          ready={!isLoading}
        >
          <dl>
            <dt>Name</dt>
            <dd>{data?.name}</dd>

            <dt>Id</dt>
            <dd>{data?.id}</dd>

            <dt>Description</dt>
            <dd>{data?.description}</dd>

            <dt>Version</dt>
            <dd>{data?.version}</dd>
          </dl>
        </Placeholder>
      </div>

      <ModelVersionEventsTable />

      <ModelArtifactTable />

      <div className="profile-details">
        <Placeholder
          shapes={
            <>
              <rect y="0" width="90" height="25" />
              <rect x="30%" y="0" width="90" height="25" />
              <rect x="60%" y="0" width="90" height="25" />
              <rect x="80%" y="0" width="90" height="25" />
            </>
          }
          ready={!isLoading}
        >
          <ProfileDetails
            createdBy={data?.createdBy || ''}
            updatedBy={data?.updatedBy || ''}
            createdDate={data?.createdDate || ''}
            updatedDate={data?.updatedDate || ''}
          />
        </Placeholder>
      </div>
      {triggerWorkflowModalVisible && (
        <TriggerWorkflowModal
          onClose={() => {
            setTriggerWorkflowModalVisible(false);
          }}
        />
      )}
      {applicationId && (
        <ModelVersionModal
          visible={modelVersionModalVisible}
          modalMode={modelVersionModalMode}
          values={modelVersionModalValues}
          onClose={() => {
            setModelVersionModalVisible(false);
          }}
          applicationId={applicationId}
          redirectLocation={
            modelVersionModalMode === 'Delete'
              ? `/accounts/${accountNo}/applications/${applicationId}`
              : undefined
          }
        />
      )}
    </div>
  );
}
