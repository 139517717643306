import React from 'react';
import moment from 'moment';

export const DateCell = (cellInfo: any) => {
  const timestamp: number = parseInt(
    // @ts-ignore
    cellInfo.row.original[cellInfo.column.accessorString],
  );

  if (isNaN(timestamp)) return <div>Date not available.</div>;

  const actualDate = moment.unix(timestamp);

  return (
    <>
      {
        // @ts-ignore
        cellInfo?.column?.showRelativeDate ? (
          <div>{actualDate.fromNow()}</div>
        ) : (
          <div>{actualDate.toString()}</div>
        )
      }
    </>
  );
};
