import React from 'react';
import uniqueId from 'lodash/uniqueId';

export const VariableListCell = ({
  variables,
}: {
  variables?: Record<string, string>;
}) => (
  <div className="variable-list">
    <table>
      <tbody>
        {variables &&
          Object.keys(variables).map((key) => (
            <tr className="variable-list__item" key={uniqueId()}>
              <td>
                <strong>{key}</strong>
              </td>
              <td>
                <span>{variables[key]}</span>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);
