import React, { useState } from 'react';
import cx from 'classnames';
import { KatButton, KatIcon } from '@amzn/katal-react';
import CopyToClipboard from 'react-copy-to-clipboard';

export const CopyToClipboardButton = ({
  icon,
  data,
  label = 'Copy to Clipboard',
  variant = 'secondary',
}: {
  icon?: KatIcon.Name;
  data: string;
  label?: string;
  variant?: KatButton.Variant;
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

  return (
    <span className={cx('copy-button', { 'copy-button--icon': icon })}>
      {copiedToClipboard && (
        <div className="copy-to-clipboard-notice">Copied</div>
      )}
      <CopyToClipboard
        text={data}
        onCopy={() => {
          setCopiedToClipboard(true);
          setTimeout(() => {
            setCopiedToClipboard(false);
          }, 1000);
        }}
      >
        <KatButton disabled={!data} label={icon ? '' : label} variant={variant}>
          {icon && (
            <>
              <KatIcon name={icon} size="tiny" />
              <span className="copy-to-clipboard__icon-label">Copy</span>
            </>
          )}
        </KatButton>
      </CopyToClipboard>
    </span>
  );
};
