import React, { ReactElement, useContext, useState } from 'react';
import { MonitoringSchedule } from 'src/context/apiState';
import { MonitoringScheduleIdColumn } from 'src/components/MonitoringScheduleTable/columns/MonitoringScheduleIdColumn/MonitoringScheduleIdColumn';
import { RegionColumn } from 'src/components/MonitoringScheduleTable/columns/RegionColumn/RegionColumn';
import { ApiContext } from 'src/context/apiContext';
import { DataTable } from 'src/components/DataTable/DataTable';
import { DateColumn } from 'src/components/DateColumn/DateColumn';

export const MonitoringScheduleTable = ({
  loading,
  data,
  error,
}: {
  loading: boolean;
  data: MonitoringSchedule[] | null;
  error: Error | null;
}): ReactElement => {
  const { state } = useContext(ApiContext);
  const [showCreatedRelativeDate, setShowCreatedRelativeDate] =
    useState<boolean>(true);
  const [showUpdatedRelativeDate, setShowUpdatedRelativeDate] =
    useState<boolean>(true);
  const columns = [
    MonitoringScheduleIdColumn(
      state.search.curAccountNo,
      state.search.curApplicationId,
      state.search.curModelVersionId,
      state.search.curModelArtifactId,
    ),
    {
      Header: 'Name',
      accessor: 'monitoringScheduleName',
      sortable: true,
    },
    RegionColumn(),
    DateColumn({
      header: 'Created Date',
      accessor: 'createdDate',
      id: 'created-date',
      showRelativeDate: showCreatedRelativeDate,
      setShowRelativeDate: setShowCreatedRelativeDate,
    }),
    DateColumn({
      header: 'Updated Date',
      accessor: 'updatedDate',
      id: 'updated-date',
      showRelativeDate: showUpdatedRelativeDate,
      setShowRelativeDate: setShowUpdatedRelativeDate,
    }),
  ];

  return (
    <>
      {!loading && (error || !data) && (
        <span className="hints">
          Unable to get monitoring schedules: {error ? error?.message : ''}
        </span>
      )}
      {!loading && data && (
        <DataTable
          heading="Monitoring Schedule"
          options={{
            noDataText: 'No monitoring schedule found.',
            defaultPageSize: 10,
            defaultSort: {
              id: 'updated-date',
              desc: true,
            },
          }}
          data={data}
          columns={columns}
        />
      )}
    </>
  );
};
