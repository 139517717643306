import React, { useContext, useState } from 'react';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';
import { OperatorFlowTable } from 'src/components/OperatorFlowTable/OperatorFlowTable';
import { DateRangePicker } from 'src/components/DateRangePicker';
import { ApiContext } from 'src/context/apiContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { BreadcrumbNavigation } from 'src/components/BreadcrumbNavigation/BreadcrumbNavigation';
import {
  convertDateToEpochTimeInSeconds,
  getDateRangeFromToday,
} from 'src/utils/timeUtils/timeUtils';
import { useQuery } from '@tanstack/react-query';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest/mlPigeonAPIRequest';
import { urlList } from 'src/context/url';

export function OperatorFlowsPage() {
  const { state } = useContext(ApiContext);
  const { modelArtifactId } = useParams<Record<string, string>>();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');

  const currentApplicationId = state.search.curApplicationId;
  const applicationDetails = state.applications.data.find(
    (app) => app['id'] === currentApplicationId,
  );
  const currentModelVersionId = state.search.curModelVersionId;
  const modelVersionDetails = state.modelVersions.data.find(
    (mv) => mv['id'] === currentModelVersionId,
  );

  const [dateRange, setDateRange] = useState<DateRange>(
    startDate && endDate
      ? { startDate: new Date(startDate), endDate: new Date(endDate) }
      : getDateRangeFromToday(30),
  );

  const { data, isLoading, error } = useQuery<OperatorFlowJob[], Error>({
    queryKey: ['operatorFlowJobs', modelArtifactId, dateRange],
    queryFn: () => {
      dateRange.startDate.setHours(0, 0, 0, 0);
      dateRange.endDate.setHours(23, 59, 59, 999);
      return apiGetRequest({
        endpoint: urlList.operatorFlowJobs,
        params: {
          modelArtifactId: modelArtifactId || '',
          startTime: convertDateToEpochTimeInSeconds(
            dateRange.startDate,
          ).toString(),
          endTime: convertDateToEpochTimeInSeconds(
            dateRange.endDate,
          ).toString(),
        },
      });
    },
  });

  return (
    <ContentLayout
      heading={
        <>
          <BreadcrumbNavigation
            links={[
              {
                display: `Account (${state.search.curAccountNo})`,
                href: `/accounts/${state.search.curAccountNo}`,
              },
              {
                display: applicationDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}`,
              },
              {
                display: modelVersionDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}`,
              },
              {
                display: `Operator Flows (${modelArtifactId})`,
              },
            ]}
          />
          <h1>Operator Flows</h1>
        </>
      }
    >
      <DateRangePicker
        initDateRange={dateRange}
        changeDateRange={setDateRange}
        setSearchParams={setSearchParams}
      />
      <OperatorFlowTable data={data} loading={isLoading} />
      {error && <p className="error-text">{error.message}</p>}
    </ContentLayout>
  );
}
