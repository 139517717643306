import React from 'react';

import { LinkGroupCell } from 'src/components/table-cells/LinkGroupCell/LinkGroupCell';
import { ApiState } from 'src/context/apiState';

export const OperatorFlowIdColumn = (
  state: ApiState,
  dispatch: React.Dispatch<any>,
  width = 400,
) => {
  const OperatorFlowIdColumnCell = (cellInfo: any) => (
    <LinkGroupCell
      links={[
        {
          text: cellInfo.value,
          href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}/modelArtifacts/${state.search.curModelArtifactId}/operatorFlowJobs/${cellInfo.value}/visualization`,
        },
      ]}
      onClick={() => {
        dispatch({
          type: 'setCurrentOperatorFlowJobId',
          payload: cellInfo.value,
        });
      }}
    />
  );
  return {
    Header: 'Operator Flow Job ID',
    accessor: 'id',
    Cell: OperatorFlowIdColumnCell,
    width,
    sortable: true,
  };
};
