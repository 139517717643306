import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'src/context/apiContext';
import { Account } from 'src/hooks/useGetAccounts/useGetAccounts';
import { SubNavMenu } from 'src/components/SubNavMenu/SubNavMenu';

export const AccountHeader = () => {
  const { state } = useContext(ApiContext);

  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  useEffect(() => {
    const localAccountString = window.localStorage.getItem('selectedAccount');
    const localAccount: Account = localAccountString
      ? JSON.parse(localAccountString)
      : null;

    setSelectedAccount(localAccount);
  }, [state.search.curAccountNo]);

  return (
    <div className="account-header">
      <div>
        <h1 className="account-header__name">
          {selectedAccount?.name || state.search.curAccountNo}
        </h1>
        <div className="account-header__details">
          {selectedAccount?.id && (
            <span className="account-header__id">{selectedAccount.id}</span>
          )}
          {selectedAccount?.id && selectedAccount?.type && <span>|</span>}
          {selectedAccount?.type && (
            <span className="account-header__type">
              {selectedAccount.type.toLowerCase()}
            </span>
          )}
        </div>
      </div>
      <SubNavMenu
        links={[
          {
            display: 'Topic Subscriptions',
            href: `/accounts/${state.search.curAccountNo}/topics`,
          },
          {
            display: 'Reserve a Model Registry Name',
            href: `/accounts/${state.search.curAccountNo}/reserveRegistryName`,
          },
          { display: 'Generate a User Script', href: '/userscript' },
          {
            display: 'Account Settings',
            href: `/accounts/${state.search.curAccountNo}/accountSettings`,
          },
          {
            display: 'Account Metrics',
            href: `/accounts/${state.search.curAccountNo}/accountMetrics`,
          },
        ]}
      />
    </div>
  );
};
