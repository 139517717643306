import React, { useContext, useState } from 'react';
import { ApiContext } from 'src/context/apiContext';
import { KatAlert, KatButton, KatLabel, KatList } from '@amzn/katal-react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DateRangePicker } from 'src/components/DateRangePicker';
import { useDataQualityMetrics } from 'src/hooks/useDataQualityMetrics/useDataQualityMetrics';
import { ViolationsTable } from 'src/components/ViolationsTable/ViolationsTable';
import { JobNameCell } from 'src/components/table-cells/JobNameCell/JobNameCell';
import { DateColumn } from 'src/components/DateColumn/DateColumn';
import { DataTable } from '../DataTable/DataTable';
import { MetricValueCell } from 'src/components/table-cells/MetricValueCell/MetricValueCell';
import { initialMetricsPublisher } from 'src/utils/metrics/metrics';
import { NumericalStatisticsTables } from 'src/components/DataQualityMetricsList/tables/NumericalStatisticsTables';
import { CategoricalStatisticsTables } from 'src/components/DataQualityMetricsList/tables/CategoricalStatisticsTables';
import { getDateRangeFromToday } from 'src/utils/timeUtils/timeUtils';

export function DataQualityMetricsList() {
  const { state } = useContext(ApiContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { trainingJobId, monitoringScheduleId } =
    useParams<Record<string, string>>();
  const params = new URLSearchParams(searchParams);
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');
  const initDateRange =
    startDate && endDate
      ? { startDate: new Date(startDate), endDate: new Date(endDate) }
      : getDateRangeFromToday(7);
  const [showNumericalTable, setShowNumericalTable] = useState<boolean>(false);
  const [showCategoricalTable, setShowCategoricalTable] =
    useState<boolean>(false);
  const actionMetricsPublisher = initialMetricsPublisher
    ? initialMetricsPublisher.newChildActionPublisherForMethod(
        'DataQualityMetricsList',
      )
    : null;

  /*
    If viewing one specific training job, should set the trainingjobid from params and the modelArtifactid should be ''.
    If viewing all data quality metrics, (all the jobs), should set the modelArtifactId from state and the
    trainingjobid should be null, since it's not in url params.
   */
  let modelArtifactId = '';
  if (!trainingJobId && !monitoringScheduleId) {
    modelArtifactId = state.search.curModelArtifactId;
  }

  const [metricsLoading, metricsError, metricsData, setTimeFilter] =
    useDataQualityMetrics(
      modelArtifactId,
      trainingJobId || monitoringScheduleId,
      initDateRange,
    );

  const jobNameColumn = {
    Header: 'Job Name',
    accessor: 'jobName',
    sortable: true,
    Cell: JobNameCell,
    width: 300,
  };

  const errorMessages = metricsData?.reduce<
    { jobId: string; jobType: string; errorMessages: string[] }[]
  >((prev, curr) => {
    if (curr.errorMessages) {
      prev.push({
        jobId: curr.jobId,
        jobType: curr.jobType,
        errorMessages: curr.errorMessages || [],
      });
    }
    return prev;
  }, []);

  const [showRelativeDate, setShowRelativeDate] = useState<boolean>(false);
  const createdDateColumn = DateColumn({
    header: 'Created Date',
    accessor: 'createdDate',
    id: 'created-date',
    showRelativeDate,
    setShowRelativeDate,
  });

  // loop through all the jobs and build a collection of all the feature names
  const getFeatures = (metricsJobs: ModelMetric[]) => {
    if (!metricsJobs) return;
    const numericalFeatures = new Set<string>();
    const categoricalFeatures = new Set<string>();

    metricsJobs?.forEach((job) => {
      const metricsJson: DataQualityMetrics = job.metrics
        ? JSON.parse(job.metrics)
        : [];
      metricsJson.features?.forEach((feature) => {
        if (feature.string_statistics) {
          categoricalFeatures.add(feature.name);
        } else if (feature.numerical_statistics) {
          numericalFeatures.add(feature.name);
        }
      });
    });
    return [numericalFeatures, categoricalFeatures];
  };

  // categorical=true when used for categorical/string data, false when used for numerical data
  function addTabToColumns(
    dataQualityMetrics: any,
    columns: any,
    categorical: boolean,
  ) {
    const features = getFeatures(dataQualityMetrics);
    const numericalFeatures = features?.[0] || [];
    const categoricalFeatures = features?.[1] || [];

    if (!categorical) {
      numericalFeatures.forEach((featureName: string) => {
        let prefix: keyof typeof columns;
        for (prefix in columns) {
          columns[prefix].push({
            Header: featureName,
            accessor: `${prefix}.${featureName}`,
            sortable: true,
            Cell: MetricValueCell,
          });
        }
      });
    } else {
      categoricalFeatures.forEach((featureName: string) => {
        let prefix: keyof typeof columns;
        for (prefix in columns) {
          columns[prefix].push({
            Header: featureName,
            accessor: `${prefix}.${featureName}`,
            sortable: true,
            Cell: MetricValueCell,
          });
        }
      });
    }
  }

  const tabToColumns = {
    min: [jobNameColumn, createdDateColumn],
    max: [jobNameColumn, createdDateColumn],
    mean: [jobNameColumn, createdDateColumn],
    sum: [jobNameColumn, createdDateColumn],
    stdDev: [jobNameColumn, createdDateColumn],
    missingPercentage: [jobNameColumn, createdDateColumn],
  };
  addTabToColumns(metricsData, tabToColumns, false);

  // Categorical metrics
  const tabToColumnsCategorical: Record<string, any[]> = {
    missingPercentage: [jobNameColumn, createdDateColumn],
    valueCount: [jobNameColumn, createdDateColumn],
  };
  addTabToColumns(metricsData, tabToColumnsCategorical, true);

  return (
    <div className="dashboard">
      <div className="content-layout__heading">
        {trainingJobId && (
          <div>
            <KatLabel>Training Job</KatLabel>
            <span>{trainingJobId}</span>
          </div>
        )}
        {monitoringScheduleId && (
          <div>
            <KatLabel>Monitoring Schedule</KatLabel>
            <span>{monitoringScheduleId}</span>
          </div>
        )}
      </div>
      <div className="metrics-filter-background">
        <DateRangePicker
          initDateRange={initDateRange}
          changeDateRange={setTimeFilter}
          setSearchParams={setSearchParams}
          label="Job Run"
        />
        <p>{metricsData?.length || '0'} job(s) found.</p>
        {metricsError && (
          <div className="metrics-filter__notice">
            <span className="error-text">
              Unable to fetch data: {metricsError.message}
            </span>
            <p>
              If the data fetch has failed, its possible that the amount of data
              returned exceeds the Lambda payload limit. In that case, you can
              try to reduce the time range, using the controls above.
            </p>
          </div>
        )}
        {errorMessages && errorMessages.length > 0 && (
          <>
            <KatAlert variant="danger" header="Data Quality Monitoring Error">
              <p>
                One or more of the jobs being monitored had an error saving the
                metrics data.
              </p>
              <DataTable
                data={errorMessages}
                columns={[
                  {
                    accessor: 'jobId',
                    Header: 'Job Id',
                    width: 100,
                  },
                  {
                    accessor: 'jobType',
                    Header: 'Job Type',
                    width: 75,
                  },
                  {
                    accessor: 'errorMessages',
                    Header: 'Error Messages',
                    Cell: (info: any) => {
                      return (
                        <KatList variant="bullet">
                          {info.value?.map((message: string, index: number) => {
                            return <li key={index}>{message}</li>;
                          })}
                        </KatList>
                      );
                    },
                  },
                ]}
                heading="Error Messages by Job"
              />
            </KatAlert>
          </>
        )}
      </div>

      <ViolationsTable
        loading={metricsLoading}
        violationData={metricsData?.reduce<JobMetricViolation[]>(
          (prev, curr) => {
            if (curr.constraintViolations) {
              const violations: MetricViolation[] = JSON.parse(
                curr.constraintViolations,
              ).violations;
              violations?.forEach((violation) => {
                prev.push({
                  jobId: curr.jobId,
                  createdDate: curr.createdDate,
                  feature_name: violation.feature_name,
                  constraint_check_type: violation.constraint_check_type,
                  description: violation.description,
                });
              });
            }

            return prev;
          },
          [],
        )}
      />

      <div className="list">
        <h3>Numerical Data Quality Metrics</h3>
      </div>
      {!showNumericalTable ? (
        <KatButton
          loading={metricsLoading}
          onClick={() => {
            setShowNumericalTable(true);
            actionMetricsPublisher?.publishCounterMonitor(
              'showNumericalTable',
              1,
            );
          }}
        >
          Show Numerical Statistics
        </KatButton>
      ) : (
        <NumericalStatisticsTables
          metricsData={metricsData}
          metricsLoading={metricsLoading}
          tabToColumns={tabToColumns}
        />
      )}
      <div className="list">
        <h3>Categorical Data Quality Metrics</h3>
      </div>
      {!showCategoricalTable ? (
        <KatButton
          loading={metricsLoading}
          onClick={() => {
            setShowCategoricalTable(true);
            actionMetricsPublisher?.publishCounterMonitor(
              'showCategoricalTable',
              1,
            );
          }}
        >
          Show Categorical Statistics
        </KatButton>
      ) : (
        <CategoricalStatisticsTables
          metricsLoading={metricsLoading}
          metricsData={metricsData}
          tabToColumns={tabToColumnsCategorical}
        />
      )}
    </div>
  );
}
