import { classMap } from 'lit-html/directives/class-map';
import { html, property } from 'lit-element';
import {
  Keys,
  KatLitElement,
  register,
  coerceToNumber,
  event,
  EventEmitter,
} from '../../shared/base';
import { ifNotNull } from '../../utils/directives';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './pagination.lit.scss';
import getString from './strings';

// Max number of pages shown, otherwise it will ellipsify.
const MAX_PAGER_SEGMENTS = 7;
// Max distance current page is from end where ellipsis won't show
const MAX_DIFF_FROM_ENDPOINTS = 4;

/**
 * @component {kat-pagination} KatalPagination Pagination breaks large amounts of data into separate pages and allows the user to navigate between those pages.
 * @example Default {"total-items": "1000", "items-per-page": "10", "page":"4"}
 * @example OpenEnded {"open-ended": "true", "total-items": "10", "items-per-page": "10", "page": "1", "script": "
 *   const openEnded = document.querySelector(\"#preview kat-pagination\");
 *   openEnded.addEventListener('change', event => {
 *     if (event.detail.page > openEnded.totalPages) {
 *       openEnded.totalItems += 10;
 *     }
 *   });
 * "}
 * @example Footer {"variant": "footer", "total-items": "97", "items-per-page": "10", "page": "1"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-pagination')
export class KatPagination extends KatLitElement {
  private __totalItems = 1;
  private __itemsPerPage = 10;
  private __page = 1;

  /** Deprecated - use `change` instead. Fired when the selected page changes via user interaction. */
  @event('page-changed')
  private _pagechanged: EventEmitter<{ page: number }>;

  /** Fires when the selected page changes via user interaction. */
  @event('change')
  private _change: EventEmitter<{ page: number }>;

  /** Fires when the items per page changes via user interaction. */
  @event('items-per-page-change')
  private _itemsPerPageChange: EventEmitter<{ value: number }>;

  /**
   * The total number of items available for pagination.
   * @required
   */
  @property({ attribute: 'total-items' })
  get totalItems(): number {
    return this.__totalItems;
  }

  set totalItems(val: number) {
    const oldVal = this.totalItems;
    this.__totalItems = coerceToNumber(val);
    this.requestUpdate('totalItems', oldVal);
  }

  /**
   * The number of items that will be displayed on a single page.
   * Used to calculate total pages. Defaults to 10.
   */
  @property({ attribute: 'items-per-page' })
  get itemsPerPage(): number {
    return this.__itemsPerPage;
  }

  set itemsPerPage(val: number) {
    const oldVal = this.__itemsPerPage;
    this.__itemsPerPage = coerceToNumber(val);
    this.requestUpdate('itemsPerPage', oldVal);
  }

  /**
   * Defines the options available to change itemsPerPage to.
   * Only used when variant is 'footer'. Defaults to [10, 25, 50].
   */
  @property({ attribute: 'items-per-page-options' })
  itemsPerPageOptions?: number[] = [10, 25, 50];

  /**
   * The currently selected page.
   * @required
   */
  @property()
  get page(): number {
    return this.__page;
  }

  set page(val: number) {
    const oldVal = this.__page;
    this.__page = coerceToNumber(val);
    this.requestUpdate('page', oldVal);
  }

  /**
   * When set, adds an ellipsis after the last page. Used for data sets with an indeterminate number of pages.
   */
  @property({ attribute: 'open-ended' })
  openEnded?: boolean = false;

  /**
   * The display mode of the pagination. Defaults to 'base'.
   * @enum {value} base A single pagination nav is displayed
   * @enum {value} footer a\A "current items" info span and an "items per page" selector are displayed as well.
   */
  @property()
  variant?: 'base' | 'footer' = 'base';

  /**
   * Currently active locale, used to localize labels.
   */
  @property()
  locale?: string;

  static get styles() {
    return [baseStyles, styles];
  }

  /**
   * The total number of pages, calculated from `totalItems` and `itemsPerPage`. Readonly.
   */
  get totalPages() {
    return Math.ceil(this.totalItems / (this.itemsPerPage || 1));
  }

  handleKeydown(e) {
    if (e.keyCode === Keys.Enter || e.keyCode === Keys.Space) {
      e.preventDefault();
      e.target.click();
      return;
    }

    const isLeft = e.keyCode === Keys.ArrowLeft;
    const isRight = e.keyCode === Keys.ArrowRight;

    if (isLeft || isRight) {
      e.preventDefault();

      let n = e.target;

      const next = () => {
        if (isLeft) {
          return (n = n.previousElementSibling);
        }
        return (n = n.nextElementSibling);
      };

      while (next() && n.classList.contains('ellipsis')) {
        // n will be next non-ellipsis or null
      }

      if (n) {
        n.focus();
      }
    }
  }

  handleNavClick(e) {
    if (!e.currentTarget.previousElementSibling) {
      if (this.page > 1) {
        this.page--;
        this._pageChanged();
      }
    } else {
      if (this.page < this.totalPages || this.openEnded) {
        this.page++;
        this._pageChanged();
      }
    }
  }

  handlePageClick(e) {
    const pageClicked = parseInt(e.currentTarget.dataset.page);
    if (pageClicked !== this.page) {
      this.page = pageClicked;
      this._pageChanged();
    }
  }

  _pageChanged() {
    // @deprecated
    this._pagechanged.emit({ page: this.page });
    this._change.emit({ page: this.page });
  }

  _generatePages() {
    const currentPage = this.page;
    const pages = [];

    const generatePages = (startPageInclusive, endPageExclusive) => {
      for (let i = startPageInclusive; i <= endPageExclusive; ++i) {
        const selected = i === currentPage;
        pages.push(html`
          <li
            part=${`pagination-page-${i}`}
            tabindex="${selected ? '-1' : '0'}"
            data-page=${i}
            aria-current=${ifNotNull(selected ? 'true' : null)}
            class=${classMap({ item: true, page: true, current: selected })}
            @click=${!selected ? this.handlePageClick : null}
          >
            <span
              class="page__inner"
              aria-label="${getString(
                'kat_pagination_page_number_label',
                null,
                this.locale
              )} ${i}"
              >${i}</span
            >
          </li>
        `);
      }
    };

    const generateEllipsis = () => {
      pages.push(html`
        <li class="item ellipsis"><span class="page__inner">...</span></li>
      `);
    };

    let leftEllipsis = false;
    let rightEllipsis = false;

    const generateOpenEndedPages = () => {
      // Max number of pages on either side of the current page
      const leftDelta = 2;
      const rightDelta = 1;

      // lowerLimit starts at 2 because page 1 is always displayed.
      const lowerLimit = 2;

      let leftIndex = currentPage - leftDelta;
      if (leftIndex < lowerLimit) {
        leftIndex = lowerLimit;
      }

      let rightIndex;
      if (
        currentPage < MAX_PAGER_SEGMENTS &&
        this.totalPages >= MAX_PAGER_SEGMENTS
      ) {
        // If the user is currently on pages 1-6, but we know that there are at least 7 pages, rightmost page is 6.
        // subtract 1 to account for ellipsis
        rightIndex = MAX_PAGER_SEGMENTS - 1;
      } else {
        rightIndex = currentPage + rightDelta;
      }

      if (rightIndex < MAX_PAGER_SEGMENTS) {
        leftIndex = lowerLimit;
      }

      leftEllipsis = leftIndex > lowerLimit;

      // Page 1 is always present
      generatePages(1, 1);

      if (leftEllipsis) {
        generateEllipsis();
      }

      generatePages(leftIndex, rightIndex);

      // Right ellipsis is always present
      generateEllipsis();
    };

    const generateDetailedPages = () => {
      if (this.totalPages > MAX_PAGER_SEGMENTS) {
        // only show left ellipsis when there are more than `MAX_PAGER_SEGMENTS`
        // total pages and the user has clicked on the 5th page.
        leftEllipsis = currentPage > MAX_DIFF_FROM_ENDPOINTS;

        // only show right ellipsis when there are more than `MAX_PAGER_SEGMENTS`
        // pages and the user is within 4 pages of the total number of pages
        rightEllipsis =
          this.totalPages - currentPage >= MAX_DIFF_FROM_ENDPOINTS;
      }

      if (!leftEllipsis && !rightEllipsis) {
        // neither ellipsis is set, write all the numbers out
        generatePages(1, this.totalPages);
      } else if (leftEllipsis && !rightEllipsis) {
        // only the left ellipsis is being set, the first number will always exist
        generatePages(1, 1);
        generateEllipsis();

        // next we need to write out up to the last `MAX_DIFF_FROM_ENDPOINTS` pages
        generatePages(
          this.totalPages - MAX_DIFF_FROM_ENDPOINTS,
          this.totalPages
        );
      } else if (!leftEllipsis && rightEllipsis) {
        // we need to write out the first `MAX_DIFF_FROM_ENDPOINTS` numbers, an ellipsis, and the max number
        generatePages(1, MAX_DIFF_FROM_ENDPOINTS + 1);
        generateEllipsis();
        generatePages(this.totalPages, this.totalPages);
      } else {
        // both ellipsis are being written - we need the first number, ellipsis,
        // currentpage - 1, currentpage, currentpage + 1, ellipsis, lastnumber
        generatePages(1, 1);
        generateEllipsis();
        generatePages(currentPage - 1, currentPage + 1);
        generateEllipsis();
        generatePages(this.totalPages, this.totalPages);
      }
    };

    if (this.openEnded) {
      generateOpenEndedPages();
    } else {
      generateDetailedPages();
    }

    return pages;
  }

  renderPagination() {
    const currentPage = this.page;

    const firstPage = currentPage === 1;
    const lastPage = this.openEnded ? false : currentPage === this.totalPages;

    const pages = this._generatePages();

    return html`
      <nav
        @keydown=${this.handleKeydown}
        aria-label="${getString('kat_pagination_nav_label', null, this.locale)}"
      >
        <span
          tabindex="${firstPage ? '-1' : '0'}"
          class=${classMap({ nav: true, item: true, end: firstPage })}
          part="pagination-nav-left"
          @click=${this.handleNavClick}
        >
          <kat-icon
            class="nav__icon"
            name="chevron-left"
            aria-label="${getString(
              'kat_pagination_back_navigation_label',
              null,
              this.locale
            )}"
          ></kat-icon>
        </span>
        <ul class="pages">
          ${pages}
        </ul>
        <span
          tabindex="${lastPage ? '-1' : '0'}"
          part="pagination-nav-right"
          class=${classMap({ nav: true, item: true, end: lastPage })}
          @click=${this.handleNavClick}
        >
          <kat-icon
            class="nav__icon"
            name="chevron-right"
            aria-label="${getString(
              'kat_pagination_forward_navigation_label',
              null,
              this.locale
            )}"
          ></kat-icon>
        </span>
      </nav>
    `;
  }

  renderCurrentItemsInfo() {
    const minItem = (this.page - 1) * this.itemsPerPage + 1;
    const maxItem = Math.min(this.page * this.itemsPerPage, this.totalItems);

    if (this.openEnded) {
      return html`
        <span class="current-items-info">${minItem}-${maxItem}</span>
      `;
    }

    return html`<span class="current-items-info">
      ${getString(
        'kat_pagination_items_displayed',
        {
          currentItems: `${minItem}-${maxItem}`,
          totalItems: `${this.totalItems}`,
        },
        this.locale
      )}
    </span>`;
  }

  renderPerPageSelector() {
    const selectorOptions = this.itemsPerPageOptions.map(option => ({
      name: getString(
        'kat_pagination_items_per_page_option',
        {
          numItems: `${option}`,
        },
        this.locale
      ),
      value: `${option}`,
    }));

    const handleChange = (e: { detail: { value: string } }) => {
      this.itemsPerPage = Number(e.detail.value);
      this.page = 1;
      this._itemsPerPageChange.emit({ value: this.itemsPerPage });
    };

    return html`
      <kat-dropdown
        options=${JSON.stringify(selectorOptions)}
        value=${selectorOptions[0].value}
        @change=${handleChange}
      ></kat-dropdown>
    `;
  }

  render() {
    if (this.variant === 'footer') {
      return html`
        <div class="footer">
          ${this.renderCurrentItemsInfo()} ${this.renderPagination()}
          ${this.renderPerPageSelector()}
        </div>
      `;
    }

    return this.renderPagination();
  }
}
