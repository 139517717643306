import React from 'react';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton/CopyToClipboardButton';
import { KatIcon, KatLink } from '@amzn/katal-react';
import { ARN } from '@aws-sdk/util-arn-parser';

interface EndpointInfoProps {
  arn: ARN;
  arnStrings: string[];
}

export const ScalingTargetInfo = ({ arn, arnStrings }: EndpointInfoProps) => {
  if (!arn) return;
  const parsedResource = arn.resource.split('/');
  const isSageMaker =
    parsedResource.includes('sagemaker') && parsedResource.includes('variant');

  const variantName = parsedResource[5]?.split(':')?.[0];
  const policyName = parsedResource[6];
  const endpointName = parsedResource[3];

  return isSageMaker ? (
    <div className="scaling-target-info">
      <ul className="scaling-target-info__list">
        <li>
          <p>Endpoint Variant</p>
          <span className="job-visualization-node__info">
            {variantName}
            <CopyToClipboardButton
              data={variantName}
              icon="content_copy"
              variant="link"
            />
          </span>
        </li>
        <li>
          <p>Policy Name</p>
          <span className="job-visualization-node__info">
            {policyName}
            <CopyToClipboardButton
              data={policyName}
              icon="content_copy"
              variant="link"
            />
          </span>
        </li>
      </ul>
      <div className="job-visualization-node__links">
        <KatLink
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${arn.region}.console.aws.amazon.com/sagemaker/home?region=${arn.region}#/endpoints/${endpointName}`}
        >
          View Endpoint in SageMaker
          <KatIcon name="launch" size="tiny" />
        </KatLink>
      </div>
    </div>
  ) : (
    <div className="scaling-target-info">
      <p>Scaling Policy Arn</p>
      <span className="job-visualization-node__info">{arnStrings[0]}</span>
      <CopyToClipboardButton
        data={arnStrings[0]}
        icon="content_copy"
        variant="link"
      />
    </div>
  );
};
