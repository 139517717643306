import { useContext } from 'react';
import { UserContext } from 'src/context/userContext';
import { urlList } from 'src/context/url';

export interface RetryConfig {
  action: string;
  id: string;
  operatorName: string;
}

export interface TriggerOperatorActionResponse {
  operatorName: string;
  operatorFlowJobId: string;
  operatorAction: 'RETRY' | 'SKIP';
  triggerStatus: 'SUCCEEDED' | 'FAILED';
}

export const useRetryOperator = (): {
  retry: (retryConfig: RetryConfig) => Promise<TriggerOperatorActionResponse>;
} => {
  const user = useContext(UserContext);
  const retry = (option: RetryConfig) =>
    fetch(urlList.operatorAction, {
      method: 'POST',
      headers: {
        Authorization: user.token,
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        operatorFlowJobId: option.id,
        operatorName: option.operatorName,
        operatorAction: option.action.toUpperCase(),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message) throw new Error(res.message);
        if (res.triggerStatus === 'FAILED')
          throw new Error('Failed to request Operator Action');
        return res;
      });

  return { retry };
};
