import React, { useContext } from 'react';
import { KatButton } from '@amzn/katal-react';
import { RetryConfig } from 'src/hooks/useRetryOperator';
import { ApiContext } from 'src/context/apiContext';

export const RetryMenu = ({
  nodeData,
  disabled,
  setConfig,
}: {
  nodeData: { id: string; operatorName: string };
  disabled?: boolean;
  setConfig: (retryConfig: RetryConfig) => void;
}) => {
  const { state } = useContext(ApiContext);
  return (
    <div className="retry-menu">
      <KatButton
        label="Retry"
        size="small"
        disabled={disabled}
        onClick={() => {
          setConfig({
            action: 'retry',
            id: state.search.curOperatorFlowJobId,
            operatorName: nodeData.operatorName,
          });
        }}
      />
    </div>
  );
};
