import { useContext, useEffect, useState } from 'react';
import { urlList } from 'src/context/url';
import { UserContext } from 'src/context/userContext';

export const useSageMakerConfig = (
  ids: string[],
): [boolean, string | null, Record<string, {}> | null] => {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, {}> | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (ids.length === 0) return;
    setLoading(true);

    fetch(`${urlList.jobDetails}?jobIds=${ids.join('&jobIds=')}`, {
      method: 'GET',
      headers: {
        Authorization: user.token,
        'Content-type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        setData(
          res.reduce(
            (
              map: Record<string, {}>,
              jobDetail: { jobId: string; sageMakerApiPayload: string },
            ) => {
              try {
                map[jobDetail.jobId] = JSON.parse(
                  jobDetail.sageMakerApiPayload,
                );
                return map;
              } catch {
                map[
                  jobDetail.jobId
                ] = `Invalid JSON: ${jobDetail.sageMakerApiPayload}`;
                return map;
              }
            },
            {},
          ),
        );

        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [ids, user.token]);

  return [loading, error, data];
};
