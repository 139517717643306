import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'src/context/apiContext';
import { useTrainingJobs } from 'src/hooks/useTrainingJobs/useTrainingJobs';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';
import { TrainingJobTable } from 'src/components/TrainingJobTable/TrainingJobTable';
import { TrainingJob } from 'src/context/apiState';
import { BreadcrumbNavigation } from 'src/components/BreadcrumbNavigation/BreadcrumbNavigation';
import { MonitoringScheduleTable } from 'src/components/MonitoringScheduleTable/MonitoringScheduleTable';
import { useMonitoringSchedules } from 'src/hooks/useMonitoringSchedules/useMonitoringSchedules';
import { MetricsChart } from 'src/components/TrainingMetricsChart/MetricsChart';

export const ModelMetricsPage = () => {
  const { state } = useContext(ApiContext);
  const [metricsLoading, metricsError, metricsData] = useTrainingJobs(
    state.search.curModelArtifactId,
  );
  const [
    monitoringSchedulesLoading,
    monitoringSchedulesError,
    monitoringSchedulesData,
  ] = useMonitoringSchedules(state.search.curModelArtifactId);

  const [metricNames, setMetricNames] = useState<Set<string> | null>(null);
  const modelArtifactId = state.search.curModelArtifactId;

  const currentApplicationId = state.search.curApplicationId;
  const applicationDetails = state.applications.data.find(
    (app) => app['id'] === currentApplicationId,
  );
  const currentModelVersionId = state.search.curModelVersionId;
  const modelVersionDetails = state.modelVersions.data.find(
    (mv) => mv['id'] === currentModelVersionId,
  );

  // loop through all the jobs and their metrics, build a list of metric names
  useEffect(() => {
    if (!metricsData) return;

    const names = metricsData.reduce(
      (namesSet: Set<string>, job: TrainingJob) => {
        job.metrics.forEach((metric) => namesSet.add(metric.MetricName));
        return namesSet;
      },
      new Set<string>(),
    );

    setMetricNames(names.size > 0 ? names : null);
  }, [metricsData]);

  return (
    <ContentLayout
      heading={
        <>
          <BreadcrumbNavigation
            links={[
              {
                display: `Account (${state.search.curAccountNo})`,
                href: `/accounts/${state.search.curAccountNo}`,
              },
              {
                display: applicationDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}`,
              },
              {
                display: modelVersionDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}`,
              },
              {
                display: `Model Metrics (${modelArtifactId})`,
              },
            ]}
          />
          <h1>Model Metrics</h1>
        </>
      }
    >
      {!state.search.curApplicationId ||
      !state.search.curModelVersionId ||
      !state.search.curModelArtifactId ? (
        <p>Please select an application, model version and model artifact.</p>
      ) : (
        <>
          <MetricsChart
            heading={<h2>Training Metrics</h2>}
            loading={metricsLoading}
            error={metricsError}
            data={metricsData}
            metricNames={metricNames}
          />
          <section className="training-jobs">
            <TrainingJobTable
              loading={metricsLoading}
              data={metricsData}
              metricNames={metricNames}
            />
          </section>
          <section className="training-jobs">
            <MonitoringScheduleTable
              loading={monitoringSchedulesLoading}
              data={monitoringSchedulesData}
              error={monitoringSchedulesError}
            />
          </section>
        </>
      )}
    </ContentLayout>
  );
};
