import React from 'react';
import { KatIcon, KatLabel, KatLink } from '@amzn/katal-react';
import { build, ARN } from '@aws-sdk/util-arn-parser';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton/CopyToClipboardButton';
import { truncateString } from 'src/utils/generalUtils/generalUtils';

export const BedrockLink = ({ arn }: { arn: ARN }) => {
  const linkTextMap: Record<string, string> = {
    'model-customization-job': 'Model Customization Job',
  };

  const href = `https://console.aws.amazon.com/bedrock/home?region=${
    arn.region
  }#/custom-models/item?arn=${build(arn)}`;

  const [resourceType] = arn.resource.split('/');

  return (
    <>
      <KatLabel>{`Bedrock ${linkTextMap[resourceType] || 'Job'} Arn`}</KatLabel>
      <span>
        {truncateString(build(arn), 40)}
        <CopyToClipboardButton
          data={build(arn)}
          icon="content_copy"
          variant="link"
        />
      </span>
      <KatLink
        className="link link--icon"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        Open AWS Bedrock
        <KatIcon name="launch" size="tiny" />
      </KatLink>
    </>
  );
};
