import React, { useContext, useState } from 'react';
import { ApiContext } from 'src/context/apiContext';
import { OperatorFlowIdColumn } from 'src/components/OperatorFlowTable/columns/OperatorFlowIdColumn/OperatorFlowIdColumn';
import { BadgeCell } from 'src/components/table-cells/BadgeCell/BadgeCell';
import { OperatorFlowLinkListCell } from 'src/components/OperatorFlowTable/columns/OperatorFlowLinkListCell/OperatorFlowLinkListCell';
import { StatusCell } from 'src/components/table-cells/StatusCell/StatusCell';
import { DataTable } from 'src/components/DataTable/DataTable';
import { DateColumn } from 'src/components/DateColumn/DateColumn';

import { VariableListCell } from 'src/components/VariableListCell/VariableListCell';
import { ColumnSetFilter } from 'src/components/ColumnSetFilter';

interface OperatorFlowTableProps {
  loading: boolean;
  data?: OperatorFlowJob[];
}

export const OperatorFlowTable = ({
  loading,
  data,
}: OperatorFlowTableProps) => {
  const { state, dispatch } = useContext(ApiContext);
  const [showRelativeDate, setShowRelativeDate] = useState<boolean>(true);
  const columns = [
    OperatorFlowIdColumn(state, dispatch, 300),
    {
      Cell: BadgeCell,
      width: 110,
      id: 'badges',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: StatusCell,
      id: 'status',
      filterable: true,
      Filter: ColumnSetFilter,
    },
    DateColumn({
      header: 'Created Date',
      accessor: 'createdDate',
      id: 'created-date',
      showRelativeDate,
      setShowRelativeDate,
    }),
    {
      Header: 'Variables',
      accessor: 'variables',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: (cellInfo: any) => <VariableListCell variables={cellInfo.value} />,
      width: 190,
      sortable: false,
      id: 'variables',
    },
    {
      Header: 'Region',
      accessor: 'region',
      id: 'region',
      width: 100,
    },
    {
      Header: 'Starting Operator',
      accessor: 'startingOperatorName',
      id: 'starting-operator-name',
      width: 200,
    },
    {
      Header: 'Redeployment Jobs',
      accessor: 'redeployedOperatorFlowJobIds',
      id: 'redeployment-operator-flows',
      Cell: OperatorFlowLinkListCell,
    },
  ];

  return (
    <DataTable
      loading={loading}
      heading="Operator Flows"
      columns={columns}
      data={data || []}
      options={{
        noDataText: 'There are no operator flows for the given time filter.',
        defaultSort: {
          id: 'created-date',
          desc: true,
        },
      }}
    />
  );
};
