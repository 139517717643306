import { Account } from 'src/hooks/useGetAccounts/useGetAccounts';
import { parse } from '@aws-sdk/util-arn-parser';

export const truncateString = (str: string, maxLength: number): string =>
  str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;

export const updateAccountLocalStorage = (account: Account) => {
  // write the selected account to local storage
  window.localStorage.setItem('selectedAccount', JSON.stringify(account));

  // update recently used accounts in local storage
  const localRecentAccountsString =
    window.localStorage.getItem('recentAccounts');
  const localRecentAccounts: Account[] = localRecentAccountsString
    ? JSON.parse(localRecentAccountsString)
    : [];

  //is the incoming account in the list? remove it
  const index = localRecentAccounts.findIndex(
    (storedAccount) => storedAccount.id === account.id,
  );
  if (index > -1) {
    localRecentAccounts.splice(index, 1);
  }

  localRecentAccounts.unshift(account);

  while (localRecentAccounts.length > 3) {
    localRecentAccounts.pop();
  }

  window.localStorage.setItem(
    'recentAccounts',
    JSON.stringify(localRecentAccounts),
  );
};

export const getRoleName = (roleArn: string): string => {
  // IAM Role name can only use alphanumeric and '+=,.@-_' characters.
  try {
    if (parse(roleArn).resource.split('/')[0] === 'role') {
      return parse(roleArn).resource.split('/')[1];
    }
    return 'Invalid IAM Role name';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return 'TypeError';
  }
};

export const buildArn = (accountNumber: string, roleName: string): string =>
  `arn:aws:iam::${accountNumber}:role/${roleName}`;

export const validateRoleName = (roleName: string): boolean =>
  /^[\w+=,.@-]+$/g.test(roleName) && roleName.length < 64;
