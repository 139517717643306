import React, { ReactElement, useContext, useState } from 'react';
import cx from 'classnames';
import { TrainingJob } from 'src/context/apiState';
import { ApiContext } from 'src/context/apiContext';
import { useSageMakerConfig } from 'src/hooks/useSageMakerConfig/useSageMakerConfig';
import { TrainingJobColumn } from 'src/components/TrainingJobTable/columns/TrainingJobColumn/TrainingJobColumn';
import { AlgorithmMetricsColumn } from 'src/components/TrainingJobTable/columns/AlgorithmMetricsColumn/AlgorithmMetricsColumn';
import { SageMakerConfigColumn } from 'src/components/TrainingJobTable/columns/SageMakerConfigColumn/SageMakerConfigColumn';
import { DataTable } from 'src/components/DataTable/DataTable';
import { KatLink } from '@amzn/katal-react';
import { Link } from 'react-router-dom';
import { DateColumn } from 'src/components/DateColumn/DateColumn';

export const TrainingJobTable = ({
  loading,
  data,
  metricNames,
}: {
  loading: boolean;
  data: TrainingJob[] | null;
  metricNames: Set<string> | null;
}): ReactElement => {
  const { state } = useContext(ApiContext);
  const [selectedMetric, setSelectedMetric] = useState<string | null>(null);
  const [jsonKey, setJsonKey] = useState<string>('HyperParameters');
  const [visibleIds, setVisibleIds] = useState<string[]>([]);
  const [jsonLoading, jsonError, jsonData] = useSageMakerConfig(visibleIds);
  const [showCreatedRelativeDate, setShowCreatedRelativeDate] =
    useState<boolean>(true);

  const updateVisibleIds = () => {
    const ids: string[] = [];
    document
      .querySelectorAll('.training-job-table .training-job-cell__id')
      .forEach((node) => {
        ids.push(node.innerHTML);
      });
    setVisibleIds(ids);
  };

  return (
    <section
      className={cx('training-job-table', { 'content-loading': loading })}
    >
      {!loading && data && (
        <DataTable
          loading={loading}
          heading="Training Jobs"
          controls={
            <>
              <Link
                to={`/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}/modelArtifacts/${state.search.curModelArtifactId}/dataQualityMetrics`}
              >
                <KatLink label="View all data quality metrics" />
              </Link>
            </>
          }
          data={data}
          options={{
            defaultPageSize: 10,
            defaultSort: {
              id: 'created-date',
              desc: true,
            },
            onFetchData: () => {
              updateVisibleIds();
              setSelectedMetric(metricNames ? [...metricNames][0] : null);
            },
            onSortedChange: updateVisibleIds,
            onPageChange: updateVisibleIds,
            onPageSizeChange: updateVisibleIds,
          }}
          columns={[
            TrainingJobColumn(
              state.search.curAccountNo,
              state.search.curApplicationId,
              state.search.curModelVersionId,
              state.search.curModelArtifactId,
            ),
            DateColumn({
              header: 'Created Date',
              id: 'created-date',
              accessor: 'createdDate',
              showRelativeDate: showCreatedRelativeDate,
              setShowRelativeDate: setShowCreatedRelativeDate,
              width: 150,
            }),
            AlgorithmMetricsColumn(
              selectedMetric,
              setSelectedMetric,
              metricNames,
            ),
            SageMakerConfigColumn(
              jsonKey,
              setJsonKey,
              jsonLoading,
              jsonData,
              jsonError,
            ),
          ]}
        />
      )}
    </section>
  );
};
