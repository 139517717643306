import { KatButton, KatModal } from '@amzn/katal-react';
import React from 'react';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton/CopyToClipboardButton';

interface Props {
  statusMessage: { message: string; name: string } | null;
  setStatusMessage: (arg0: { message: string; name: string } | null) => void;
}

export const StatusMessageModal = ({
  statusMessage,
  setStatusMessage,
}: Props) => {
  return (
    <KatModal
      footer={
        <>
          <CopyToClipboardButton
            data={statusMessage?.message || ''}
            variant="link"
          />
          <KatButton
            label="Close"
            onClick={() => {
              setStatusMessage(null);
            }}
          />
        </>
      }
      title="Approval Request Status Message"
      visible={statusMessage !== null}
      onClose={() => {
        setStatusMessage(null);
      }}
    >
      <pre>{statusMessage?.message}</pre>
    </KatModal>
  );
};
