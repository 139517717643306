import React from 'react';
import { KatDropdown } from '@amzn/katal-react';

export const TrainingMetricsChartControls = ({
  firstMetricName,
  metricNamesOptions,
  setSelectedMetric,
  setCompareMetric,
  setRange,
  trainingName = 'Job',
}: {
  firstMetricName?: string;
  metricNamesOptions?: { name: string; value: string }[];
  setSelectedMetric: (metric: string) => void;
  setCompareMetric: (metric: string) => void;
  setRange: (range: number) => void;
  trainingName?: string;
}) => (
  <div className="metrics-graph__controls">
    <KatDropdown
      disabled={!metricNamesOptions}
      value={firstMetricName}
      label="Metric"
      placeholder="Select a metric to view"
      options={metricNamesOptions}
      onChange={(e: KatDropdown.ChangeEvent) => {
        setSelectedMetric(e.detail.value);
      }}
    />
    <KatDropdown
      disabled={!metricNamesOptions}
      value=""
      label="Compare Metric"
      placeholder="Select a metric to compare"
      options={metricNamesOptions}
      onChange={(e: KatDropdown.ChangeEvent) => {
        setCompareMetric(e.detail.value);
      }}
    />
    <KatDropdown
      disabled={!metricNamesOptions}
      value="10"
      label={`${trainingName}s Shown`}
      options={[
        { name: `10 most recent ${trainingName.toLowerCase()}s`, value: '10' },
        { name: `25 most recent ${trainingName.toLowerCase()}s`, value: '25' },
        { name: `50 most recent ${trainingName.toLowerCase()}s`, value: '50' },
        {
          name: `100 most recent ${trainingName.toLowerCase()}s`,
          value: '100',
        },
        { name: `All ${trainingName.toLowerCase()}s`, value: '0' }, // setRange(0) indicates all jobs
      ]}
      onChange={(e: KatDropdown.ChangeEvent) => {
        setRange(parseInt(e.detail.value));
      }}
    />
  </div>
);
