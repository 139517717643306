import { katTranslator } from '../../shared/i18n';

const strings = {
  "kat-dropdown-button-open": {
    "en-US": "open dropdown",
    "ar-AE": "فتح القائمة المنسدلة",
    "bn-IN": "ড্রপডাউন খুলুন",
    "de-DE": "Drop-down-Menü öffnen",
    "en-AU": "open drop-down",
    "en-CA": "open drop-down",
    "en-GB": "open drop-down",
    "en-IN": "open drop-down",
    "en-SG": "open drop-down",
    "es-ES": "abrir menú desplegable",
    "es-MX": "abrir menú desplegable",
    "fr-BE": "ouvrir le menu déroulant",
    "fr-FR": "ouvrir le menu déroulant",
    "gu-IN": "ડ્રોપડાઉન ઓપન કરો",
    "hi-IN": "ड्रॉपडाउन खोलें",
    "it-IT": "apri menu a tendina",
    "ja-JP": "ドロップダウンを開く",
    "kn-IN": "ಡ್ರಾಪ್‌ಡೌನ್ ತೆರೆಯಿರಿ",
    "ko-KR": "드롭다운 열기",
    "ml-IN": "ഡ്രോപ്പ്ഡൗൺ തുറക്കുക",
    "mr-IN": "ड्रॉपडाउन उघडा",
    "nl-BE": "vervolgkeuzelijst openen",
    "nl-NL": "vervolgkeuzelijst openen",
    "pl-PL": "otwórz listę rozwijaną",
    "pt-BR": "abrir menu suspenso",
    "pt-PT": "abrir lista suspensa",
    "sv-SE": "öppna rullgardinsmeny",
    "ta-IN": "டிராப்டவுனைத் திற",
    "te-IN": "ఓపెన్ డ్రాప్‌డౌన్",
    "th-TH": "เปิดดรอปดาวน์",
    "tr-TR": "açılır menüyü aç",
    "vi-VN": "mở menu thả xuống",
    "zh-CN": "打开下拉菜单",
    "zh-TW": "打開下拉式選單"
  },
  "kat-dropdown-button-close": {
    "en-US": "close dropdown",
    "ar-AE": "إغلاق القائمة المنسدلة",
    "bn-IN": "বন্ধ ড্রপডাউন",
    "de-DE": "Drop-down-Menü schließen",
    "en-AU": "close drop-down",
    "en-CA": "close drop-down",
    "en-GB": "close drop-down",
    "en-IN": "close drop-down",
    "en-SG": "close drop-down",
    "es-ES": "cerrar menú desplegable",
    "es-MX": "cerrar menú desplegable",
    "fr-BE": "fermer le menu déroulant",
    "fr-FR": "fermer le menu déroulant",
    "gu-IN": "ડ્રોપડાઉન બંધ કરો",
    "hi-IN": "ड्रॉपडाउन बंद करें",
    "it-IT": "chiudi menu a tendina",
    "ja-JP": "ドロップダウンを閉じる",
    "kn-IN": "ಡ್ರಾಪ್‌ಡೌನ್ ಮುಚ್ಚಿ",
    "ko-KR": "드롭다운 닫기",
    "ml-IN": "ഡ്രോപ്പ്ഡൗൺ അടയ്ക്കുക",
    "mr-IN": "ड्रॉपडाउन बंद करा",
    "nl-BE": "vervolgkeuzelijst sluiten",
    "nl-NL": "vervolgkeuzelijst sluiten",
    "pl-PL": "zamknij listę rozwijaną",
    "pt-BR": "fechar menu suspenso",
    "pt-PT": "fechar lista suspensa",
    "sv-SE": "stäng rullgardinsmenyn",
    "ta-IN": "டிராப்டவுனை மூடு",
    "te-IN": "క్లోజ్ డ్రాప్‌డౌన్",
    "th-TH": "ปิดดรอปดาวน์",
    "tr-TR": "açılır menüyü kapat",
    "vi-VN": "đóng menu thả xuống",
    "zh-CN": "关闭下拉菜单",
    "zh-TW": "關閉下拉式選單"
  }
}; 

export default katTranslator(strings);
    