import React from 'react';
import heroBg from '../../public/hero-bg.jpg';
import { KatButton } from '@amzn/katal-react';
import { useNavigate } from 'react-router-dom';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { Link } from 'src/components/Link';

interface HeroSectionProps {
  actionMetricsPublisher: KatalMetricsPublisher;
}

export const HeroSection = ({ actionMetricsPublisher }: HeroSectionProps) => {
  const navigate = useNavigate();

  return (
    <section
      className="hero-section"
      style={{ backgroundImage: `url(${heroBg})` }}
    >
      <div className="hero-section__container">
        <div className="hero-section__wrapper">
          <h1>
            <span className="italics">
              <strong>Productionize</strong>
            </span>{' '}
            ML Models While Maintaining <strong>Flexibility</strong> And{' '}
            <strong>Account Ownership</strong>
          </h1>
          <p className="sub-heading">
            Support for model development and workflow creation, allowing Data
            Scientists to focus on science, while keeping in line with software
            engineering best practices
          </p>

          <div className="button-wrapper">
            <KatButton
              onClick={() => {
                actionMetricsPublisher?.publishCounterMonitor('Onboarding', 1);
                navigate('/accountSetup');
              }}
            >
              Onboard an AWS Account
            </KatButton>
            <Link to="https://w.amazon.com/bin/view/MLPigeon" external>
              Visit Our Wiki
            </Link>
          </div>
          <span className="italics">
            *Conduit <strong>and</strong> Isengard supported
          </span>
        </div>
      </div>
    </section>
  );
};
