import React from 'react';
import { KatLink } from '@amzn/katal-react';
import { Account } from 'src/hooks/useGetAccounts/useGetAccounts';
import { Link } from 'react-router-dom';

export const RecentAccounts = ({
  accounts,
  onClick,
}: {
  accounts: Account[] | null;
  onClick?: (account: Account) => void;
}) => (
  <div>
    <p className="recent-accounts__label">Recent Accounts</p>
    <ul className="recent-accounts__accounts">
      {!accounts && (
        <li>
          <p>No recently used accounts.</p>
        </li>
      )}
      {accounts?.map((account) => (
        <li key={account.id}>
          <Link to={`/accounts/${account.id}`}>
            <KatLink
              onClick={() => onClick?.(account)}
              className="recent-accounts__account-link"
            >
              {account.name}
            </KatLink>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);
