import React from 'react';
import { truncateString } from 'src/utils/generalUtils/generalUtils';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton/CopyToClipboardButton';
import { Placeholder } from 'src/components/Placeholder/Placeholder';
import { KatLink } from '@amzn/katal-react';

interface Props {
  loading: boolean;
  message?: { message: string; name: string } | null;
  setStatusModalMessage: (arg0: { message: string; name: string }) => void;
}
export const StatusMessage = ({
  loading,
  message,
  setStatusModalMessage,
}: Props) => {
  return (
    <div className="status-message">
      <p className="status-message__label">Status Message</p>
      <Placeholder
        ready={!loading}
        shapes={<rect y="0" width="100%" height="50" />}
      >
        <div className="status-message__content-container">
          <div className="status-message__content-header">
            <>
              <p className="text-secondary status-message__content">
                <span>
                  {truncateString(
                    message?.message || 'No status message available.',
                    100,
                  )}
                  {message && message?.message?.length > 100 && (
                    <KatLink
                      onClick={() => {
                        setStatusModalMessage({
                          message: message?.message || '',
                          name: message?.name || '',
                        });
                      }}
                    >
                      Read More
                    </KatLink>
                  )}
                </span>
              </p>

              {message && message?.message?.length <= 100 && (
                <CopyToClipboardButton
                  data={message.message}
                  icon="content_copy"
                  variant="link"
                />
              )}
            </>
          </div>
          <p className="status-message__label">Created By</p>
          <p className="text-secondary status-message__createdby">
            {message?.name && <span>{truncateString(message?.name, 50)}</span>}
          </p>
        </div>
      </Placeholder>
    </div>
  );
};
