import React, { ReactNode } from 'react';
import { KatIcon, KatLink } from '@amzn/katal-react';
import { useNavigate } from 'react-router-dom';

interface LinkProps {
  children: ReactNode;
  to: string;
  external?: boolean;
  onClick?: () => void;
}

export const Link = ({ children, to, external, onClick }: LinkProps) => {
  const navigate = useNavigate();
  return (
    <KatLink
      href={external ? to : undefined}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener noreferrer' : undefined}
      onClick={(e) => {
        onClick?.();
        if (!external) {
          e.preventDefault();
          navigate(`${to}`);
        }
      }}
    >
      {children}
      {external && <KatIcon name="launch" size="tiny" />}
    </KatLink>
  );
};
