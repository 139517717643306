import React from 'react';
import { Link } from 'react-router-dom';
import { KatLink } from '@amzn/katal-react';

export const LinkGroupCell = ({
  links,
  onClick,
}: {
  links: { text: string; href: string }[];
  onClick?: () => void;
}) => (
  <div className="link-group">
    {links.map((link) => (
      <Link key={link.text} to={link.href}>
        <KatLink onClick={onClick}>{link.text}</KatLink>
      </Link>
    ))}
  </div>
);
