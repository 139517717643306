import React from 'react';
import { ARN } from '@aws-sdk/util-arn-parser';
import { OperatorInfo } from 'src/components/JobVisualizationNode/OperatorInfo';
import { StatusLabel } from 'src/components/ApprovalOperatorNode/StatusLabel';
import { Link } from 'react-router-dom';
import { ScalingTargetInfo } from 'src/components/ScalingTargetInfo';
import { KatIcon } from '@amzn/katal-react';

export interface AutoScalingConfigNodeProps {
  header: string;
  info: {
    id: string;
    operatorName: string;
    operatorFlowId: string;
    modelName?: string;
    modelArn?: ARN;
  };
  status: OperatorJobStatus;
  arnList: ARN[];
  setOperatorHelpConfig: (arg: { status: OperatorJobStatus }) => void;
  arnStrings: string[];
}

export const AutoScalingConfigNode = ({
  header,
  info,
  status,
  arnList,
  setOperatorHelpConfig,
  arnStrings,
}: AutoScalingConfigNodeProps) => {
  const operatorJobTypeMap: Record<OperatorJobStatus, string> = {
    NOTSTARTED: 'Not Started',
    STARTING: 'Starting',
    PENDING: 'Pending',
    SUCCEEDED: 'Succeeded',
    FAILED: 'Failed',
  };
  const operatorFriendlyStatus: string = operatorJobTypeMap[status];

  return (
    <div className="job-visualization-node">
      <div className="job-visualization-node__header">
        <h3>{header}</h3>
        <div className="job-visualization-node__status-container">
          <StatusLabel
            loading={false}
            label={operatorFriendlyStatus.toLowerCase()}
          />
          <button
            disabled={status === 'SUCCEEDED'}
            className="icon-button"
            onClick={() => setOperatorHelpConfig({ status })}
            title="Troubleshooting"
          >
            <KatIcon size="small" name="help" />
          </button>
        </div>
      </div>
      <OperatorInfo operatorId={info.id} operatorName={info.operatorName} />
      <ScalingTargetInfo arn={arnList[0] || ''} arnStrings={arnStrings} />

      <div className="job-visualization-node__buttons">
        <Link
          to={`../logs?operatorName=${info.operatorName}`}
          relative="path"
          target="_blank"
        >
          View Logs
        </Link>
      </div>
    </div>
  );
};
