import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import { KatDropdown, KatSpinner } from '@amzn/katal-react';
import { ApiContext } from 'src/context/apiContext';
import {
  useGetAccounts,
  Account,
} from 'src/hooks/useGetAccounts/useGetAccounts';

export function AWSAccountPicker({
  label,
  onChange,
  darkBg = false,
}: {
  label?: string;
  onChange: (e: Account) => void;
  darkBg?: boolean;
}) {
  const { state } = useContext(ApiContext);
  const [loading, error, data, getAccounts] = useGetAccounts();

  useEffect(() => {
    getAccounts();
  }, []);

  const onChangeHandler = (e: any) => {
    const account = data?.find((account) => account.id === e.detail.value);
    account && onChange(account);
  };

  return (
    <div
      className={cx('aws-account-picker', {
        'aws-account-picker--dark': darkBg,
      })}
    >
      {label && <label>{label}</label>}
      <div className="aws-account-picker__loading-container">
        {loading && (
          <span className="aws-account-picker__spinner">
            <KatSpinner
              size="small"
              variant={darkBg ? 'inverted' : 'default'}
            />
          </span>
        )}
        {!loading && error && <span>Accounts Error: {error.message}</span>}
        {!loading && !error && data && (
          <KatDropdown
            className="site-name-picker"
            options={data
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((account) => ({
                name: `${account.name} (${account.id})`,
                value: account.id,
              }))}
            value={state.search.curAccountNo}
            placeholder="Select an AWS account"
            onChange={onChangeHandler}
            searchable
          />
        )}
      </div>
    </div>
  );
}
