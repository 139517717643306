import React, { useContext, useState } from 'react';
import { ApiContext } from 'src/context/apiContext';
import { Link, useParams } from 'react-router-dom';
import { KatButton, KatLink } from '@amzn/katal-react';
import { DataTable } from 'src/components/DataTable/DataTable';
import { DateColumn } from 'src/components/DateColumn/DateColumn';
import { ModelVersionModal } from 'src/components/modals/ModelVersionModal';
import { useModelVersions } from 'src/hooks/useModelVersions';

export function ModelVersionList() {
  const { dispatch } = useContext(ApiContext);
  const [showRelativeDate, setShowRelativeDate] = useState<boolean>(true);
  const { accountNo, applicationId } = useParams();
  const [modelVersionModalVisible, setModelVersionModalVisible] =
    useState<boolean>(false);
  const [modelVersionModalMode, setModelVersionModalMode] = useState<
    'New' | 'Edit' | 'Delete'
  >('New');
  const [modelVersionModalValues, setModelVersionModalValues] =
    useState<any>(null);

  const showModelVersionModal = (
    mode: 'New' | 'Edit' | 'Delete',
    values: any,
  ) => {
    setModelVersionModalValues(values);
    setModelVersionModalMode(mode);
    setModelVersionModalVisible(true);
  };

  const { data, isLoading } = useModelVersions({ applicationId });

  const columns = [
    {
      Header: 'Model Version Name',
      accessor: 'name',
      sortable: true,
      id: 'model-version-name',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: (rowInfo: any) => (
        <Link
          to={`/accounts/${accountNo}/applications/${applicationId}/modelVersions/${rowInfo.row.original.id}/`}
        >
          <KatLink
            onClick={() => {
              dispatch({
                type: 'setCurrentModelVersionId',
                payload: rowInfo.row.original.id,
              });
            }}
          >
            {rowInfo.row.original.name}
          </KatLink>
        </Link>
      ),
    },
    {
      Header: 'Model Version ID',
      accessor: 'id',
      id: 'model-version-id',
      sortable: true,
    },
    {
      Header: 'Description',
      id: 'description',
      accessor: 'description',
    },
    DateColumn({
      header: 'Updated Date',
      accessor: 'updatedDate',
      showRelativeDate,
      setShowRelativeDate,
      id: 'updated-date',
    }),
    {
      Header: 'Updated By',
      accessor: 'updatedBy',
      sortable: true,
      id: 'updated-by',
    },
    {
      id: 'buttons',
      width: 220,
      disableResizing: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: (props: any) => (
        <div className="button-cell">
          <KatButton
            onClick={() => showModelVersionModal('Edit', props.row.original)}
            label="Edit"
            variant="secondary"
          />
          <KatButton
            label="Delete"
            variant="danger"
            onClick={() => showModelVersionModal('Delete', props.row.original)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="application-list">
      <DataTable
        loading={isLoading}
        heading="Model Versions"
        controls={
          <KatButton
            onClick={() => {
              showModelVersionModal('New', null);
            }}
          >
            New Model Version
          </KatButton>
        }
        options={{ defaultSort: { id: 'updated-date', desc: true } }}
        data={data || []}
        columns={columns}
      />
      {applicationId && (
        <ModelVersionModal
          visible={modelVersionModalVisible}
          modalMode={modelVersionModalMode}
          values={modelVersionModalValues}
          onClose={() => {
            setModelVersionModalVisible(false);
          }}
          applicationId={applicationId}
        />
      )}
    </div>
  );
}
