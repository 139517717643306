import React from 'react';
import { KatLink } from '@amzn/katal-react';
import { Link } from 'react-router-dom';

export const BreadcrumbNavigation = ({
  links,
}: {
  links: {
    display?: string;
    href?: string;
  }[];
}) => (
  <nav>
    <ul className="breadcrumb-nav">
      {links.map((link) => {
        if (!link.display) return;
        return (
          <li key={link.display} className="breadcrumb-nav__item">
            {link.href ? (
              <Link to={link.href}>
                <KatLink>{link.display}</KatLink>
              </Link>
            ) : (
              <span>{link.display}</span>
            )}
          </li>
        );
      })}
    </ul>
  </nav>
);
