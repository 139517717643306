import React, { useState, useEffect } from 'react';
import * as KatalMetrics from '@amzn/katal-metrics';
import cx from 'classnames';
import { KatIcon, KatLink } from '@amzn/katal-react';
import { Link } from 'react-router-dom';

export const SubNavMenu = ({
  menuText,
  iconName = 'dehaze',
  iconSize = 'medium',
  links,
  metricsPublisher,
}: {
  menuText?: string;
  iconName?: KatIcon.Name;
  iconSize?: KatIcon.Size;
  links: {
    display: string;
    detail?: string;
    href: string;
    external?: boolean;
  }[];
  metricsPublisher?: KatalMetrics.Publisher;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const actionMetricsPublisher = metricsPublisher
    ? metricsPublisher.newChildActionPublisherForMethod('HelpMenu')
    : null;

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsOpen(false);
    });
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') setIsOpen(false);
    });
    window.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (
        // @ts-ignore
        !target?.matches(['.subnav-menu', '.subnav-menu *'])
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  const linkClickHandler = (name: string) => {
    actionMetricsPublisher?.publishCounterMonitor(name, 1);
  };

  return (
    <>
      <div className="subnav-menu">
        <button
          className="subnav-menu__display"
          onClick={() => {
            isOpen === false && linkClickHandler('help-menu-open');
            setIsOpen(!isOpen);
          }}
          aria-expanded={isOpen}
        >
          <span className="subnav-menu__menu-text">{menuText}</span>
          <KatIcon name={iconName} size={iconSize} />
        </button>
        {isOpen && (
          <div
            className={cx('subnav-menu__menu', {
              'subnav-menu__menu--open': isOpen,
            })}
          >
            <ul className="subnav-menu__links">
              {links.map((link) => (
                <li key={link.href}>
                  {link.external ? (
                    <KatLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.href}
                      onClick={() => {
                        linkClickHandler(
                          `${link.display.replace(/ /g, '-')}-click`,
                        );
                        setIsOpen(false);
                      }}
                      className="subnav-menu__link"
                    >
                      <>
                        {link.display}
                        {link.detail && (
                          <p className="subnav-menu__link-detail">
                            {link.detail}
                          </p>
                        )}
                      </>
                    </KatLink>
                  ) : (
                    <Link
                      to={link.href}
                      onClick={() => {
                        linkClickHandler(
                          `${link.display.replace(' ', '-')}-click`,
                        );
                        setIsOpen(false);
                      }}
                    >
                      <KatLink className="subnav-menu__link">
                        <>
                          {link.display}
                          {link.detail && (
                            <p className="subnav-menu__link-detail">
                              {link.detail}
                            </p>
                          )}
                        </>
                      </KatLink>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
