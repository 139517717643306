import React from 'react';

export const BadgeCell = ({ row }: any) => {
  const badges = [];
  if (row.original.rolledBackOperatorFlowJobId) {
    badges.push({ text: 'Redeploy' });
  }
  return (
    <div>
      {badges.map((badge) => (
        <span className="badge" key={badge.text}>
          {badge.text}
        </span>
      ))}
    </div>
  );
};
