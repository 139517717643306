import { useContext, useState } from 'react';
import { urlList } from 'src/context/url';
import { UserContext } from 'src/context/userContext';

export interface Account {
  name: string;
  id: string;
  type: string;
}

export const useGetAccounts = (): [
  boolean,
  Error | null,
  Account[] | null,
  () => void,
] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<Account[] | null>(null);
  const user = useContext(UserContext);

  const getAccounts = () => {
    setLoading(true);
    fetch(urlList.accounts, {
      method: 'GET',
      headers: {
        Authorization: user.token,
        'Content-Type': 'application/json',
      },
    })
      .then(async (res) => {
        if (!res.ok) {
          const apiResponse = await res.json();
          if (apiResponse.message) {
            throw new Error(`GetAccounts API: Error: ${apiResponse.message}`);
          }
          throw new Error(`GetAccounts API: Error: ${res.statusText}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return [loading, error, data, getAccounts];
};
