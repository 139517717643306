import { katTranslator } from '../../shared/i18n';

export const strings = {
  kat_pagination_page_number_label: {
    'en-US': 'Page',
    'ar-AE': 'الصفحة',
    'bn-IN': 'পৃষ্ঠা',
    'de-DE': 'Seite',
    'es-ES': 'Página',
    'es-MX': 'Página',
    'gu-IN': 'પેજ',
    'hi-IN': 'पेज',
    'it-IT': 'Pagina',
    'ja-JP': 'ページ',
    'kn-IN': 'ಪುಟ',
    'ko-KR': '페이지',
    'ml-IN': 'പേജ്',
    'mr-IN': 'पृष्ठ',
    'nl-BE': 'Pagina',
    'nl-NL': 'Pagina',
    'pl-PL': 'Strona',
    'pt-BR': 'Página',
    'pt-PT': 'Página',
    'sv-SE': 'Sida',
    'ta-IN': 'பக்கம்',
    'te-IN': 'పేజీ',
    'th-TH': 'หน้า',
    'tr-TR': 'Sayfa',
    'vi-VN': 'Trang',
    'zh-CN': '页面',
    'zh-TW': '頁面',
  },
  kat_pagination_nav_label: {
    'en-US': 'Pagination',
    'ar-AE': 'ترقيم الصفحات',
    'bn-IN': 'পেজিগনেশন',
    'de-DE': 'Seitenauswahl',
    'es-ES': 'Paginación',
    'es-MX': 'Paginación',
    'gu-IN': 'પેજ ક્રમાંકન',
    'hi-IN': 'पेजिनेशन',
    'it-IT': 'Impaginazione',
    'ja-JP': 'ページ分け',
    'kn-IN': 'ಪುಟ ವಿನ್ಯಾಸ',
    'ko-KR': '페이지 번호',
    'ml-IN': 'പേജിനേഷൻ',
    'mr-IN': 'पृष्ठांकन',
    'nl-BE': 'Paginering',
    'nl-NL': 'Paginering',
    'pl-PL': 'Paginacja',
    'pt-BR': 'Paginação',
    'pt-PT': 'Paginação',
    'sv-SE': 'Sidnumrering',
    'ta-IN': 'பேஜினேஷன்',
    'te-IN': 'పేజినేషన్',
    'th-TH': 'การแบ่งหน้า',
    'tr-TR': 'Sayfa numaralandırma',
    'vi-VN': 'Số trang',
    'zh-CN': '分页',
    'zh-TW': '分頁',
  },
  kat_pagination_back_navigation_label: {
    'en-US': 'Navigate back',
    'ar-AE': 'الانتقال إلى الخلف',
    'bn-IN': 'ফিরে যান',
    'de-DE': 'Zurück navigieren',
    'es-ES': 'Retroceder',
    'es-MX': 'Desplazarse hacia atrás',
    'fr-BE': 'Retour',
    'fr-CA': 'Naviguer en arrière',
    'fr-FR': 'Revenir en arrière',
    'gu-IN': 'પાછળ નેવિગેટ કરો',
    'hi-IN': 'पीछे नेविगेट करें',
    'it-IT': 'Torna indietro',
    'ja-JP': '戻る',
    'kn-IN': 'ಹಿಂದಕ್ಕೆ ನ್ಯಾವಿಗೇಟ್ ಮಾಡಿ',
    'ko-KR': '뒤로 이동',
    'ml-IN': 'പിന്നിലേക്ക് നാവിഗേറ്റ് ചെയ്യുക',
    'mr-IN': 'मागे नेव्हिगेट करा',
    'nl-BE': 'Navigeer terug',
    'nl-NL': 'Terug navigeren',
    'pl-PL': 'Przejdź wstecz',
    'pt-BR': 'Navegar para trás',
    'pt-PT': 'Navegar atrás',
    'sv-SE': 'Gå tillbaka',
    'ta-IN': 'பின்னால் செல்லவும்',
    'te-IN': 'వెనక్కి నావిగేట్ చేయండి',
    'th-TH': 'ไปยังหน้าก่อน',
    'tr-TR': 'Geri git',
    'vi-VN': 'Điều hướng quay lại',
    'zh-CN': '向后导航',
    'zh-TW': '向後瀏覽',
  },
  kat_pagination_forward_navigation_label: {
    'en-US': 'Navigate forward',
    'ar-AE': 'الانتقال إلى الأمام',
    'bn-IN': 'সামনে অগ্রসর হন',
    'de-DE': 'Vorwärts navigieren',
    'en-AU': 'Navigate forwards',
    'en-SG': 'Navigate forwards',
    'es-ES': 'Avanzar',
    'es-MX': 'Avanzar',
    'fr-BE': 'Avancer',
    'fr-CA': "Naviguer vers l'avant",
    'fr-FR': 'Avancer',
    'gu-IN': 'આગળ નેવિગેટ કરો',
    'hi-IN': 'आगे नेविगेट करें',
    'it-IT': 'Vai avanti',
    'ja-JP': '進む',
    'kn-IN': 'ಮುಂದಕ್ಕೆ ನ್ಯಾವಿಗೇಟ್ ಮಾಡಿ',
    'ko-KR': '앞으로 이동',
    'ml-IN': 'മുന്നോട്ട് നാവിഗേറ്റ് ചെയ്യുക',
    'mr-IN': 'पुढे नेव्हिगेट करा',
    'nl-BE': 'Navigeer verder',
    'nl-NL': 'Vooruit navigeren',
    'pl-PL': 'Przejdź do przodu',
    'pt-BR': 'Navegar para frente',
    'pt-PT': 'Navegar em frente',
    'sv-SE': 'Gå framåt',
    'ta-IN': 'முன்னோக்கி செல்லவும்',
    'te-IN': 'ముందుకు నావిగేట్ చేయండి',
    'th-TH': 'ไปยังหน้าต่อไป',
    'tr-TR': 'İleri git',
    'vi-VN': 'Điều hướng đến trước',
    'zh-CN': '向前导航',
    'zh-TW': '向前瀏覽',
  },
  kat_pagination_items_per_page_option: {
    'en-US': '{numItems} per page',
    'ar-AE': '{numItems}لكل صفحة',
    'bn-IN': 'প্রতি পৃষ্ঠায় {numItems} টি',
    'de-DE': '{numItems} pro Seite',
    'es-ES': '{numItems} por página',
    'es-MX': '{numItems} por página',
    'fr-BE': '{numItems} par page',
    'fr-CA': '{numItems} par page',
    'fr-FR': '{numItems} par page',
    'gu-IN': 'પેજ દીઠ {numItems}',
    'hi-IN': 'हर पेज पर {numItems}',
    'it-IT': '{numItems} per pagina',
    'ja-JP': '{numItems}/ページ',
    'kn-IN': '{numItems} ಪ್ರತಿ ಪುಟಕ್ಕೆ',
    'ko-KR': '페이지당 {numItems}개',
    'ml-IN': 'ഓരോ പേജിലും {numItems}',
    'mr-IN': 'प्रति पृष्ठ {numItems}',
    'nl-BE': '{numItems} per pagina',
    'nl-NL': '{numItems} per pagina',
    'pl-PL': '{numItems} na stronę',
    'pt-BR': '{numItems} por página',
    'pt-PT': '{numItems} por página',
    'sv-SE': '{numItems} per sida',
    'ta-IN': 'ஒவ்வொரு பக்கத்திற்கும் {numItems}',
    'te-IN': 'పేజీకి {numItems}',
    'th-TH': '{numItems} ต่อหน้า',
    'tr-TR': 'Her sayfada {numItems}',
    'vi-VN': '{numItems} mỗi trang',
    'zh-CN': '每页 {numItems} 个 ',
    'zh-TW': '每頁 {numItems}',
  },
  kat_pagination_items_displayed: {
    'en-US': '{currentItems} of {totalItems}',
    'ar-AE': '{currentItems} من {totalItems}',
    'bn-IN': '{totalItems} এর {currentItems}',
    'de-DE': '{currentItems} von {totalItems}',
    'es-ES': '{currentItems} de {totalItems}',
    'es-MX': '{currentItems} de {totalItems}',
    'fr-BE': '{currentItems} sur {totalItems}',
    'fr-CA': '{currentItems} de {totalItems}',
    'fr-FR': '{currentItems} sur {totalItems}',
    'gu-IN': '{totalItems}ના {currentItems}',
    'hi-IN': '{totalItems} में से {currentItems}',
    'it-IT': '{currentItems} di {totalItems}',
    'ja-JP': '{currentItems}/{totalItems}',
    'kn-IN': '{totalItems} ರಲ್ಲಿ {currentItems}',
    'ko-KR': '{currentItems}/{totalItems}',
    'ml-IN': '{currentItems} / {totalItems}',
    'mr-IN': '{currentItems} चे {totalItems}',
    'nl-BE': '{currentItems} van {totalItems}',
    'nl-NL': '{currentItems} van {totalItems}',
    'pl-PL': '{currentItems} z {totalItems}',
    'pt-BR': '{currentItems} de {totalItems}',
    'pt-PT': '{currentItems} de {totalItems}',
    'sv-SE': '{currentItems} av {totalItems}',
    'ta-IN': '{totalItems} இல் {currentItems}',
    'te-IN': '{totalItems}లో {currentItems}',
    'th-TH': '{currentItems} จาก {totalItems}',
    'tr-TR': '{currentItems}/{totalItems}',
    'vi-VN': '{currentItems}/{totalItems}',
    'zh-CN': '第 {currentItems} 个，共 {totalItems} 个',
    'zh-TW': '{currentItems} / {totalItems}',
  },
};

export default katTranslator(strings);
