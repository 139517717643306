import React, { useState } from 'react';
import { KatButton } from '@amzn/katal-react';
import { DataTable } from 'src/components/DataTable/DataTable';
import { DateColumn } from 'src/components/DateColumn/DateColumn';
import { useModelVersionEvents } from 'src/hooks/useModelVersionEvents';

import { useParams } from 'react-router-dom';
import { ModelVersionEventFormModal } from 'src/components/modals/ModelVersionEventFormModal/ModelVersionEventFormModal';

export const ModelVersionEventsTable = () => {
  const params = useParams();
  const { data, isLoading } = useModelVersionEvents({
    versionId: params.modelVersionId,
  });

  const [showRelativeDate, setShowRelativeDate] = useState<boolean>(true);
  const [modelVersionEventModalVisible, setModelVersionEventModalVisible] =
    useState<boolean>(false);
  const [modelVersionEventModalMode, setModelVersionEventModalMode] = useState<
    'New' | 'Edit' | 'Delete'
  >('New');
  const [modelEventModalValues, setModelEventModalValues] = useState<any>(null);

  const showModelEventModal = (
    mode: 'New' | 'Edit' | 'Delete',
    values: any,
  ) => {
    setModelEventModalValues(values);
    setModelVersionEventModalMode(mode);
    setModelVersionEventModalVisible(true);
  };

  const columns = [
    { Header: 'Event Id', accessor: 'id', sortable: true, id: 'event-id' },
    {
      Header: 'Event Type',
      accessor: 'type',
      sortable: true,
      id: 'event-type',
    },
    { Header: 'S3 Bucket', accessor: 's3Bucket', id: 's3-bucket' },
    { Header: 'S3 Prefix', accessor: 's3Prefix', id: 's3-prefix' },
    { Header: 'Operator Name', accessor: 'operatorName', id: 'operator-name' },
    DateColumn({
      header: 'Updated Date',
      id: 'updated-date',
      accessor: 'updatedDate',
      showRelativeDate,
      setShowRelativeDate,
    }),
    {
      Header: 'Updated By',
      accessor: 'updatedBy',
      sortable: true,
      id: 'updated-by',
    },
    {
      id: 'buttons',
      width: 220,
      disableResizing: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: (props: any) => (
        <div className="button-cell">
          <KatButton
            onClick={() => showModelEventModal('Edit', props.row.original)}
            label="Edit"
            variant="secondary"
          />
          <KatButton
            label="Delete"
            variant="danger"
            onClick={() => showModelEventModal('Delete', props.row.original)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <DataTable
        options={{
          noDataText: 'No Model Version Events found.',
        }}
        columns={columns}
        loading={isLoading}
        data={data || []}
        heading="Events"
        controls={
          <>
            <KatButton
              onClick={() => {
                showModelEventModal('New', null);
              }}
            >
              Add Model Version Event
            </KatButton>
          </>
        }
      />
      {modelVersionEventModalVisible && (
        <ModelVersionEventFormModal
          modalMode={modelVersionEventModalMode}
          values={modelEventModalValues}
          onClose={() => {
            setModelVersionEventModalVisible(false);
          }}
        />
      )}
    </>
  );
};
