export function numberOfDaysInMonth(date: Date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  return 32 - new Date(year, month, 32).getDate();
}

export function addDay(date: Date, amount: number) {
  const newDate = dateCopy(date);
  newDate.setDate(newDate.getDate() + amount);
  return newDate;
}

export function applyDay(date, dayOfMonth) {
  const result = dateCopy(date);
  result.setDate(dayOfMonth);
  return result;
}

export function daysBeforeFirstOfMonth(date: Date) {
  const newDate = new Date(date.getTime());
  newDate.setDate(1);
  return newDate.getDay();
}

export function isLeapYear(dateOrYear: Date | number) {
  const year =
    dateOrYear instanceof Date ? dateOrYear.getFullYear() : dateOrYear;
  return !(year % 400) || (!(year % 4) && !!(year % 100));
}

export function daysWithinOneYearBefore(date: Date) {
  const dateOneYearBefore = new Date(date.getTime());
  dateOneYearBefore.setFullYear(date.getFullYear() - 1);
  return (date.getTime() - dateOneYearBefore.getTime()) / 86400000;
}

export function daysWithinOneYearAfter(date: Date) {
  const dateOneYearAfter = new Date(date.getTime());
  dateOneYearAfter.setFullYear(date.getFullYear() + 1);
  return (dateOneYearAfter.getTime() - date.getTime()) / 86400000;
}

export function dateCopy(date: any) {
  if (isValidDateObject(date)) {
    return new Date(date.getTime());
  }
  return date;
}

export function isSameMonth(date1: Date, date2: Date) {
  if (!date1 || !date2) return false;
  return (
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function createDateFromStringAndFormat(dateString, format) {
  if (!dateString || !format) {
    return null;
  }

  const dateStringParts = splitDateString(dateString);
  const formatParts = splitDateString(format);

  if (dateStringParts.length !== 3 || formatParts.length !== 3) {
    return null;
  }

  let monthInteger;
  let dayInteger;
  let yearInteger;

  for (let i = 0; i < 3; i++) {
    const formatPart = formatParts[i];
    const dateStringPart = dateStringParts[i];
    if (formatPart === 'DD') {
      dayInteger = parseInt(dateStringPart, 10);
      if (dayInteger > 31) {
        dayInteger = NaN;
      }
    } else if (formatPart === 'MM') {
      monthInteger = parseInt(dateStringPart, 10);
      if (monthInteger > 12) {
        monthInteger = NaN;
      }
    } else if (formatPart === 'YYYY') {
      yearInteger = parseInt(dateStringPart, 10);
    }
  }

  return new Date(yearInteger, monthInteger - 1, dayInteger);
}

export function getDateFormatFromLocale(locale) {
  return new Intl.DateTimeFormat(locale)
    .formatToParts(new Date())
    .map(obj => {
      switch (obj.type) {
        case 'day':
          return 'DD';
        case 'month':
          return 'MM';
        case 'year':
          return 'YYYY';
        case 'literal':
          return obj.value.replace('\u200F', ''); // this is the separator. Get rid of RLM
        default:
          return '';
      }
    })
    .join('')
    .trim();
}

// We use a custom padStart method as opposed to the builtin str.padStart for broader browser support
export function padDate(str: string) {
  if (str.padStart) {
    return str.padStart(2, '0');
  }
  let paddingString = '';
  for (let i = 0; i < Math.max(2 - str.length, 0); i++) {
    paddingString += '0';
  }
  return paddingString + str;
}

export function isValidDateObject(date: any) {
  try {
    if (date instanceof Date) {
      const ms = date.getTime();
      return !isNaN(ms);
    }
  } catch (_) {
    return false;
  }
  return false;
}

export function formatDateObject(date: Date, formatString: string) {
  let formattedDate = formatString;

  formattedDate = formattedDate.replace(
    'MM',
    padDate((date.getMonth() + 1).toString())
  );
  formattedDate = formattedDate.replace(
    'DD',
    padDate(date.getDate().toString())
  );
  formattedDate = formattedDate.replace('YYYY', date.getFullYear().toString());

  return formattedDate;
}

function splitDateString(string: string) {
  return string.split(/[^0-9A-Z]/g).filter(i => i.length > 0);
}
