import React, { ReactElement, ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastPortal } from 'src/components/Toast/ToastPortal/ToastPortal';
import { SubNavMenu } from 'src/components/SubNavMenu/SubNavMenu';
import { initialMetricsPublisher } from 'src/utils/metrics/metrics';
import { KatSpinner } from '@amzn/katal-react';
import { Routing } from 'src/components/Routing/Routing';

interface SiteContainerProps {
  header: ReactNode;
  sidePanel?: ReactNode;
}

function SiteContainer({
  header,
  sidePanel,
}: SiteContainerProps): ReactElement {
  return (
    <div className="site-container">
      <Routing />
      <div className="site-container__header">{header}</div>
      <div className="site-container__side-panel">{sidePanel}</div>
      <div className="site-container__main">
        <main>
          <Suspense
            fallback={
              <div className="chunk-loading-spinner">
                <KatSpinner size="large" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
          <ToastPortal autoClose autoCloseTime={7000} />
          <div className="help-link">
            <SubNavMenu
              metricsPublisher={initialMetricsPublisher}
              menuText="Need Help?"
              iconName="help"
              iconSize="large"
              links={[
                {
                  display: 'FAQ',
                  detail: 'Frequently Asked Questions',
                  href: 'https://w.amazon.com/bin/view/MLPigeon/FAQ',
                  external: true,
                },
                {
                  display: 'Python Package Search',
                  detail:
                    'See if a python package is available for use with MLPigeon',
                  href: 'https://prod.peruse.builder-tools.aws.dev/search/pypi',
                  external: true,
                },
                {
                  display: 'Slack',
                  detail: 'Search our Slack support room: #mlpigeon-support',
                  href: 'https://amzn-wwc.slack.com/archives/C01BPFC6JE6',
                  external: true,
                },
                {
                  display: 'Office Hours',
                  detail:
                    'For additional support, please join our office hours.',
                  href: 'https://office-hour-scheduler.tools.aws.dev/schedule/2b6ed158-520a-4f2b-9e88-4fd21b614ae4',
                  external: true,
                },
                {
                  display: 'SIM Ticketing',
                  detail: 'Cut a ticket to the MLPigeon team',
                  href: 'https://t.corp.amazon.com/create/templates/e2e102bc-6cc2-4487-b5c3-d77d78d91827',
                  external: true,
                },
              ]}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default SiteContainer;
