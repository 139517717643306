import { MonitoringSchedule } from 'src/context/apiState';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/context/userContext';
import { urlList } from 'src/context/url';

export const useMonitoringSchedules = (
  modelArtifactId: string,
): [boolean, Error | null, MonitoringSchedule[] | null] => {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<MonitoringSchedule[] | null>(null);
  useEffect(() => {
    if (!modelArtifactId) return;
    setLoading(true);

    fetch(
      urlList.monitoringSchedules +
        '?' +
        new URLSearchParams({
          modelArtifactId,
        }),
      {
        headers: {
          Authorization: user.token,
          'Content-type': 'application/json',
        },
      },
    )
      .then(async (res) => {
        if (!res.ok) {
          const apiResponse = await res.json();
          if (apiResponse.message) {
            throw new Error(
              `GetMonitoringSchedule API: Error: ${apiResponse.message}`,
            );
          }
          throw new Error(
            `GetMonitoringSchedule API: Error: ${res.statusText}`,
          );
        }
        return res;
      })
      .then((res) => res.json())
      .then((data: MonitoringSchedule[]) => {
        const succeededMonitoringSchedules: MonitoringSchedule[] = [];
        data.map((item) => {
          if (item.status === 'SUCCEEDED')
            succeededMonitoringSchedules.push(item);
        });
        setData(succeededMonitoringSchedules);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [modelArtifactId, user.token]);

  return [loading, error, data];
};
