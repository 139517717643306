import React, { ReactElement, ReactNode } from 'react';

export const ContentLayout = ({
  heading,
  navigation,
  description,
  children,
}: {
  heading: ReactNode;
  navigation?: ReactNode;
  description?: ReactNode;
  children: ReactNode;
}): ReactElement => (
  <section className="content-layout">
    <div className="content-layout__heading-container-background">
      <div className="content-layout__heading-container">
        {navigation && (
          <div className="content-layout__navigation">{navigation}</div>
        )}
        <div className="content-layout__heading">{heading}</div>
        {description && (
          <div className="content-layout__description">{description}</div>
        )}
      </div>
    </div>
    <div className="content-layout__content">{children}</div>
  </section>
);
