import { katTranslator } from '../../shared/i18n';

const strings = {
  "date-picker-calendar-button-aria-label": {
    "en-US": "Calendar",
    "ar-AE": "التقويم",
    "bn-IN": "ক্যালেন্ডার",
    "de-DE": "Kalender",
    "es-ES": "Calendario",
    "es-MX": "Calendario",
    "fr-BE": "Calendrier",
    "fr-FR": "Calendrier",
    "gu-IN": "કેલેન્ડર",
    "hi-IN": "कैलेंडर",
    "it-IT": "Calendario",
    "ja-JP": "カレンダー",
    "kn-IN": "ಕ್ಯಾಲೆಂಡರ್",
    "ko-KR": "달력",
    "ml-IN": "കലണ്ടർ",
    "mr-IN": "कॅलेंडर",
    "nl-BE": "Kalender",
    "nl-NL": "Kalender",
    "pl-PL": "Kalendarz",
    "pt-BR": "Calendário",
    "pt-PT": "Calendário",
    "sv-SE": "Kalender",
    "ta-IN": "காலெண்டர்",
    "te-IN": "క్యాలెండర్",
    "th-TH": "ปฏิทิน",
    "tr-TR": "Takvim",
    "vi-VN": "Lịch",
    "zh-CN": "日历",
    "zh-TW": "行事曆"
  }
}; 

export default katTranslator(strings);
    