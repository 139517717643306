import React, { ReactElement } from 'react';
import { KatAlert } from '@amzn/katal-react';
import { IToastMessage } from 'src/context/apiState';

export const ToastMessage = ({
  id,
  header,
  description,
  onClick,
  onDismiss,
  dismissTime,
  variant = 'info',
  held = false,
}: IToastMessage): ReactElement => {
  return (
    <div className="toast-message">
      <KatAlert
        id={id}
        className="toast-message__alert"
        header={header}
        onDismiss={onDismiss}
        onClick={onClick}
        variant={variant}
      >
        {description}
      </KatAlert>
      {!held && dismissTime && (
        <div
          style={{
            animation: `expand ${Math.floor(dismissTime / 1000)}s ease-out`,
          }}
          className={`toast-message__progress-bar toast-message__progress-bar--${variant}`}
        />
      )}
    </div>
  );
};
