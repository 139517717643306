import { QueryClient, useQuery } from '@tanstack/react-query';
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from 'src/utils/mlPigeonAPIRequest/mlPigeonAPIRequest';
import { urlList } from 'src/context/url';

interface Props {
  applicationId: string | undefined;
}

export const useModelVersions = ({ applicationId }: Props) => {
  if (!applicationId) return { data: [], isLoading: false };
  return useQuery<ModelVersion[], Error>({
    queryKey: ['model-versions', applicationId],
    queryFn: () =>
      apiGetRequest({
        endpoint: urlList.modelVersions,
        params: {
          applicationId,
        },
      }),
  });
};

type CreateModelVersionProps = {
  applicationId: string;
  name: string;
  version?: number;
  description?: string;
  tags?: Record<string, string>;
};

export const getModelVersion = ({
  modelVersionId,
}: {
  modelVersionId?: string;
}) => {
  if (!modelVersionId) return { data: undefined, isLoading: false };
  return useQuery<ModelVersion, Error>({
    queryKey: ['model-versions', modelVersionId],
    queryFn: () =>
      apiGetRequest({
        endpoint: `${urlList.modelVersions}/${modelVersionId}`,
        params: {},
      }),
  });
};

export const createModelVersion = (
  { applicationId, name, description }: CreateModelVersionProps,
  queryClient: QueryClient,
) => {
  return apiPostRequest({
    endpoint: urlList.modelVersions,
    data: { applicationId, name, description },
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['model-versions', applicationId],
    });
  });
};

export const editModelVersion = (
  {
    applicationId,
    name,
    description,
    version,
    id,
  }: CreateModelVersionProps & { id: string },
  queryClient: QueryClient,
) => {
  return apiPutRequest({
    endpoint: urlList.modelVersions,
    data: { applicationId, name, description, version, id },
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['model-versions'],
    });
  });
};

export const deleteModelVersion = (
  { id, applicationId }: { id: string; applicationId: string },
  queryClient: QueryClient,
) => {
  return apiDeleteRequest({
    endpoint: `${urlList.modelVersions}/${id}`,
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['model-versions', applicationId],
    });
  });
};
