import React from 'react';

import { OperatorFlowLink } from 'src/components/OperatorFlowLink/OperatorFlowLink';

export const OperatorFlowLinkListCell = (cellInfo: any) => (
  <div>
    {cellInfo.value.map((link: string) => (
      <div key={link}>
        <OperatorFlowLink operatorFlowJobId={link}>{link}</OperatorFlowLink>
      </div>
    ))}
  </div>
);
