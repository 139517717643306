import { ApiContext } from 'src/context/apiContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account } from 'src/hooks/useGetAccounts/useGetAccounts';
import { updateAccountLocalStorage } from 'src/utils/generalUtils/generalUtils';

export const useSetAccount = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(ApiContext);

  const setAccount = (account: Account) => {
    updateAccountLocalStorage(account);

    // set the account in state
    dispatch({ type: 'setCurrentAccountNo', payload: account.id });

    // clear the global state for the previous account selection
    dispatch({ type: 'setCurrentApplicationId', payload: '' });
    dispatch({ type: 'setCurrentModelVersionId', payload: '' });
    dispatch({ type: 'setCurrentModelArtifactId', payload: '' });
    dispatch({ type: 'updateOperatorFlowJobData', payload: { data: [] } });

    if (
      window.location.pathname.startsWith('/accounts') ||
      window.location.pathname === '/'
    ) {
      navigate(`/accounts/${account.id}`);
    }
  };

  return setAccount;
};
