export const getDateRangeFromToday = (length: number) => {
  const endDate = new Date();
  const startDate =
    length === 0
      ? new Date()
      : new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * length);

  return {
    endDate,
    startDate,
  };
};

export const convertDateToEpochTimeInSeconds = (date: Date) =>
  Math.round(date.getTime() / 1000);
