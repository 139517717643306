import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import { KatBox, KatButton, KatIcon } from '@amzn/katal-react';
import { AWSAccountPicker } from 'src/components/AWSAccountPicker/AWSAccountPicker';
import { Link } from 'react-router-dom';
import { RecentAccounts } from 'src/components/RecentAccounts/RecentAccounts';
import { useSetAccount } from 'src/hooks/useSetAccount/useSetAccount';
import { Account } from 'src/hooks/useGetAccounts/useGetAccounts';
import { ApiContext } from 'src/context/apiContext';

export const AccountSwitcher = ({
  darkBg = false,
  variant = 'default',
  onChange,
}: {
  darkBg?: boolean;
  variant?: 'default' | 'mobile';
  onChange?: () => void;
}) => {
  const { state } = useContext(ApiContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [accountDisplay, setAccountDisplay] =
    useState<string>('Select an account.');
  const [recentAccounts, setRecentAccounts] = useState<Account[] | null>(null);
  const setAccount = useSetAccount();

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsOpen(false);
    });
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    });
    window.addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      if (!target) return;
      if (
        // @ts-ignore
        !target.matches([
          '.account-switcher__mobile-button',
          '.account-switcher *',
        ])
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    const localAccountString = window.localStorage.getItem('selectedAccount');
    const localAccount: Account = localAccountString
      ? JSON.parse(localAccountString)
      : null;

    const localRecentAccountsString =
      window.localStorage.getItem('recentAccounts');
    const localRecentAccounts: Account[] = localRecentAccountsString
      ? JSON.parse(localRecentAccountsString)
      : null;

    setAccountDisplay(
      localAccount
        ? `${localAccount.name} (${localAccount.id})`
        : state.search.curAccountNo || 'Select an account.',
    );

    if (localRecentAccounts) {
      setRecentAccounts(localRecentAccounts);
    }
  }, [state.search.curAccountNo]);

  const updateAccount = (account: Account) => {
    setAccountDisplay(`${account.name} (${account.id})`);
    setIsOpen(false);
    setAccount(account);
    onChange?.();
  };

  return (
    <>
      <div
        className={cx('account-switcher', {
          'account-switcher--darkbg': darkBg,
          'account-switcher--mobile': variant === 'mobile',
        })}
      >
        {variant === 'mobile' ? (
          <KatBox variant="zircon">
            <span className="account-switcher__label">Current Account</span>
            <p aria-expanded={isOpen}>{accountDisplay}</p>
            <KatButton
              className="account-switcher__mobile-button"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              Change Account
            </KatButton>
          </KatBox>
        ) : (
          <button
            className="account-switcher__display"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <span aria-expanded={isOpen}>{accountDisplay}</span>
            <KatIcon name="chevron-down" size="small" />
          </button>
        )}
        {isOpen && (
          <div
            className={cx('account-switcher__menu', {
              'account-switcher__menu--open': isOpen,
            })}
          >
            <div>
              <AWSAccountPicker
                label="Current Account"
                onChange={(account) => {
                  updateAccount(account);
                }}
                darkBg={true}
              />
            </div>
            <div className="account-switcher__recent-accounts-container">
              <RecentAccounts
                onClick={(account) => {
                  updateAccount(account);
                }}
                accounts={recentAccounts}
              />
            </div>
            <Link to="/accountSetup">
              <KatButton
                className="w-full"
                onClick={() => {
                  setIsOpen(false);
                  onChange?.();
                }}
                variant="secondary"
                label="Onboard a new Account"
              />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
