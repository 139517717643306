import React from 'react';

import { MetricValueCell } from 'src/components/table-cells/MetricValueCell/MetricValueCell';

export const AlgorithmMetricsColumn = (
  selectedMetric: string | null,
  setSelectedMetric: (metric: string) => void,
  metricNames: Set<string> | null,
) => {
  const firstMetricName = metricNames ? [...metricNames][0] : null;
  const AlgorithmMetricsColumnFilter = () => (
    <select
      className="data-table__filter-select"
      disabled={!metricNames}
      value={selectedMetric || firstMetricName || ''}
      onChange={(e) => {
        setSelectedMetric(e.target.value);
      }}
    >
      {!metricNames && <option>No metrics available</option>}
      {metricNames &&
        Array.from(metricNames).map((name) => (
          <option
            key={name}
            defaultChecked={
              selectedMetric && selectedMetric === name ? true : undefined
            }
            value={name}
          >
            {name}
          </option>
        ))}
    </select>
  );
  const AlgorithmMetricsColumnCell = (cellInfo: any) => {
    if (!cellInfo.value) return <span>No metrics available</span>;
    const value = cellInfo.value.find(
      (metric: Metric) => metric.MetricName === selectedMetric,
    )?.Value;
    return <MetricValueCell value={value} />;
  };
  return {
    Header: 'Algorithm Metrics',
    Filter: AlgorithmMetricsColumnFilter,
    filterable: true,
    accessor: 'metrics',
    sortable: false,
    width: 150,
    Cell: AlgorithmMetricsColumnCell,
  };
};
