import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from 'src/context/apiContext';
import { initialMetricsPublisher } from 'src/utils/metrics/metrics';
import { HeroSection } from 'src/components/HeroSection';
import { PointsSection } from 'src/components/PointsSection';
import { WorkflowSection } from 'src/components/WorkflowSection';
import { DevelopmentSection } from 'src/components/DevelopmentSection';
import { CommunitySection } from 'src/components/CommunitySection';
import { Link } from 'src/components/Link';

export function Home() {
  const navigate = useNavigate();
  const { state } = useContext(ApiContext);
  useEffect(() => {
    if (state.search.curAccountNo) {
      navigate(`/accounts/${state.search.curAccountNo}`);
    }
  }, []);

  const actionMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('HomePage');

  return (
    <div>
      <HeroSection actionMetricsPublisher={actionMetricsPublisher} />
      <PointsSection />
      <WorkflowSection />
      <DevelopmentSection />
      <CommunitySection actionMetricsPublisher={actionMetricsPublisher} />

      <footer className="homepage-footer">
        <ul>
          <li>
            <Link
              to="https://w.amazon.com/index.php/MLPigeon/FeaturesProvidedDetail"
              external
            >
              See our Full Feature List
            </Link>
          </li>
          <li>
            <Link to="https://w.amazon.com/bin/view/MLPigeon" external>
              Visit Our Wiki
            </Link>
          </li>
          <li>
            <Link to="https://w.amazon.com/bin/view/MLPigeon/FAQ">FAQs</Link>
          </li>
        </ul>
      </footer>
    </div>
  );
}
