import React from 'react';

import { JSONViewer } from 'src/components/JSONViewer/JSONViewer';

export const SageMakerConfigColumn = (
  jsonKey: string,
  setJsonKey: (key: string) => void,
  jsonLoading: boolean,
  jsonData: Record<string, any> | null,
  jsonError: string | null,
) => {
  const SageMakerConfigColumnFilter = () => (
    <select
      className="data-table__filter-select"
      value={jsonKey}
      onChange={(e) => {
        setJsonKey(e.target.value);
      }}
    >
      <option value="HyperParameters" defaultChecked={true}>
        HyperParameters
      </option>
      <option value="">All</option>
    </select>
  );

  const SageMakerConfigColumnCell = (cellInfo: any) => (
    <JSONViewer
      loading={jsonLoading}
      jsonKey={jsonKey}
      expanded={!jsonKey}
      error={jsonError}
      jsonData={jsonData ? jsonData[cellInfo.value] : undefined}
    />
  );
  return {
    Header: 'SageMaker Config',
    accessor: 'id',
    id: 'sagemaker-config',
    Filter: SageMakerConfigColumnFilter,
    filterable: true,
    sortable: false,
    Cell: SageMakerConfigColumnCell,
  };
};
