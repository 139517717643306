import React from 'react';
import moment from 'moment';

export interface ProfileDetails {
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
}

export function ProfileDetails({
  createdDate,
  createdBy,
  updatedDate,
  updatedBy,
}: ProfileDetails) {
  function formatDate(date: any) {
    const actualDate = moment.unix(date);
    return actualDate.toString();
  }

  const badgePhotosBaseURL =
    'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';

  return (
    <div className="profile-details">
      <div className="profile-details__element">
        <p className="profile-details__header">Updated Date</p>
        <p data-testid="updated-date">{formatDate(updatedDate)}</p>
      </div>
      <div className="profile-details__element">
        <p className="profile-details__header">Updated By</p>
        <div className="profile-details__user">
          <a
            target="_blank"
            href={`https://phonetool.amazon.com/users/${updatedBy}`}
            rel="noreferrer"
          >
            <img
              className="photo"
              src={`${badgePhotosBaseURL}${updatedBy}`}
              alt=""
            />
            {`@${updatedBy}`}
          </a>
        </div>
      </div>
      <div className="profile-details__element">
        <p className="profile-details__header">Created Date</p>
        <p data-testid="created-date">{formatDate(createdDate)}</p>
      </div>
      <div className="profile-details__element">
        <p className="profile-details__header">Created By</p>
        <div className="profile-details__user">
          <a
            target="_blank"
            href={`https://phonetool.amazon.com/users/${createdBy}`}
            rel="noreferrer"
          >
            <img
              className="photo"
              src={`${badgePhotosBaseURL}${createdBy}`}
              alt=""
            />
            {`@${createdBy}`}
          </a>
        </div>
      </div>
    </div>
  );
}
