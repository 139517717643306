import React, { Dispatch, SetStateAction } from 'react';
import { ARN, parse } from '@aws-sdk/util-arn-parser';
import { JobVisualizationNode } from 'src/components/JobVisualizationNode/JobVisualizationNode';
import { ReactFlowProvider } from 'react-flow-renderer';
import { RedeployConfig } from 'src/hooks/useModelRedeployment/useModelRedeployment';
import { ApprovalOperatorNode } from 'src/components/ApprovalOperatorNode/ApprovalOperatorNode';
import { DeploymentJobNode } from 'src/components/DeploymentJobNode/DeploymentJobNode';
import { RetryConfig } from 'src/hooks/useRetryOperator';
import { GraphDiagram } from 'src/components/GraphDiagram/GraphDiagram';
import { ApprovalConfig } from 'src/hooks/useApprovalDecision/useApprovalDecision';
import { AutoScalingConfigNode } from 'src/components/AutoScalingConfigNode';

const operatorTypeMap: Record<OperatorJobType, string> = {
  AUTOSCALINGCONFIG: 'Auto Scaling Config',
  BATCHINFERENCEJOB: 'Batch Inference Job',
  PROCESSINGJOB: 'Processing Job',
  DEPLOYMENTJOB: 'Deployment Job',
  TRAININGJOB: 'Training Job',
  FEEDBACKLOOPJOB: 'Feedback Loop Job',
  APPROVALOPERATOR: 'Approval Request',
  HYPERPARAMETERTUNINGJOB: 'Hyperparameter Tuning Job',
  LABELINGJOB: 'Labeling Job',
  MODELCUSTOMIZATIONJOB: 'Model Customization Job',
  MONITORINGSCHEDULE: 'Monitoring Schedule',
  INLINECODE: 'Inline Code',
  SAGEMAKERPIPELINEEXECUTION: 'SageMaker Pipeline Execution',
  SHAREMODELJOB: 'Share Model Job',
  SHAREDMODELCONSUMEJOB: 'Consume Shared Model Job',
};

// attempt to parse the supplied arnStrings, ignore malformed ones.
const parseArnList = (arnStrings: string[]): ARN[] =>
  arnStrings.reduce(
    (prevParsedArns: ARN[], currentArnString: string): ARN[] => {
      try {
        return [...prevParsedArns, parse(currentArnString)];
      } catch {
        return prevParsedArns;
      }
    },
    [],
  );

// given a list of ARNs, find information about a model
const getModelInformation = (
  arns: ARN[],
): { arn: ARN | undefined; name: string | undefined } => {
  const modelArn = arns.find((arn) => arn.resource.startsWith('model'));
  const name = modelArn?.resource.split('/')[1];
  return { arn: modelArn, name };
};

export const OperatorFlowGraph = ({
  operatorFlowDetails,
  getPipelineGraph,
  setPromoteConfig,
  setRedeployConfig,
  setOperatorHelpConfig,
  setApprovalConfig,
  setRetryConfig,
  setStatusModalMessage,
  goFullScreen,
  togglePanel,
}: {
  operatorFlowDetails?: OperatorFlowJob;
  getPipelineGraph?: ({ jobId }: { jobId: string }) => void;
  setPromoteConfig: Dispatch<
    SetStateAction<{ modelName: string; arn: ARN } | null>
  >;
  setRedeployConfig: Dispatch<SetStateAction<RedeployConfig | null>>;
  setOperatorHelpConfig: (arg: { status: OperatorJobStatus } | null) => void;
  setRetryConfig: (arg0: RetryConfig) => void;
  setStatusModalMessage: (
    arg0: { message: string; name: string } | null,
  ) => void;
  setApprovalConfig: (arg0: ApprovalConfig) => void;
  goFullScreen?: () => Promise<void> | undefined;
  togglePanel: ({ panelId }: { panelId: string }) => void;
}) => {
  if (!operatorFlowDetails) return;

  const nodes = operatorFlowDetails.graphDetails?.graphNodes;
  const edges = operatorFlowDetails.graphDetails?.graphEdges;
  const initialElements: any[] = [];

  nodes?.forEach((node: GraphNode) => {
    const loadNodeInfo = () => {
      if (operatorFlowDetails.id) {
        getPipelineGraph?.({ jobId: node.jobId });
      }
    };

    const viewSageMakerGraphHandler = () => {
      togglePanel({ panelId: 'panel-2' });
      loadNodeInfo();
    };

    let parsedArns: ARN[] | undefined;
    let modelInfo:
      | { arn: ARN | undefined; name: string | undefined }
      | undefined;

    // convert the list of arn strings and find the model info
    if (node.arnList) {
      parsedArns = parseArnList(node.arnList);
      modelInfo = getModelInformation(parsedArns);
    }

    // look at operatorType, see which kind of node to use
    if (node.operatorType === 'APPROVALOPERATOR') {
      initialElements.push({
        id: node.operatorName,
        nodeHeight: 275,
        data: {
          label: (
            <ApprovalOperatorNode
              header="Approval Request"
              info={{
                id: node.jobId,
                operatorName: node.operatorName,
                modelName: modelInfo?.name,
                modelArn: modelInfo?.arn,
                operatorFlowId: operatorFlowDetails.id,
              }}
              status={node.operatorStatus}
              setStatusModalMessage={setStatusModalMessage}
              setApprovalConfig={setApprovalConfig}
              setOperatorHelpConfig={setOperatorHelpConfig}
            />
          ),
        },
      });
    } else if (node.operatorType === 'DEPLOYMENTJOB') {
      initialElements.push({
        id: node.operatorName,
        nodeHeight: 230,
        data: {
          label: (
            <DeploymentJobNode
              header="Deployment Job"
              info={{
                id: node.jobId,
                operatorName: node.operatorName,
                modelName: modelInfo?.name,
                modelArn: modelInfo?.arn,
                operatorFlowId: operatorFlowDetails.id,
                region: node.region,
              }}
              arnList={parsedArns}
              status={node.operatorStatus}
              setPromoteConfig={setPromoteConfig}
              setRedeployConfig={setRedeployConfig}
              setRetryConfig={setRetryConfig}
              setOperatorHelpConfig={setOperatorHelpConfig}
            />
          ),
        },
      });
    } else if (node.operatorType === 'AUTOSCALINGCONFIG') {
      initialElements.push({
        id: node.operatorName,
        nodeHeight: node.operatorStatus !== 'PENDING' ? 280 : 130,
        data: {
          label: (
            <AutoScalingConfigNode
              header="Auto Scaling Config"
              info={{
                id: node.jobId,
                operatorName: node.operatorName,
                modelName: modelInfo?.name,
                modelArn: modelInfo?.arn,
                operatorFlowId: operatorFlowDetails.id,
              }}
              arnList={parsedArns || []}
              arnStrings={node.arnList}
              status={node.operatorStatus}
              setOperatorHelpConfig={setOperatorHelpConfig}
            />
          ),
        },
      });
    } else {
      initialElements.push({
        id: node.operatorName,
        nodeHeight: 225,
        data: {
          label: (
            <JobVisualizationNode
              header={operatorTypeMap[node.operatorType]}
              info={{
                id: node.jobId,
                operatorName: node.operatorName,
                modelName: modelInfo?.name,
                modelArn: modelInfo?.arn,
                region: node.region,
              }}
              arnList={parsedArns}
              jobType={node.operatorType}
              status={node.operatorStatus}
              viewSageMakerGraphHandler={viewSageMakerGraphHandler}
              setRetryConfig={setRetryConfig}
              setOperatorHelpConfig={setOperatorHelpConfig}
            />
          ),
        },
      });
    }
  });

  edges?.forEach((edge: GraphEdge) =>
    initialElements.push({
      id: `${edge.parentOperatorName}-${edge.childOperatorName}`,
      source: edge.parentOperatorName,
      target: edge.childOperatorName,
      type: 'straight',
      arrowHeadType: 'arrowclosed',
    }),
  );

  return (
    <ReactFlowProvider>
      <GraphDiagram elements={initialElements} goFullScreen={goFullScreen} />
    </ReactFlowProvider>
  );
};
