import { html, property, query } from 'lit-element';
import {
  register,
  dateToStringConverter,
  event,
  EventEmitter,
  KatLitMobileElement,
} from '../../shared/base';
import { formInputMap } from '../../utils/form-input-map';
import { ifNotNull } from '../../utils/directives';
import baseStyles from '../../shared/base/base.lit.scss';
import { KatDatePicker } from '../date-picker';
import styles from './date-range-picker.lit.scss';
import getString from './strings';

/**
 * @component {kat-date-range-picker} KatalDaterangepicker The date range picker allows users to enter a date range either by typing into the input directly or through the graphical calendar interface. The component is composed of two identical <a href="/components/date-picker/">date pickers</a>: one for the start date and one for the end date.
 * @guideline Do Best used when you want to collect a range of dates
 * @example UsLocale {"locale": "en-US"}
 * @example GermanLocale {"locale": "de-DE"}
 * @example DisabledWeekends {"id": "disabled-weekends", "locale": "en-US", "start-label": "Disabled Weekends",
 * "script": "
 *   var rangePicker = document.querySelector(\"kat-date-range-picker\");
 *   var disableWeekends = function (date) {
 *     return (date.getDay() === 0 || date.getDay() === 6);
 *   };
 *   rangePicker.startPicker.isDateDisabled = disableWeekends;
 *   rangePicker.endPicker.isDateDisabled = disableWeekends;
 * "}
 *  @example ColorCodedDates {"locale": "en-US", "label": "Color coded dates",
 * "script": "
 *   var SCARY_DAY_OF_MONTH = 13;
 *   document.querySelector(\"kat-date-range-picker\").getDateDecorationConfig = function (date) {
 *     if (date.getDate() === SCARY_DAY_OF_MONTH) {
 *         return {style: 'color-code-01', ariaLabel: 'Do not book today.'};
 *     }
 *     return null;
 *   };
 * "}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-date-range-picker')
export class KatDateRangePicker extends KatLitMobileElement {
  /** Sets the start-date value for the date pickers */
  @property({
    type: String,
    attribute: 'start-value',
    converter: dateToStringConverter,
  })
  startValue?: string | Date;

  /** Sets the end-date value for the date pickers */
  @property({
    type: String,
    attribute: 'end-value',
    converter: dateToStringConverter,
  })
  endValue?: string | Date;

  /**
   * The name of the start field. Used when
   * sending form data to a server.
   */
  @property({ attribute: 'start-name' })
  startName?: string;

  /**
   * The name of the end field. Used when
   * sending form data to a server.
   */
  @property({ attribute: 'end-name' })
  endName?: string;

  /**
   * The size of the date picker.
   * @enum {value} large Large date picker - Default
   * @enum {value} small Small date picker
   */
  @property()
  size?: 'large' | 'small' = 'large';

  /** Sets the locale for the date pickers. */
  @property()
  locale = 'en-US';

  /** Sets the label for the end-date pickers. */
  @property({ attribute: 'end-label' })
  endLabel: string;

  /** Sets the label for the start-date picker.s */
  @property({ attribute: 'start-label' })
  startLabel: string;

  /**
   * Defines a function that is called each time a day is rendered.
   * Passes the date the day represents.
   * Returns a configuration object that contains the date rendering style and accessibility content
   * or null if no specific styling is applicable to the date.
   * style attribute must be one of "color-code-01" | "color-code-02" | "color-code-03" | "color-code-04" | "color-code-05" |
   * "color-code-06" | "color-code-07" | "color-code-08" | "color-code-09" | "color-code-10".
   *
   * Each style must be accompanied with a corresponding accessibility blurb that describes the specifics of the date.
   *
   * E.G. {style: 'color-code-01', ariaLabel: 'Bookings today require payment.'}
   */
  @property({ attribute: false })
  getDateDecorationConfig?: (
    date: Date
  ) => { style: string; ariaLabel: string } | null;

  /**
   * Specifies the autocomplete behavior that a browser should attempt:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  @property()
  autocomplete?: string;

  /**
   * @classprop {HTMLElement} startPicker The first date picker element that represents the start date.
   */
  @query('.start')
  readonly startPicker: KatDatePicker;

  /**
   * @classprop {HTMLElement} endPicker The second date picker element that represents the end date.
   */
  @query('.end')
  readonly endPicker: KatDatePicker;

  /** Fires when either date updates. */
  @event('change', true)
  private _change: EventEmitter<{
    startValue: string | Date;
    endValue: string | Date;
    startDate: string;
    endDate: string;
  }>;

  get updateComplete() {
    return super.updateComplete.then(() =>
      Promise.all([
        this.startPicker.updateComplete,
        this.endPicker.updateComplete,
      ])
    );
  }

  static get styles() {
    return [baseStyles, styles];
  }

  _startDateChange(e) {
    e.stopImmediatePropagation();
    this.startValue = e.detail.value;
    this._change.emit(this._getRangeState());
  }

  _endDateChange(e) {
    e.stopImmediatePropagation();
    this.endValue = e.detail.value;
    this._change.emit(this._getRangeState());
  }

  _getRangeState() {
    const start = this.startPicker.dateValue;
    const end = this.endPicker.dateValue;
    return {
      startValue: this.startValue,
      endValue: this.endValue,
      startDate: start ? start.toISOString() : '',
      endDate: end ? end.toISOString() : '',
    };
  }

  @formInputMap([
    {
      tag: 'input',
      name: (component: KatDateRangePicker) => component.startName,
      isNeeded: (component: KatDateRangePicker) => !!component.startName,
      setup: (component: KatDateRangePicker, input: HTMLInputElement) => {
        component.startValue && (input.value = `${component.startValue}`);
      },
    },
    {
      tag: 'input',
      name: (component: KatDateRangePicker) => component.endName,
      isNeeded: (component: KatDateRangePicker) => !!component.endName,
      setup: (component: KatDateRangePicker, input: HTMLInputElement) => {
        component.endValue && (input.value = `${component.endValue}`);
      },
    },
  ])
  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('startValue')) {
      this.startPicker.value = this.startValue;
    }
    if (changedProperties.has('endValue')) {
      this.endPicker.value = this.endValue;
    }
    if (changedProperties.has('getDateDecorationConfig')) {
      this.startPicker.getDateDecorationConfig = this.getDateDecorationConfig;
      this.endPicker.getDateDecorationConfig = this.getDateDecorationConfig;
    }
  }

  render() {
    return html`
      <kat-date-picker
        include-exportparts
        class="start"
        part=${ifNotNull(this.startName)}
        locale=${ifNotNull(this.locale)}
        label=${ifNotNull(this.startLabel)}
        size=${this.size}
        kat-aria-label=${ifNotNull(
          this.startLabel
            ? null // Don't render this attribute if user defined label is passed.
            : getString('kat_date_range_picker_start_date', null, this.locale)
        )}
        getDateDecorationConfig=${this.getDateDecorationConfig}
        autocomplete=${ifNotNull(this.autocomplete)}
        @change=${this._startDateChange}
      ></kat-date-picker>
      <kat-date-picker
        include-exportparts
        class="end"
        part=${ifNotNull(this.endName)}
        locale=${ifNotNull(this.locale)}
        label=${ifNotNull(this.endLabel)}
        size=${this.size}
        kat-aria-label=${ifNotNull(
          this.endLabel
            ? null // Don't render this attribute if user defined label is passed.
            : getString('kat_date_range_picker_end_date', null, this.locale)
        )}
        getDateDecorationConfig=${this.getDateDecorationConfig}
        autocomplete=${ifNotNull(this.autocomplete)}
        @change=${this._endDateChange}
      ></kat-date-picker>
    `;
  }
}
