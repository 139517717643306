import React, { useContext } from 'react';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';
import { BreadcrumbNavigation } from 'src/components/BreadcrumbNavigation/BreadcrumbNavigation';
import { ApiContext } from 'src/context/apiContext';
import { ApplicationDetails } from 'src/components/ApplicationDetails';

export const ApplicationPage = () => {
  const { state } = useContext(ApiContext);
  const currentApplicationId = state.search.curApplicationId;
  const applicationDetails = state.applications.data.find(
    (app) => app['id'] === currentApplicationId,
  );

  return (
    <ContentLayout
      heading={
        <>
          <BreadcrumbNavigation
            links={[
              {
                display: `Account (${state.search.curAccountNo})`,
                href: `/accounts/${state.search.curAccountNo}`,
              },
              { display: applicationDetails?.name },
            ]}
          />
          <h1>{applicationDetails?.name}</h1>
        </>
      }
    >
      <ApplicationDetails />
    </ContentLayout>
  );
};
