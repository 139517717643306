import React from 'react';

import { LinkGroupCell } from 'src/components/table-cells/LinkGroupCell/LinkGroupCell';
import { ApiState } from 'src/context/apiState';

export const ArtifactLinkGroupColumn = ({
  state,
  dispatch,
}: {
  state: ApiState;
  dispatch: React.Dispatch<any>;
}) => ({
  accessor: 'id',
  sortable: false,
  width: 200,

  // eslint-disable-next-line @typescript-eslint/naming-convention
  Cell: (cellInfo: any) => (
    <LinkGroupCell
      links={[
        {
          text: 'Operator Flows',
          href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}/modelArtifacts/${cellInfo.value}/operatorFlowJobs`,
        },
        {
          text: 'Model Metrics',
          href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}/modelArtifacts/${cellInfo.value}/monitoring`,
        },
        {
          text: 'Data Quality Metrics',
          href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}/modelArtifacts/${cellInfo.value}/dataQualityMetrics`,
        },
      ]}
      onClick={() => {
        dispatch({
          type: 'setCurrentModelArtifactId',
          payload: {
            modelArtifactId: cellInfo.value,
          },
        });
      }}
    />
  ),
});
