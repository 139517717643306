import React, { ReactNode, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  filterData,
  getDataByRecency,
  buildCompleteChartData,
  setCursorToPointerOverAnElement,
  setSelectedId,
} from 'src/utils/chartUtils/chartUtils';
import { Line } from 'react-chartjs-2';
import { ChartData } from 'src/context/apiState';
import { TrainingMetricsChartControls } from 'src/components/TrainingMetricsChart/TrainingMetricsChartControls';
import { truncateString } from 'src/utils/generalUtils/generalUtils';
import { KatAlert } from '@amzn/katal-react';
import { Placeholder } from '../Placeholder/Placeholder';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const TrainingMetricsChart = ({
  heading,
  loading,
  data,
  error,
  metricNames,
  setSelectedJobId,
  trainingName,
}: {
  heading?: ReactNode;
  loading: boolean;
  data: MetricsData[] | null;
  error: Error | null;
  metricNames: Set<string> | null;
  setSelectedJobId: (id: string) => void;
  trainingName?: string;
}) => {
  const [range, setRange] = useState<number>(10); // setRange(0) indicates all jobs
  const [selectedMetric, setSelectedMetric] = useState<string>('');
  const [compareMetric, setCompareMetric] = useState<string>('');
  const [chartDataComplete, setChartDataComplete] = useState<ChartData | null>(
    null,
  );
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [],
  });
  const [selectedJob, setSelectedJob] = useState<{
    id: string;
    datasetIndex: number;
  } | null>(null);

  const metricNamesOptions = metricNames
    ? [...metricNames].map((name: string) => ({
        name: truncateString(name, 23), // 23 characters is around the max length for 200px (12.5rem)
        value: name,
      }))
    : undefined;

  // build and set the complete data, runs on mount and only if the data or metricNames list changes
  useEffect(() => {
    if (!metricNames || metricNames.size === 0 || !data) return;
    setSelectedMetric([...metricNames][0]);
    setChartDataComplete(buildCompleteChartData(data, metricNames));
  }, [data, metricNames]);

  // build the visible portion of the data, runs on mount and when controls are used or a point is clicked
  useEffect(() => {
    if (chartDataComplete) {
      setChartData({
        labels: chartDataComplete.labels.slice(range * -1),
        datasets: filterData(
          getDataByRecency(chartDataComplete.datasets, range),
          selectedMetric,
          compareMetric,
          selectedJob,
        ),
      });
    }
  }, [chartDataComplete, range, selectedMetric, compareMetric, selectedJob]);

  return (
    <Placeholder
      ready={!loading}
      shapes={
        <>
          <rect
            y="100"
            height="600px"
            width="70%"
            style={{ margin: '2rem 0' }}
          />
          <rect
            x="800"
            y="100"
            height="600px"
            width="25%"
            style={{ margin: '2rem 0' }}
          />
        </>
      }
    >
      <div className="metrics-graph">
        <div className="metrics-graph__layout">
          <div className="metrics-graph__header">
            {heading}
            <div className="metrics-graph__controls">
              <TrainingMetricsChartControls
                firstMetricName={[...(metricNames || [])]?.[0]}
                metricNamesOptions={metricNamesOptions}
                setSelectedMetric={setSelectedMetric}
                setCompareMetric={setCompareMetric}
                setRange={setRange}
                trainingName={trainingName}
              />
            </div>
          </div>
          {error && (
            <div className="chart-grid__alert">
              <KatAlert variant="danger" description={error.message} />
            </div>
          )}
          {!loading && !error && chartData?.labels.length === 0 && (
            <div className="chart-grid__alert">
              <KatAlert description="No Training Metrics Available" />
            </div>
          )}
          <div className="metrics-graph__visualization chart-container">
            <Line
              id="training-metrics-canvas"
              options={{
                onHover: setCursorToPointerOverAnElement,
                onClick: setSelectedId(
                  chartData,
                  setSelectedJobId,
                  setSelectedJob,
                ),
                // @ts-ignore
                animations: false,
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                  tooltip: { enabled: false },
                  legend: {
                    position: 'bottom',
                  },
                  title: {
                    display: false,
                  },
                },
              }}
              data={
                chartData || {
                  labels: [undefined],
                  datasets: [{ data: [undefined] }],
                }
              }
            />
          </div>
        </div>
      </div>
    </Placeholder>
  );
};
