import { useContext, useState } from 'react';
import { urlList } from 'src/context/url';
import { UserContext } from 'src/context/userContext';

export interface RedeployConfig {
  operatorName: string;
  rollbackOperatorFlowId: string;
  regions?: string[];
  selectedRegion?: string;
}

export interface RedeployRequestResponse {
  operatorName: string;
  rolledBackOperatorFlowJobId: string;
  rolledBackRegion: string;
  rolledBackDeploymentJobId: string;
  newOperatorFlowJobId: string;
}

export const useModelRedeployment = (): [
  boolean,
  (
    redeployConfig: RedeployConfig,
  ) => Promise<{ success: boolean; error: Error | null }>,
] => {
  const [loading, setLoading] = useState<boolean>(false);
  const user = useContext(UserContext);

  const requestRedeployment = async ({
    operatorName,
    rollbackOperatorFlowId,
    selectedRegion,
  }: RedeployConfig) => {
    setLoading(true);

    try {
      const response = await fetch(urlList.triggerDeploymentRollback, {
        method: 'POST',
        headers: {
          Authorization: user.token,
        },
        body: JSON.stringify({
          operatorName,
          rollbackOperatorFlowJobId: rollbackOperatorFlowId,
          rollbackRegion: selectedRegion,
        }),
      });

      if (!response.ok) {
        const body = await response?.json();
        throw new Error(body.message || response.statusText);
      }

      return { success: true, error: null };
    } catch (e) {
      return { success: false, error: e as Error };
    } finally {
      setLoading(false);
    }
  };

  return [loading, requestRedeployment];
};
