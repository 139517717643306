import React, { createContext, useReducer } from 'react';
import { urlList } from 'src/context/url';
import { trackPromise } from 'react-promise-tracker';
import { initialState } from './initialState';
import { ApiState } from './apiState';
import { reducer } from './reducer';

export const ApiContext = createContext<{
  state: ApiState;
  dispatch: React.Dispatch<any>;
}>({} as any);

export const ApiContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState.apiData);

  return (
    <ApiContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export function fetchData(
  user: any,
  type: any,
  state: any = initialState.apiData,
  signal: any = null,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve: any, reject: any) => {
    trackPromise(
      fetch(getBackendUrl(user, type, state), getOptions(user, signal))
        .then((res: Response) => {
          if (!res.ok) {
            throw new Error('HTTP Error: ' + res.statusText);
          }
          return res.json();
        })
        .then((res) => {
          resolve({ data: res, error: '' });
        })
        //TODO: Add retry logic if query times-out
        .catch((err: Error) => {
          resolve({ data: [], error: err });
        }),
    );
  });
}

function getOptions(user: any, signal: any = null) {
  return {
    signal,
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    headers: {
      Authorization: user.token,
      'Content-type': 'application/json',
    },
  };
}

function getBackendUrl(user: any, type: string, state: any) {
  switch (type) {
    case 'getAccounts':
      return urlList.accounts;
    case 'getOnboardableAccounts':
      return urlList.onboardableAccounts;
    case 'getApplicationsByAccount':
      return (
        urlList.applications +
        '?' +
        new URLSearchParams({ awsAccountId: state.search.curAccountNo })
      );
    case 'getModelVersionsByApplication':
      return (
        urlList.modelVersions +
        '?' +
        new URLSearchParams({ applicationId: state.search.curApplicationId })
      );
    case 'getModelArtifactsByModelVersion':
      return (
        urlList.modelArtifacts +
        '?' +
        new URLSearchParams({ modelVersionId: state.search.curModelVersionId })
      );
    case 'getTrainingJobsByModelArtifact':
      return (
        urlList.trainingJobs +
        '?' +
        new URLSearchParams({
          modelArtifactId: state.search.curModelArtifactId,
        })
      );
    case 'getOperatorFlowJobsByModelArtifactId':
      return (
        urlList.operatorFlowJobs +
        '?' +
        new URLSearchParams({
          modelArtifactId: state.search.curModelArtifactId,
          startTime: state.search.curOperatorFlowJobStartTime,
          endTime: state.search.curOperatorFlowJobEndTime,
        })
      );
    case 'getPlatformMetrics':
      return urlList.embeddedDashboard;
    case 'getAccountMetrics':
      return (
        urlList.embeddedDashboard +
        '?' +
        new URLSearchParams({
          awsAccountId: state.search.curAccountNo,
        })
      );
    default:
      console.warn('Unknown api: ' + type);
      return '';
  }
}
