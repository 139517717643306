import React from 'react';
import { KatIcon, KatLink, KatList } from '@amzn/katal-react';
import slackLogo from '../../public/slack.jpg';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { Link } from 'src/components/Link';

interface CommunitySectionProps {
  actionMetricsPublisher: KatalMetricsPublisher;
}

export const CommunitySection = ({
  actionMetricsPublisher,
}: CommunitySectionProps) => {
  return (
    <section className="community-section">
      <div className="community-section__container">
        <div className="community-section__heading">
          <h2>Community</h2>
        </div>
        <div className="community-section__wrapper">
          <div className="community-section__content">
            <h3>Model Registry</h3>
            <p>
              Share your trained or untrained model with the rest of Amazon.
              Consume shared models from{' '}
              <strong>MLPigeon Model Registry</strong> to optimize your
              workflow.
            </p>
            <div className="community-section__list">
              <KatList variant="bullet">
                <li>
                  Model sharing and consuming shared models via MLPigeon
                  workflow
                </li>
                <li>Browse all company-wide shared models</li>
                <li>
                  Model sharing and consumption with{' '}
                  <strong>controlled access</strong> (coming soon)
                </li>
              </KatList>
            </div>

            <Link
              to={'/modelRegistry'}
              onClick={() => {
                actionMetricsPublisher?.publishCounterMonitor(
                  'Model Registry',
                  1,
                );
              }}
            >
              Browse Models
            </Link>
          </div>
          <div className="community-section__content">
            <h3>Join Us</h3>
            <div className="community-section__slack">
              <KatLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://amazon.enterprise.slack.com/archives/C01BPFC6JE6"
              >
                <img src={slackLogo} alt="slack" />
                #mlpigeon-support
                <KatIcon name="launch" size="tiny" />
              </KatLink>
            </div>
            <p>
              Please{' '}
              <KatLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://email-list.corp.amazon.com/email-list/expand-list/mlpigeon-newsletter"
              >
                subscribe
                <KatIcon name="launch" size="tiny" />
              </KatLink>{' '}
              to our <strong>newsletter</strong>! We send out a recap of changes
              and added features{' '}
              <KatLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://w.amazon.com/bin/MLPigeon/MonthlyNewsletterHistory"
              >
                periodically
                <KatIcon name="launch" size="tiny" />
              </KatLink>
              .
            </p>
            <p>
              As of June 2024, over <strong>1000</strong> AWS accounts (Across{' '}
              <strong>420</strong>+ Two-Pizza teams and <strong>150</strong>+ VP
              Organizations) have onboarded to MLPigeon.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
