import { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext, fetchData } from 'src/context/apiContext';
import { UserContext } from 'src/context/userContext';
import { updateAccountLocalStorage } from 'src/utils/generalUtils/generalUtils';
import {
  Account,
  useGetAccounts,
} from 'src/hooks/useGetAccounts/useGetAccounts';
import { useSetAccount } from 'src/hooks/useSetAccount/useSetAccount';

export interface Params {
  accountNo?: string;
  applicationId?: string;
  modelVersionId?: string;
  modelVersionEventId?: string;
  modelArtifactId?: string;
  trainingJobId?: string;
  operatorFlowId?: string;
}

export function Routing() {
  const { state, dispatch } = useContext(ApiContext);
  const setAccount = useSetAccount();
  const user = useContext(UserContext);
  const {
    accountNo,
    applicationId,
    modelVersionId,
    modelVersionEventId,
    modelArtifactId,
    operatorFlowId,
  } = useParams();

  const prevAccountId: any = useRef();
  const prevApplicationId: any = useRef();
  const prevModelVersionId: any = useRef();
  const prevModelArtifactId: any = useRef();

  const [accountsLoading, accountsError, accountsData, getAccounts] =
    useGetAccounts();

  function setCurrentProperty(dispatchType1: string, payload: string | Params) {
    dispatch({
      type: dispatchType1,
      payload,
    });
  }

  function setCurrentValues(fetchType: string, dispatchType2: string) {
    fetchData(user, fetchType, state).then((data: any) => {
      dispatch({
        type: dispatchType2,
        payload: data,
      });
    });
  }

  // runs on initial mount, dispatching state updates based on the url params
  // if the account number is different, check the other params. If they are in the url, set them in state too
  useEffect(() => {
    //account number
    if (accountNo && state.search.curAccountNo !== accountNo) {
      setCurrentProperty('setCurrentAccountNo', accountNo);

      //application ID
      if (applicationId) {
        setCurrentProperty('setCurrentApplicationId', applicationId);
      }

      //modelVersion ID
      if (modelVersionId) {
        setCurrentProperty('setCurrentModelVersionId', modelVersionId);
      }

      //modelVersionEvent ID
      if (modelVersionEventId) {
        setCurrentProperty(
          'setCurrentModelVersionEventId',
          modelVersionEventId,
        );
      }

      //modelArtifact ID
      if (modelArtifactId) {
        setCurrentProperty('setCurrentModelArtifactId', {
          modelArtifactId,
        });
      }

      //operator flow job ID
      if (operatorFlowId) {
        setCurrentProperty('setCurrentOperatorFlowJobId', operatorFlowId);
      }
    }
  }, []);

  // since the fetchData function relies on data being set in state, these fetches must be run after the data is set
  // for example, the fetch for application data (dispatch updateApplicationsData) can only be done once the
  // curAccountNo state (dispatch 'setCurrentAccountNo') is done. Using refs, this useEffect is able to tell if
  // the component has been mounted or not, effectively making these fetches on rerender
  // (once the state is set from the useEffect above)
  useEffect(() => {
    if (
      state.search.curAccountNo &&
      state.search.curAccountNo !== prevAccountId.current
    ) {
      setCurrentValues('getApplicationsByAccount', 'updateApplicationsData');
    }
    if (
      state.search.curApplicationId &&
      state.search.curApplicationId !== prevApplicationId.current
    ) {
      setCurrentValues(
        'getModelVersionsByApplication',
        'updateModelVersionsData',
      );
    }
    if (
      state.search.curModelVersionId &&
      state.search.curModelVersionId !== prevModelVersionId.current
    ) {
      setCurrentValues(
        'getModelArtifactsByModelVersion',
        'updateModelArtifactsData',
      );
    }
    if (
      state.search.curModelArtifactId &&
      state.search.curOperatorFlowJobStartTime &&
      state.search.curOperatorFlowJobEndTime
    ) {
      setCurrentValues(
        'getOperatorFlowJobsByModelArtifactId',
        'updateOperatorFlowJobData',
      );
    }
  }, [state.search]);

  // this runs on every render, updating the ref so that the previous useEffect can tell a render has happened
  useEffect(() => {
    prevAccountId.current = state.search.curAccountNo;
    prevApplicationId.current = state.search.curApplicationId;
    prevModelVersionId.current = state.search.curModelVersionId;
    prevModelArtifactId.current = state.search.curModelArtifactId;
  });

  // this runs whenever state's account number is changed and decides whether we need to lookup the account name. It clears
  // out the saved account and writes the correct account
  useEffect(() => {
    // return if we haven't updated state from the url and theres a url accountNo param (meaning we will set it)
    if (!state.search.curAccountNo && accountNo) return;

    const localAccountString = window.localStorage.getItem('selectedAccount');
    const localAccount: Account = localAccountString
      ? JSON.parse(localAccountString)
      : null;

    // if there's an account in local storage, but not a url param, use the stored one
    if (localAccount && !accountNo) {
      setAccount(localAccount);
      return;
    }

    // if we have the account we want in local storage, no need to fetch or update
    if (
      state.search.curAccountNo &&
      state.search.curAccountNo === localAccount?.id
    )
      return;

    // since we want a different account, remove the previous as selected, new selected and recent accounts get updated after
    // the getAccounts fetch
    window.localStorage.removeItem('selectedAccount');

    if (accountsError) {
      console.error(`Routing Error: ${accountsError.message}`);
      return;
    }

    // if we're not loading and there's no data yet, fetch
    if (!accountsLoading && !accountsData) {
      getAccounts();
      return;
    }

    // done loading and have data, then update local storage
    const account = accountsData?.find(
      (account) => account.id === state.search.curAccountNo,
    );
    account && updateAccountLocalStorage(account);
  }, [state.search.curAccountNo, accountsLoading]);
  return null;
}
