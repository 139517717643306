import React, { ReactElement } from 'react';
import { KatSpinner } from '@amzn/katal-react';
import cx from 'classnames';

export const JSONViewer = ({
  jsonKey,
  loading,
  jsonData,
  error,
  spacing = 2,
  expanded,
}: {
  jsonKey?: string;
  loading: boolean;
  jsonData?: Record<string, any> | null;
  error?: string | null;
  spacing?: number;
  expanded?: boolean;
}): ReactElement => {
  if (loading)
    return (
      <div className="json-viewer__container">
        <KatSpinner size="small" />
      </div>
    );
  if (error)
    return (
      <div className="json-viewer__container json-viewer__error">{error}</div>
    );
  if (!jsonData)
    return <div className="json-viewer__container">No data available.</div>;

  return (
    <div
      className={cx('json-viewer__container', {
        'json-viewer__container--expanded': expanded,
      })}
    >
      <div>
        <pre className="json-viewer__pre">
          {jsonKey
            ? JSON.stringify(jsonData[jsonKey] || {}, null, spacing)
            : JSON.stringify(jsonData, null, spacing)}
        </pre>
      </div>
    </div>
  );
};
