import React from 'react';
import sagemakerImage from '../../public/sagemaker.svg';

export const PointsSection = () => (
  <section className="points-section">
    <div className="points-section__container">
      <div className="points-section__wrapper">
        <div>
          <p className="sub-heading">
            MLPigeon is a <strong>hosted</strong>, <strong>event-driven</strong>
            , <strong>red-certified</strong> service, built on top of{' '}
            <strong>Amazon SageMaker</strong>.
          </p>
          <img src={sagemakerImage} alt="Amazon SageMaker" />
        </div>
        <div>
          <p>
            Workflows can enable use-cases, such as automatic retraining and
            deployment, beta testing, data processing, and many more.
          </p>
          <p>
            You maintain ownership of your AWS account and SageMaker jobs.
            Access is granted via IAM roles so that MLPigeon can orchestrate
            SageMaker API calls on your behalf while your data is stored
            securely in your AWS account.
          </p>
        </div>
      </div>
    </div>
  </section>
);
