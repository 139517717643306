import React from 'react';
import { Placeholder } from 'src/components/Placeholder/Placeholder';

interface Props {
  loading: boolean;
  label?: string | null;
}
export const StatusLabel = ({ loading, label }: Props) => {
  return (
    <div className="status-label">
      <Placeholder
        ready={!loading}
        shapes={<rect y="0" width="90" height="25" />}
      >
        <span
          className={`status-label__content status-label__content--${label?.toLowerCase()}`}
        >
          {label}
        </span>
      </Placeholder>
    </div>
  );
};
