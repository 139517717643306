import React from 'react';
import { KatIcon } from '@amzn/katal-react';

interface Props {
  column: {
    filterValue: string;
    setFilter: (filterValue: string) => void;
    id: string;
    accessor: () => string;
  };
  data: Record<string, string>[];
  rows: [{ original: Record<string, string> }];
}

export const ColumnSetFilter = (info: Props) => {
  const optionsSet = info.data.reduce<Set<string>>((prev, curr) => {
    prev.add(curr[info.column.id]);
    return prev;
  }, new Set<string>());

  // build a set, render a select
  // on change, set the filter to the value selected
  return (
    <div className="data-table__filter-container">
      <select
        className="data-table__filter-select"
        onChange={(e) => {
          info.column.setFilter(e.target.value);
        }}
        placeholder="Select a filter"
        value={info.column.filterValue}
      >
        <option value="">Select a filter</option>
        {[...optionsSet].map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <button
        className="data-table__filter-button"
        onClick={() => {
          info.column.setFilter('');
        }}
      >
        <KatIcon size="small" name="clear" />
      </button>
    </div>
  );
};
