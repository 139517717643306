import { property, query } from 'lit-element';
import { html } from 'lit-html';
import {
  KatLitMobileElement,
  register,
  nextUniqueId,
  event,
  EventEmitter,
} from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import { ifNotNull } from '../../utils/directives';
import styles from './tabs-overflow.lit.scss';
import { TabStrings } from './strings';

/**
 * @component {kat-tab-overflow} KatTabOverflow A tab element used to display overflow kat-tab elements.
 */
@register('kat-tab-overflow')
export class KatTabOverflow extends KatLitMobileElement {
  /** The ID of the tab. Should be unique among the set of tabs. The `selected` attribute of the parent `kat-tabs` element will update to this value when this tab is selected. */
  @property({ attribute: 'tab-id' })
  tabId: string;

  /** The contents of the tab's label. */
  @property()
  label?: string;

  /** An additional label to mark a tab with special significance. */
  @property()
  status?: string;

  /** A read-only attribute, whether this tab is selected. To programmatically set the selected tab, use the parent kat-tabs' `selected` property instead. */
  @property()
  selected?: boolean;

  /** whether overflow is visible. */
  @property({ attribute: `${TabStrings.IsOverflowVisible}` })
  isOverflowVisible?: boolean = false;

  /** What tab in the dropdown is selected. */
  @property({ attribute: `${TabStrings.OverflowSelected}` })
  overflowSelected?: number = -1;

  preventNextClose = false;

  @event('select', {
    bubbles: true,
    cancelable: false,
  })
  private _select: EventEmitter<{ id: string }>;

  /** @private */
  @event('_overflowBlur', true)
  private _overflowBlur: EventEmitter<{ relatedTarget: string | null }>;

  static get styles() {
    return [baseStyles, styles];
  }

  _uniqueId = nextUniqueId();

  @query('.content')
  private _content: HTMLElement;

  constructor() {
    super();
    this._listeners = [
      {
        target: window,
        listeners: [['resize', this._positionOverflow.bind(this)]],
      },
    ];

    this.expandedPropertyName = 'isOverflowVisible';
  }

  firstUpdated() {
    super.firstUpdated();
    this._positionOverflow();
  }

  _positionOverflow() {
    const dropdownWidth = 150;

    this._content.style.left = `${
      this.getBoundingClientRect().left - dropdownWidth
    }px`;
  }

  focusContent() {
    this._content.focus();
  }

  _handleBlur(e) {
    if (!this.preventNextClose) {
      this.preventNextClose = false;
      // Ensure the overflow is in the correct state
      this._overflowBlur.emit({ relatedTarget: e.relatedTarget });
    }
  }

  // Only called an overflow child tab is selected and another overflow child is clicked, or arrowing away from a selected overflow child tab
  _overflowChildBlur(e) {
    // Fire overflow blur to prevent non overflow children from affecting state with blur
    this._overflowBlur.emit({ relatedTarget: e.detail.relatedTarget });
  }

  _focus() {
    // Mousedown fires before focus so this will only execute when tabbing
    if (this.isOverflowVisible === false && this.preventNextClose === false) {
      this.isOverflowVisible = true;
    }

    // Needed for when someone clicks on the overflow tab twice, then clicks Enter and then clicks on anything other than a primary tab
    this.preventNextClose = false;
  }

  _keydown(e) {
    if (e.key === 'Enter' && this.isOverflowVisible === true) {
      this.isOverflowVisible = false;
      return;
    }

    if (e.key === 'Enter' && this.isOverflowVisible === false) {
      this.isOverflowVisible = true;
    }
  }

  _focusOut() {
    // Needed for when someone clicks on overflowTab twice, reverse tabs and then tabs forward
    this.preventNextClose = false;
  }

  _mousedown() {
    if (!this.isOverflowVisible) {
      return (this.isOverflowVisible = true);
    }

    this.isOverflowVisible = false;
    // Handles when a user has tabbed into the overflow then clicks the overflow tab
    this.preventNextClose = true;
  }

  render() {
    return html`
          <div
            id=${this._uniqueId}
            class="label overflow-root"
            role="tab"
            aria-selected=${!!this.selected}
            tabindex=${ifNotNull(0)}
            @blur=${this._handleBlur}
            @focus=${this._focus}
            @focusout=${this._focusOut}
            @keydown=${this._keydown}
            @mousedown=${this._mousedown}
          >
            <div class="label__inner">
              <kat-icon name="vertical-dots" size="tiny" class="label__inner__dots">
              <div class="status">
                <slot name="status">${this.status}</slot>
              </div>
            </div>
            <span class="ring"></span>
          </div>

        <div part=${super.getPartMask(this.isOverflowVisible)}>
          <div part=${super.getPartWrapper(this.isOverflowVisible)}>
            <div
              class="content"
              role="tabpanel"
              aria-labelledby=${this._uniqueId}
              @_overflowChildBlur=${this._overflowChildBlur}
              part=${super.getPartContainer(this.isOverflowVisible)}
            >
              <div part=${super.getPartContent(this.isOverflowVisible)}>
                <slot></slot>
              </div>
            </div>
          </div>
        </div>
    `;
  }
}
