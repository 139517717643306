import React, { ReactNode } from 'react';
import cx from 'classnames';
import { KatButton, KatIcon } from '@amzn/katal-react';

export const SlideOutPanel = ({
  isOpen,
  children,
  closePanel,
}: {
  isOpen?: boolean;
  children: ReactNode;
  closePanel: () => void;
}) => (
  <>
    <div className={cx('slide-out-panel', { 'slide-out-panel--open': isOpen })}>
      <div>
        <KatButton
          disabled={!isOpen}
          className="slide-out-panel__close-button"
          onClick={() => {
            closePanel();
          }}
        >
          <KatIcon name="close" size="small" />
        </KatButton>
      </div>
      <div className="slide-out-panel__contents">{children}</div>
    </div>
  </>
);
