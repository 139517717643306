import React, { useContext } from 'react';
import { BreadcrumbNavigation } from 'src/components/BreadcrumbNavigation/BreadcrumbNavigation';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';
import { DataQualityMetricsList } from 'src/components/DataQualityMetricsList/DataQualityMetricsList';
import { ApiContext } from 'src/context/apiContext';

export const DataQualityMetricsPage = () => {
  const { state } = useContext(ApiContext);

  const modelArtifactId = state.search.curModelArtifactId;

  const currentApplicationId = state.search.curApplicationId;
  const applicationDetails = state.applications.data.find(
    (app) => app['id'] === currentApplicationId,
  );
  const currentModelVersionId = state.search.curModelVersionId;
  const modelVersionDetails = state.modelVersions.data.find(
    (mv) => mv['id'] === currentModelVersionId,
  );

  return (
    <ContentLayout
      heading={
        <>
          <BreadcrumbNavigation
            links={[
              {
                display: `Account (${state.search.curAccountNo})`,
                href: `/accounts/${state.search.curAccountNo}`,
              },
              {
                display: applicationDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}`,
              },
              {
                display: modelVersionDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}`,
              },
              {
                display: `Data Quality Metrics (${modelArtifactId})`,
              },
            ]}
          />
          <h1>Data Quality Metrics</h1>
        </>
      }
    >
      <DataQualityMetricsList />
    </ContentLayout>
  );
};
