import React from 'react';
import { ContentLayout } from 'src/components/ContentLayout/ContentLayout';
import { Link } from 'react-router-dom';
import { KatLink } from '@amzn/katal-react';

export const FileNotFoundPage = () => (
  <ContentLayout
    heading={<h1>Page Not Found</h1>}
    description="No page matching this url was found. Please click the link below to return to the account homepage."
  >
    <Link to="/">
      <KatLink>Return to the homepage</KatLink>
    </Link>
  </ContentLayout>
);
