import React from 'react';
import { KatList } from '@amzn/katal-react';
import workflowImage from '../../public/flow-illustration.svg';
import { Link } from 'src/components/Link';

export const WorkflowSection = () => (
  <section className="workflow-section">
    <div className="workflow-section__container">
      <div className="workflow-section__wrapper">
        <div className="workflow-section__content">
          <div>
            <h2>Manage And Productionize With Workflows</h2>
            <p className="sub-heading">
              Define your workflow in Python with an MLPigeon UserScript
            </p>
          </div>
          <KatList variant="bullet">
            <li>Model Retraining</li>
            <li>Model Deployment & Endpoint Auto-Scaling</li>
            <li>Batch Inference</li>
            <li>Data Processing</li>
            <li>Data Quality Monitoring</li>
            <li>Manual & Automated Approval</li>
            <li>Custom Code</li>
            <li>Hyperparameter Tuning</li>
            <li>Labeling</li>
            <li>SageMaker Pipeline Execution</li>
            <li>Bedrock Model Customization</li>
            <li>Model Sharing and Consuming</li>
          </KatList>
          <div className="button-wrapper">
            <Link
              to="https://code.amazon.com/packages/MLPigeonAppExampleV2/blobs/mainline/--/configuration/mlpigeon_user_script_sample_v2.py"
              external
            >
              See an Example UserScript
            </Link>
            <Link to="https://w.amazon.com/bin/view/MLPigeon/Workflow" external>
              Learn More About Workflows
            </Link>
          </div>
        </div>
        <div className="workflow-section__illustration">
          <img src={workflowImage} alt="workflow" />
        </div>
      </div>
    </div>
  </section>
);
