import { numberOfDaysInMonth } from '../../shared/base/date-utils';

export function daysOfWeekByLocale(locale, format) {
  const todayDay = new Date().getDay();
  const startOfWeek = new Date();
  const days = [];
  startOfWeek.setDate(startOfWeek.getDate() - todayDay);

  for (let i = 0; i < 7; i++) {
    let day = localizeDate(startOfWeek, locale, {
      weekday: format,
      index: i,
    });
    if (format === 'short' && day.indexOf('.') !== -1) {
      day = day.replace('.', '');
    }
    days.push(day);
    startOfWeek.setDate(startOfWeek.getDate() + 1);
  }
  return days;
}

export function monthsOfYearByLocale(locale, format) {
  const todayMonth = new Date().getMonth();
  const startOfYear = new Date();
  const months = [];
  startOfYear.setDate(1);
  startOfYear.setMonth(startOfYear.getMonth() - todayMonth);

  for (let i = 0; i < 12; i++) {
    const month = localizeDate(startOfYear, locale, {
      month: format,
      index: i,
    });

    months.push(month);
    startOfYear.setMonth(startOfYear.getMonth() + 1);
  }
  return months;
}

export function fullDaysOfMonth(dateInMonth: Date, locale) {
  const days = [];
  const date = new Date(dateInMonth);
  const numDays = numberOfDaysInMonth(date);

  for (let i = 0; i < numDays; i++) {
    date.setDate(i + 1);
    days.push(fullDateString(date, locale));
  }

  return days;
}

export function fullDateString(date: Date, locale) {
  return localizeDate(date, locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    key: `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`, // Key used for caching
  });
}

/*
    Simple key-value store for holding localization data for dates
*/
const LOCALIZATION_CACHE = new Map();
function localizeDate(date, locale, options) {
  const key = `${locale}_${JSON.stringify(options)}`;
  let val = LOCALIZATION_CACHE.get(key);
  if (!val) {
    val = date.toLocaleString(locale, options);
    LOCALIZATION_CACHE.set(key, val);
  }
  return val;
}
