import { katTranslator } from '../../shared/i18n';

export const strings = {
  kat_date_range_picker_start_date: {
    'en-US': 'Start date',
    'ar-AE': 'تاريخ البدء',
    'bn-IN': 'শুরুর তারিখ',
    'de-DE': 'Startdatum',
    'es-ES': 'Fecha de inicio',
    'es-MX': 'Fecha de inicio',
    'fr-BE': 'Date de début',
    'fr-FR': 'Date de début',
    'gu-IN': 'પ્રારંભ તારીખ',
    'hi-IN': 'शुरू होने की तारीख',
    'it-IT': 'Data di inizio',
    'ja-JP': '開始日',
    'kn-IN': 'ಪ್ರಾರಂಭ ದಿನಾಂಕ',
    'ko-KR': '시작 날짜',
    'ml-IN': 'ആരംഭിക്കുന്ന തീയതി',
    'mr-IN': 'प्रारंभ दिनांक',
    'nl-BE': 'Startdatum',
    'nl-NL': 'Startdatum',
    'pl-PL': 'Data początkowa',
    'pt-BR': 'Data de início',
    'pt-PT': 'Data de início',
    'sv-SE': 'Startdatum',
    'ta-IN': 'தொடக்கத் தேதி',
    'te-IN': 'ప్రారంభ తేదీ',
    'th-TH': 'วันที่เริ่มต้น',
    'tr-TR': 'Başlangıç tarihi',
    'vi-VN': 'Ngày bắt đầu',
    'zh-CN': '开始日期',
    'zh-TW': '開始日期',
  },
  kat_date_range_picker_end_date: {
    'en-US': 'End date',
    'ar-AE': 'تاريخ الانتهاء',
    'bn-IN': 'শেষের তারিখ',
    'de-DE': 'Enddatum',
    'es-ES': 'Fecha de finalización',
    'es-MX': 'Fecha de finalización',
    'fr-BE': 'Date de fin',
    'fr-FR': 'Date de fin',
    'gu-IN': 'પૂર્ણાહૂતિની તારીખ',
    'hi-IN': 'खत्म होने की तारीख',
    'it-IT': 'Data di fine',
    'ja-JP': '終了日',
    'kn-IN': 'ಅಂತಿಮ ದಿನಾಂಕ',
    'ko-KR': '종료 날짜',
    'ml-IN': 'അവസാനിക്കുന്ന തീയതി',
    'mr-IN': 'अंतिम तारीख',
    'nl-BE': 'Einddatum',
    'nl-NL': 'Einddatum',
    'pl-PL': 'Data końcowa',
    'pt-BR': 'Data de término',
    'pt-PT': 'Data de fim',
    'sv-SE': 'Slutdatum',
    'ta-IN': 'முடிவுத் தேதி',
    'te-IN': 'ముగింపు తేదీ',
    'th-TH': 'วันที่สิ้นสุด',
    'tr-TR': 'Bitiş tarihi',
    'vi-VN': 'Ngày kết thúc',
    'zh-CN': '结束日期',
    'zh-TW': '結束日期',
  },
};

export default katTranslator(strings);
