import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import SiteContainer from 'src/components/SiteContainer/SiteContainer';
import Header from 'src/components/SiteHeader/SiteHeader';
import {
  LazyAccountSettingsPage,
  LazyCLIOnboardingPage,
  LazyDashboardMetricsPage,
  LazyManualDeploymentPage,
  LazyNewTopicSubscriptionPage,
  LazyOnboardingPage,
  LazyOperatorHistoryPage,
  LazyTopicSubscriptionPage,
  LazyUserScriptGenerator,
  LazyModelRegistryHomePage,
  LazyModelRegistryModelPage,
  LazyModelVariationPage,
  LazyReserveRegistryNamePage,
  LazyEditModelDescriptionPage,
  LazyEditReleaseNotesPage,
  LazyLogViewPage,
  LazyUnauthorizedPage,
} from 'src/lazyRoutes';
import { Home } from 'src/views/Home/Home';
import { AccountPage } from 'src/views/AccountPage/AccountPage';
import { FileNotFoundPage } from 'src/views/FileNotFoundPage/FileNotFoundPage';
import { ApplicationPage } from 'src/views/ApplicationPage/ApplicationPage';
import { ModelVersionPage } from 'src/views/ModelVersionPage/ModelVersionPage';
import { DataQualityMetricsPage } from 'src/views/DataQualityMetricsPage/DataQualityMetricsPage';
import { ModelMetricsPage } from 'src/views/ModelMetricsPage/ModelMetricsPage';
import { OperatorFlowsPage } from 'src/views/OperatorFlowsPage/OperatorFlowsPage';
import { OperatorFlowPage } from 'src/views/OperatorFlowPage/OperatorFlowPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <SiteContainer header={<Header />} />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'accounts/:accountNo',
        element: <AccountPage />,
      },
      {
        path: 'accounts/:accountNo/accountSettings',
        element: <LazyAccountSettingsPage />,
      },
      {
        path: 'accounts/:accountNo/topics',
        element: <LazyTopicSubscriptionPage />,
      },
      {
        path: 'accounts/:accountNo/topics/new',
        element: <LazyNewTopicSubscriptionPage />,
      },
      {
        path: 'accounts/:accountNo/accountMetrics',
        element: <LazyDashboardMetricsPage isAccountMetrics />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId',
        element: <ApplicationPage />,
      },
      {
        path: 'accounts/:accountNo/reserveRegistryName',
        element: <LazyReserveRegistryNamePage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId',
        element: <ModelVersionPage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/dataQualityMetrics',
        element: <DataQualityMetricsPage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/trainingJobs/:trainingJobId/dataQualityMetrics',
        element: <DataQualityMetricsPage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/monitoringSchedules/:monitoringScheduleId/dataQualityMetrics',
        element: <DataQualityMetricsPage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/monitoring',
        element: <ModelMetricsPage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/operatorFlowJobs',
        element: <OperatorFlowsPage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/operatorFlowJobs/:operatorFlowId/visualization',
        element: <OperatorFlowPage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/operatorFlowJobs/:operatorFlowId/logs',
        element: <LazyLogViewPage />,
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/operatorFlowJobs/:operatorFlowId/visualization/operatorHistory',
        element: <LazyOperatorHistoryPage />,
      },
      {
        path: 'accountSetup',
        element: <LazyOnboardingPage />,
      },
      {
        path: 'userscript',
        element: <LazyUserScriptGenerator />,
      },
      {
        path: 'clionboardingtoken',
        element: <LazyCLIOnboardingPage />,
      },
      {
        path: 'manualdeployment',
        element: <LazyManualDeploymentPage />,
      },
      {
        path: 'platformMetrics',
        element: <LazyDashboardMetricsPage isAccountMetrics={false} />,
      },
      {
        path: 'modelRegistry',
        element: <LazyModelRegistryHomePage />,
      },
      {
        path: 'modelRegistry/model/:modelRegistryId',
        element: <LazyModelRegistryModelPage />,
      },
      {
        path: 'modelRegistry/model/:modelRegistryId/variation/:variationId',
        element: <LazyModelVariationPage />,
      },
      {
        path: 'modelRegistry/model/:modelRegistryId/variation/:variationId/edit',
        element: <LazyEditModelDescriptionPage />,
      },
      {
        path: 'modelRegistry/model/:modelRegistryId/variation/:variationId/version/:variationVersionId/edit-release-notes',
        element: <LazyEditReleaseNotesPage />,
      },
      {
        path: 'unauthorized',
        element: <LazyUnauthorizedPage />,
      },
      {
        path: '*',
        element: <FileNotFoundPage />,
      },
    ],
  },
]);
