import React, { useState } from 'react';
import { KatButton, KatModal, KatTextarea } from '@amzn/katal-react';
import {
  ApprovalConfig,
  useApprovalDecision,
} from 'src/hooks/useApprovalDecision/useApprovalDecision';

export const ApprovalDecisionModal = ({
  approvalConfig,
  setApprovalConfig,
}: {
  approvalConfig: ApprovalConfig | null;
  setApprovalConfig: (arg0: ApprovalConfig | null) => void;
}) => {
  const [approvalLoading, sendApprovalDecision] = useApprovalDecision();
  const [statusText, setStatusText] = useState<string>('');
  return (
    <KatModal
      title="Approval Operator"
      visible={approvalConfig !== null}
      onClose={() => {
        setApprovalConfig(null);
      }}
      footer={
        <div className="approval-modal__controls">
          <KatButton
            variant="link"
            onClick={() => {
              setApprovalConfig(null);
            }}
          >
            Cancel
          </KatButton>
          <div className="approval-model__actions">
            <KatButton
              variant="secondary"
              loading={approvalLoading}
              onClick={() => {
                if (approvalConfig) {
                  approvalConfig.decision = 'FAILED';
                  approvalConfig.statusMessage = statusText;
                  sendApprovalDecision(approvalConfig, () => {
                    setApprovalConfig(null);
                  });
                }
              }}
            >
              Reject
            </KatButton>
            <KatButton
              variant="primary"
              loading={approvalLoading}
              onClick={() => {
                if (approvalConfig) {
                  approvalConfig.decision = 'SUCCEEDED';
                  approvalConfig.statusMessage = statusText;
                  sendApprovalDecision(approvalConfig, () => {
                    setApprovalConfig(null);
                  });
                }
              }}
            >
              Approve
            </KatButton>
          </div>
        </div>
      }
    >
      <div className="approval-modal__contents">
        <p>
          This will either approve or reject the operator:{' '}
          <strong>{approvalConfig?.operatorName}</strong>. The status message
          will be logged in either case.
        </p>
        <KatTextarea
          placeholder="Enter text to be submitted with your decision."
          label="Status Message (optional)"
          value={statusText}
          onChange={(e) => {
            setStatusText((e.target as HTMLTextAreaElement)?.value);
          }}
        />
      </div>
    </KatModal>
  );
};
