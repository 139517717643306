import React, { useEffect, useState } from 'react';
import { KatButton, KatInput, KatModal } from '@amzn/katal-react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import {
  createModelVersion,
  deleteModelVersion,
  editModelVersion,
} from 'src/hooks/useModelVersions';
import { useNavigate } from 'react-router-dom';

export const ModelVersionModal = ({
  applicationId,
  modalMode,
  values,
  onClose,
  visible,
  redirectLocation,
}: {
  applicationId: string;
  modalMode: 'New' | 'Edit' | 'Delete';
  values?: {
    id?: string;
    name: string;
    description?: string;
    version?: number;
  };
  onClose: () => void;
  visible: boolean;
  redirectLocation?: string;
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const ModelVersionValidationSchema = yup.object().shape({
    name: yup.string().required('Please specify a model version name.'),
    description: yup.string(),
  });

  const initialValues = {
    name: values?.name || '',
    description: values?.description || '',
  };

  useEffect(() => {
    formik.setValues({
      name: values?.name || '',
      description: values?.description || '',
    });
    formik.setTouched({});
  }, [values]);

  const [error, setError] = useState<Error | null>(null);

  const formik = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      let response;
      // check the mode, call the correct api
      setLoading(true);
      switch (modalMode) {
        case 'New':
          response = await createModelVersion(
            {
              applicationId,
              name: formValues.name,
              description: formValues.description,
            },
            queryClient,
          )
            .catch((e) => {
              setError(e);
              return e.message;
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case 'Edit':
          if (!values?.id) return;
          response = await editModelVersion(
            {
              id: values?.id,
              applicationId,
              name: formValues.name,
              description: formValues.description,
              version: (values?.version || 1) + 1,
            },
            queryClient,
          )
            .catch((e) => {
              setError(e);
              return e.message;
            })
            .finally(() => {
              setLoading(false);
            });
          break;
      }

      if (!response) onClose();
    },
    validationSchema: ModelVersionValidationSchema,
  });

  const handleDeleteConfirm = async () => {
    if (!values?.id) return;
    setLoading(true);
    const response = await deleteModelVersion(
      {
        id: values?.id,
        applicationId,
      },
      queryClient,
    )
      .catch((e) => {
        setError(e);
        return e.message;
      })
      .finally(() => {
        setLoading(false);
        if (redirectLocation) {
          navigate(redirectLocation);
        }
      });

    if (!response) onClose();
  };

  return (
    <KatModal
      visible={visible}
      onClose={onClose}
      title={`${modalMode} Model Version`}
      footer={
        <div className="modal-footer">
          {error && <p className="error-text">{error.message}</p>}
          <KatButton variant="link" onClick={onClose}>
            Cancel
          </KatButton>
          <KatButton
            loading={loading}
            variant={modalMode === 'Delete' ? 'danger' : 'primary'}
            onClick={() => {
              modalMode === 'Delete'
                ? handleDeleteConfirm()
                : formik.handleSubmit();
            }}
          >
            {modalMode === 'Delete' ? 'Delete Model Version' : 'Submit'}
          </KatButton>
        </div>
      }
    >
      {modalMode === 'Delete' ? (
        <p>This will delete the Model Version. Confirm?</p>
      ) : (
        <div className="modal-form">
          <div>
            <form onSubmit={formik.handleSubmit}>
              <KatInput
                label="Model Version Name"
                value={values?.name || formik.values.name}
                placeholder="My Model Version"
                name="name"
                onChange={formik.handleChange}
                onBlur={() => {
                  formik.setTouched({ ...formik.touched, name: true });
                }}
                state={
                  formik.touched.name && formik.errors.name
                    ? 'error'
                    : undefined
                }
                stateLabel={formik.errors.name}
              />
              <KatInput
                label="Description"
                value={values?.description || formik.values.description}
                name="description"
                placeholder="My MLPigeon model version description"
                onChange={formik.handleChange}
                onBlur={() => {
                  formik.setTouched({ ...formik.touched, description: true });
                }}
                state={
                  formik.touched.description && formik.errors.description
                    ? 'error'
                    : undefined
                }
                stateLabel={formik.errors.description}
              />
            </form>
          </div>
        </div>
      )}
    </KatModal>
  );
};
