import React from 'react';

export const StatusCell = ({ value }: any) => {
  const statusDisplayMap: Record<string, string> = {
    PENDINGCREATION: 'Pending Creation',
    PENDINGRETRY: 'Pending Retry',
    PENDING: 'Pending',
    SUCCEEDED: 'Succeeded',
    FAILEDCREATION: 'Failed Creation',
    FAILED: 'Failed',
  };

  if (!value) return <></>;

  return (
    <div>
      <span className={`status status--${value.toLowerCase()}`} key={value}>
        {statusDisplayMap[value] || value.toLowerCase()}
      </span>
    </div>
  );
};
