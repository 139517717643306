import { urlList } from 'src/context/url';

export const fetchMetricsByTrainingJobId = (
  trainingJobId: string,
  startTime: string,
  endTime: string,
  userToken: string,
): Promise<ModelMetric[]> =>
  new Promise((resolve, reject) => {
    fetch(
      urlList.modelMetrics +
        '?' +
        new URLSearchParams({
          trainingJobId,
          metricsType: 'DATAQUALITY',
          startTime,
          endTime,
        }),
      {
        headers: {
          Authorization: userToken,
          'Content-type': 'application/json',
        },
      },
    )
      .then(async (res) => {
        if (!res.ok) {
          await res.json().then((res) => {
            throw new Error(res?.message);
          });
        }
        return res;
      })
      .then((res) => res.json())
      .then(resolve)
      .catch(reject);
  });
